export function validateMeteringLocationNumber(
  meteringLocationNumber: string | null
) {
  if (meteringLocationNumber === null) {
    return false;
  }

  return validateString(meteringLocationNumber);
}

function validateString(value: string) {
  return /^DE\d{31}$/.test(value);
}
