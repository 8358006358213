import type { MRT_ColumnDef, MRT_RowData } from "mantine-react-table";
import React, { useState } from "react";
import { numericFormatter } from "react-number-format";
import { MantineEditNumberInput } from "../../../../../mantine/mrt/components/edit/MantineEditNumberInput";
import { isMrtCreating } from "../../../../../mantine/mrt/utils/isMrtCreating";
import { submitOrCancelOnKeyPress } from "../../../../../mantine/mrt/utils/submitOrCancelOnKeyPress";
import type { ValidationErrors } from "../../../../../types/inlineEditTable.types";
import { IconHelpText } from "../../../../IconHelpText/IconHelpText";

const MAX_WEIGHTING = 1;
const HELP_TEXT = `Der Aufteilungsfaktor muss zwischen 0 und ${MAX_WEIGHTING} liegen.\n\nAndere Eingaben werden automatisch korrigiert.`;

interface WeightingColumnProps {
  validationErrors: ValidationErrors;
  overrideShareValue?: string;
}

export function getWeightingColumn<T extends MRT_RowData>({
  validationErrors,
  overrideShareValue
}: WeightingColumnProps): MRT_ColumnDef<T> {
  return {
    accessorKey: "weighting",
    header: "Aufteilungsfaktor",
    Header: (
      <span className="header-with-help-icon">
        Aufteilungsfaktor
        <IconHelpText helpText={HELP_TEXT} />
      </span>
    ),
    enableSorting: true,
    Cell: ({ cell }) => {
      const value = cell.getValue<string | null>() ?? "";
      return numericFormatter(value, {
        decimalSeparator: ",",
        thousandSeparator: "."
      });
    },
    Edit: ({ cell, column, row, table }) => {
      const shouldOverrideValue = isMrtCreating(table);
      const [sum, setSum] = useState(0);
      const sumError = sum > MAX_WEIGHTING;
      const sumErrorText = sumError
        ? `Die Summe der Erzeugungsanteile für den Zählpunkt überschreitet ${MAX_WEIGHTING}.`
        : "";

      return (
        <MantineEditNumberInput
          cell={cell}
          columnId={column.id}
          errorText={validationErrors?.weighting || sumErrorText}
          label="Aufteilungsfaktor"
          max={1}
          min={0}
          overrideValue={shouldOverrideValue ? overrideShareValue : undefined}
          placeholder="z.B. 0,5"
          row={row}
          onChange={(value) => {
            const rowId = row.id;
            const otherRows = table
              .getRowModel()
              .rows.filter((rows) => rows.id !== rowId);
            const otherRowsWeighting = otherRows.map((rows) =>
              Number(rows.original.weighting)
            );
            const sum = otherRowsWeighting.reduce(
              (acc, curr) => acc + curr,
              Number(value)
            );
            setSum(sum);
          }}
          onKeyDown={(event) => submitOrCancelOnKeyPress(event, table)}
        />
      );
    }
  };
}
