import { Flex } from "@mantine/core";
import type { RowSelectionState } from "@tanstack/react-table";
import type {
  MRT_Cell,
  MRT_RowData,
  MRT_TableInstance,
  MRT_TableOptions
} from "mantine-react-table";
import React from "react";
import { DeleteButton } from "./mrt/components/rowActions/DeleteButton";
import { EditButton } from "./mrt/components/rowActions/EditButton";

interface DefaultMRTRowEditModeOptionsOptions<TData extends MRT_RowData> {
  createText?: string;
  rowActions?: {
    edit?: {
      text: string;
    };
    delete?: {
      text: string;
      enableDelete?: (cell: MRT_Cell<TData>) => boolean;
      onClick: (rows: Array<TData>) => void;
    };
  };
  onClickDelete?: (rowSelection: RowSelectionState) => void;
}

/** Define re-useable default Mantine-React-Table options for row edit mode */
export function getDefaultMRTRowEditModeOptions<TData extends MRT_RowData>(
  options: DefaultMRTRowEditModeOptionsOptions<TData> = {}
): Partial<MRT_TableOptions<TData>> {
  function isCreatingOrEditing(table: MRT_TableInstance<TData>): boolean {
    return (
      table.getState().creatingRow !== null ||
      table.getState().editingRow !== null
    );
  }

  function isDeleteDisabled(
    table: MRT_TableInstance<TData>,
    cell: MRT_Cell<TData>
  ): boolean {
    if (isCreatingOrEditing(table)) {
      return true;
    }

    if (options.rowActions?.delete?.enableDelete) {
      return !options.rowActions.delete.enableDelete(cell);
    }

    return false;
  }

  return {
    createDisplayMode: "row",
    editDisplayMode: "row",
    enableEditing: true,
    enableRowSelection: true,
    renderRowActions: ({ cell, row, table }) => (
      <Flex gap="sm">
        <EditButton
          disabled={isCreatingOrEditing(table)}
          label={options.rowActions?.edit?.text}
          onClick={() => table.setEditingRow(row)}
        />
        <DeleteButton
          disabled={isDeleteDisabled(table, cell)}
          label={options.rowActions?.delete?.text}
          onClick={() => options.rowActions?.delete?.onClick([row.original])}
        />
      </Flex>
    )
  };
}
