import { BarChart } from "@mantine/charts";
import { Group, Paper, Text } from "@mantine/core";
import * as React from "react";
import { Text as RechartsText } from "recharts";
import { type CounterData } from "../../../DataSheet/DataSheet";
import "./AcquisitionBarPlot.scss";

interface AcquisitionBarPlotProps {
  acquisitionSeries: CounterData;
  yAxisTitle: string;
}

function AcquisitionBarPlot({
  acquisitionSeries,
  yAxisTitle
}: AcquisitionBarPlotProps) {
  const dataIds = Object.keys(acquisitionSeries.header);
  const series = [
    {
      name: "value",
      label: acquisitionSeries.labels?.[dataIds[0]] || "Unbekannt",
      color: "indigo.6"
    }
  ];
  const data = dataIds.map((id) => ({
    category: id,
    value: acquisitionSeries.values[id][0]
  }));

  return (
    <BarChart
      data={data}
      dataKey="category"
      h={500}
      series={series}
      tickLine="y"
      tooltipProps={{
        content: ({ label, payload }) => (
          <ChartTooltip
            allCategoryLabels={acquisitionSeries.labels || {}}
            category={label}
            payload={payload}
          />
        )
      }}
      xAxisProps={{
        tick: ({ x, y, payload }) => (
          <CustomizedAxisTick
            allLabels={acquisitionSeries.labels || {}}
            payload={payload}
            x={x}
            y={y}
          />
        )
      }}
      yAxisLabel={yAxisTitle}
    />
  );
}

interface CustomizedAxisTickProps {
  allLabels: Record<string, string>;
  x: number;
  y: number;
  payload: { value: string };
}

function CustomizedAxisTick({
  allLabels,
  x,
  y,
  payload
}: CustomizedAxisTickProps) {
  return (
    <RechartsText angle={90} width={90} x={x} y={y}>
      {allLabels[payload.value].replaceAll("<br>", " ") || "Unbekannt"}
    </RechartsText>
  );
}

interface ChartTooltipProps {
  allCategoryLabels: Record<string, string>;
  category: string;
  payload;
}

/*  Mantine doesn't allow changes to the recharts tooltip: https://mantine.dev/charts/line-chart/#custom-tooltip */
function ChartTooltip({
  allCategoryLabels,
  category,
  payload
}: ChartTooltipProps) {
  if (!payload) return null;

  return (
    <Paper
      className="AcquisitionBarPlot ChartTooltip"
      px="md"
      py="sm"
      radius="md"
      shadow="md"
      withBorder
    >
      <Text fw={500} mb={5}>
        {allCategoryLabels[category]?.replaceAll("<br>", " ") || "Unbekannt"}
      </Text>
      {payload.map((item) => (
        <Group gap="sm" key={item.name}>
          <div className="dot" style={{ backgroundColor: item.color }} />
          <Text fz="sm">{item.value}</Text>
        </Group>
      ))}
    </Paper>
  );
}

export { AcquisitionBarPlot };
