import { useQueryClient } from "@tanstack/react-query";
import classNames from "classnames";
import React, { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import type { Column } from "react-table";
import { Col, Row } from "reactstrap";
import api from "../../api";
import { useArchivedInvoices } from "../../hooks/useArchivedInvoices";
import { useDownload } from "../../hooks/useDownload";
import { useInvoices } from "../../hooks/useInvoices";
import { usePreviewInvoices } from "../../hooks/usePreviewInvoices";
import { useProject } from "../../hooks/useProject";
import { useSapExport } from "../../hooks/useSapExport";
import { useVariantSites } from "../../hooks/useVariantSites";
import { PaymentAdvanceInvoice, PaymentInvoice } from "../../images";
import urls from "../../urls";
import { postDataAndPollResponse } from "../../utils/api-utils";
import type {
  Contract,
  InvoiceData,
  InvoiceResponse,
  Person
} from "../../utils/backend-types";
import { InvoiceState } from "../../utils/backend-types";
import { INVOICE_STATE_CHOICES } from "../../utils/constants";
import { backendDateOrDateTimeToLuxonDateTime } from "../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../utils/dates/sortBackendDates";
import { showToast } from "../../utils/toast";
import { DownIcon } from "../Breadcrumbs/icons/DownIcon";
import {
  Dropdown,
  DropdownItem,
  DropdownMenu,
  DropdownToggle
} from "../BuildingBlocks/Dropdown/Dropdown";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { showBasicConfirmationPopup } from "../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";
import { Button, buttonColors } from "../Buttons/Button/Button";
import { IconButton } from "../Buttons/IconButton/IconButton";
import { PersonCell } from "../CustomReactTable/Cells/PersonCell";
import { CustomReactSelectTable } from "../CustomReactTable/CustomReactTable";
import { useCustomReactTableCheckboxes } from "../CustomReactTable/CustomReactTableHooks";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { ArchiveConfirmationModal } from "./ArchivedInvoices/ArchiveConfirmationModal/ArchiveConfirmationModal";
import { ArchivedInvoices } from "./ArchivedInvoices/ArchivedInvoices";
import "./DeliveryInvoices.scss";
import { useCurrentUserIsInvoiceAdmin } from "./hooks/useCurrentUserIsInvoiceAdmin";
import { InvoicePreviewModal } from "./InvoicePreview/InvoicePreviewModal";
import { InvoiceDropzone } from "./InvoiceUploadDropzone";
import { SapExportModal } from "./SapExportModal/SapExportModal";

export type Invoice = Omit<InvoiceResponse, "invoiceData"> &
  Partial<Omit<InvoiceData, "id">> & {
    supplied?: number;
    supplier?: number;
    siteName?: string;
  };

interface InvoicesProps {
  invoices: Array<Invoice>;
  persons: Array<Person>;
  contracts: Array<Contract>;
  customReactTableProps;
  onChangeStatus: (status: InvoiceState, id: string) => void;
  onUpdateInvoice: (
    invoice: Invoice,
    sentViaEmail: boolean,
    invoiceStatus: InvoiceState
  ) => void;
}

function Invoices({
  invoices,
  persons,
  contracts,
  customReactTableProps,
  onChangeStatus,
  onUpdateInvoice
}: InvoicesProps) {
  const { t } = useTranslation();

  async function sendMail(invoice: Invoice) {
    const sendUrl = urls.api.sendInvoiceToSupplied(invoice.id);
    try {
      await api.post(sendUrl);
      onUpdateInvoice(invoice, true, InvoiceState.Sent);
      showToast("success", "E-Mail wurde erfolgreich verschickt.");
    } catch (e) {
      openErrorAlertPopup(e);
    }
  }

  async function onSendMail(invoice: Invoice, persons: Array<Person>) {
    const person = persons.find((p) => p.id === invoice.supplied);
    const contract = contracts.find((c) => c.id === invoice.contract);

    if (person && contract) {
      if (contract.allowanceSendInvoiceViaMail) {
        await sendMail(invoice);
      } else {
        const text = `Liegt eine Einwilligung von ${person.name} für den elektronischen Rechnungsversand vor?`;
        showBasicConfirmationPopup({
          text: text,
          onConfirm: async () => {
            contract.allowanceSendInvoiceViaMail = true;
            const updateUrl = urls.api.contract(contract.id);
            try {
              await api.put<void>(updateUrl, contract);
              await sendMail(invoice);
            } catch (e) {
              console.error(e);
              showToast("error", t("errors.DeliveryInvoices.AllowSendError"));
            }
          }
        });
      }
    }
  }

  const columns: Array<Column> = [
    {
      Header: "Rechnungsdokument",
      accessor: "id",
      Cell: (row) => (
        <FileCell
          billingPeriodStart={row.original.billingPeriodStart}
          icon={
            row.original.isAdvanceInvoice
              ? PaymentAdvanceInvoice
              : PaymentInvoice
          }
          persons={persons}
          prefix={
            row.original.isAdvanceInvoice
              ? "Abschlagsrechnung"
              : row.original.isCorrectionInvoice
                ? "Korrekturrechnung"
                : "Rechnung"
          }
          suppliedId={row.original.supplied}
          supplierId={row.original.supplier}
          url={urls.api.downloadInvoice(row.value)}
        />
      )
    },
    {
      Header: "Erstellung",
      accessor: "created",
      width: 100,
      Cell: (row) => row.value.split(" ")[0],
      sortMethod: (dateA, dateB) =>
        backendDateOrDateTimeToLuxonDateTime(dateA).diff(
          backendDateOrDateTimeToLuxonDateTime(dateB)
        ).seconds < 0
          ? 1
          : -1
    },
    {
      Header: "Lieferant",
      accessor: "supplier",
      Cell: (row) => <PersonCell personId={row.value} persons={persons} />
    },
    {
      Header: "Belieferter",
      accessor: "supplied",
      Cell: (row) => <PersonCell personId={row.value} persons={persons} />
    },
    { Header: "Liegenschaft", accessor: "siteName" },
    {
      Header: "Abrechnungszeitraum",
      accessor: "billingPeriodStart",
      width: 160,
      sortMethod: sortBackendDates,
      Cell: (row) => (
        <DateRangeCell
          beginDate={row.original.billingPeriodStart}
          endDate={row.original.billingPeriodEnd}
        />
      )
    },
    {
      Header: "Status",
      accessor: "state",
      width: 140,
      Cell: (row) => (
        <InvoiceStatusCell
          id={row.original.id}
          status={row.value}
          onChangeStatus={onChangeStatus}
        />
      )
    },
    {
      Header: "E-Mail-Versand",
      accessor: "sentViaEmail",
      width: 140,
      Cell: (row) =>
        row.value ? undefined : (
          <div className={"centered"}>
            <Button onClick={() => onSendMail(row.original, persons)}>
              Versenden
            </Button>
          </div>
        )
    }
  ];

  const defaultSorted = [
    {
      id: "billingPeriodStart",
      desc: true
    }
  ];

  return (
    <div className="invoices-container">
      <h5>Rechnungen</h5>
      <CustomReactSelectTable
        {...customReactTableProps}
        columns={columns}
        data={invoices}
        defaultSorted={defaultSorted}
        keyField="id"
        NoDataComponent={NoDataComponent}
        pageSize={10}
        showPageJump
        showPagination
      />
    </div>
  );
}

interface FileCellProps {
  url: string;
  prefix: string;
  supplierId: number;
  suppliedId: number;
  persons: Array<Person>;
  billingPeriodStart: string;
  icon?: string;
}

export function FileCell({
  url,
  prefix,
  supplierId,
  suppliedId,
  persons,
  billingPeriodStart,
  icon
}: FileCellProps) {
  const supplier = persons.find((person) => person.id === supplierId);
  const supplied = persons.find((person) => person.id === suppliedId);
  const supplierName = supplier ? supplier.name : "<Kein_Lieferant>";
  const suppliedName = supplied ? supplied.name : "<Kein_Belieferter>";
  const billingPeriod = billingPeriodStart || "<Kein_Abrechnungsbeginn>";
  const text = `${prefix}_${supplierName}_${suppliedName}_${billingPeriod}`;
  const { download } = useDownload();

  return (
    <>
      {icon && <img className="invoice-file-icon" src={icon} />}
      <a href="#" onClick={() => download({ downloadUrl: url })}>
        {text}
      </a>
    </>
  );
}

export function DateRangeCell({
  beginDate,
  endDate
}: {
  beginDate?: string;
  endDate?: string;
}) {
  if (!beginDate || !endDate) {
    return null;
  }

  const begin = backendDateOrDateTimeToLuxonDateTime(beginDate);
  const end = backendDateOrDateTimeToLuxonDateTime(endDate);

  const month =
    +begin.startOf("month") === +begin &&
    +begin.endOf("month") === +end.endOf("day");
  const year =
    +begin.startOf("year") === +begin &&
    +begin.endOf("year") === +end.endOf("day");
  const formatter = new Intl.DateTimeFormat("de", {
    month: "short",
    year: "numeric"
  });

  if (month) {
    return <span>{formatter.format(begin.toJSDate())}</span>;
  } else if (year) {
    const formatter = new Intl.DateTimeFormat("de", { year: "numeric" });
    return <span>{formatter.format(end.toJSDate())}</span>;
  } else {
    return (
      <span>
        {formatter.format(begin.toJSDate())} -{" "}
        {formatter.format(end.toJSDate())}
      </span>
    );
  }
}

function NoDataComponent() {
  return (
    <div className="no-data-component">
      <p>Keine Rechnungen vorhanden.</p>
    </div>
  );
}

interface InvoiceStatusCellProps {
  status: InvoiceState;
  id: string;
  onChangeStatus: (status: string, id: string) => void;
}

export function InvoiceStatusCell({
  status,
  id,
  onChangeStatus
}: InvoiceStatusCellProps) {
  return (
    <div className="status-cell">
      <select
        className="cell-dropdown form-control m-input"
        value={status}
        onChange={(event) => onChangeStatus(event.target.value, id)}
      >
        {INVOICE_STATE_CHOICES.map((entry) => (
          <option key={entry.value} value={entry.value}>
            {entry.displayName}
          </option>
        ))}
      </select>
    </div>
  );
}

function InvoiceActionsDropdown({
  onShowPreview,
  onGenerateInvoices,
  invoices,
  setArchiveModalOpen,
  isLoading
}) {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const archiveInvoicesDisabled = invoices.length === 0;

  function toggleDropdown() {
    setDropdownOpen((prevState) => !prevState);
  }

  return (
    <Dropdown
      className="InvoiceActionsDropdown"
      isOpen={dropdownOpen}
      toggle={toggleDropdown}
    >
      <DropdownToggle
        aria-expanded={dropdownOpen}
        className="invoice-action-dropdown-toggle"
        color={buttonColors.brand}
        disabled={isLoading}
      >
        Rechnungen
        {isLoading ? <Icon name={IconName.SpinnerSpinning} /> : <DownIcon />}
      </DropdownToggle>
      <DropdownMenu title="Rechnungen">
        <DropdownItem onClick={onShowPreview}>Rechnungsvorschau</DropdownItem>
        <DropdownItem onClick={onGenerateInvoices}>
          Rechnungen erstellen
        </DropdownItem>
        <DropdownItem
          disabled={archiveInvoicesDisabled}
          onClick={() => setArchiveModalOpen(true)}
        >
          <span
            className={classNames("archive-invoices-dropdown-item", {
              disabled: archiveInvoicesDisabled
            })}
          >{`Rechnung${invoices.length > 1 ? "en" : ""} stornieren`}</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
}

interface SapExportModalProps {
  openSapExportModal: () => void;
  loading: boolean;
  disabled?: boolean;
}
function SapExportButton({
  openSapExportModal,
  loading,
  disabled
}: SapExportModalProps) {
  return (
    <IconButton
      className="sap-export-button"
      disabled={disabled}
      iconName={loading ? IconName.SpinnerSpinning : IconName.FileExcel}
      onClick={openSapExportModal}
    >
      SAP-Export
    </IconButton>
  );
}
interface InvoicesDataLoader {
  variantId: number;
  contracts: Array<Contract>;
  persons: Array<Person>;
  projectId: string | undefined;
}

function InvoicesDataLoader({
  variantId,
  contracts,
  persons,
  projectId
}: InvoicesDataLoader) {
  const {
    invoices: invoicesResponse,
    reverseInvoice,
    isLoading: invoicesResponseLoading,
    error: invoicesResponseError
  } = useInvoices(variantId);

  const {
    archivedInvoices: archivedInvoicesResponse,
    isLoading: archivedInvoicesResponseLoading,
    error: archivedInvoicesResponseError
  } = useArchivedInvoices(variantId);

  const { project } = useProject(projectId);

  const { currentUserIsInvoiceAdmin } = useCurrentUserIsInvoiceAdmin();
  const { generateAndDownloadPreview } = usePreviewInvoices();
  const { generateAndDownloadSapExport, exportLoading } = useSapExport();
  const {
    sites,
    isLoading: isSitesLoading,
    error: sitesError
  } = useVariantSites(variantId);

  const [invoices, setInvoices] = useState<Array<Invoice> | undefined>(
    undefined
  );

  const [isGeneratingInvoices, setIsGeneratingInvoices] = useState(false);
  const [sapExportModalOpen, setSapExportModalOpen] = useState(false);
  const [archivedInvoices, setArchivedInvoices] = useState<
    Array<Invoice> | undefined
  >(undefined);

  const [archiveModalOpen, setArchiveModalOpen] = useState(false);
  const [previewModalOpen, setPreviewModalOpen] = useState(false);

  const [isArchiveLoading, setIsArchiveLoading] = useState(false);
  const queryClient = useQueryClient();

  const { setSelection, setSelectAll, getSelectedData, customReactTableProps } =
    useCustomReactTableCheckboxes<Invoice>();

  function onUpdateInvoice(
    invoice: Invoice,
    sentViaEmail: boolean,
    state: InvoiceState
  ) {
    if (invoices) {
      const newInvoices = [...invoices];
      const index = newInvoices.findIndex((i) => i.id === invoice.id);

      if (index > -1) {
        newInvoices[index] = { ...invoice, sentViaEmail, state };
        setInvoices(newInvoices);
      }
    }
  }

  const processInvoices = useCallback(
    (
      invoicesResponse: Array<InvoiceResponse>,
      setInvoices: (invoices: Array<Invoice>) => void,
      contracts: Array<Contract>
    ) => {
      const newInvoices: Array<Invoice> = [];

      invoicesResponse.forEach((invoiceResponse) => {
        const { invoiceData, ...invoiceWithoutDataId } = invoiceResponse;

        if (invoiceResponse.invoiceData) {
          const { id, ...invoiceData } = invoiceResponse.invoiceData;
          const contract = contracts.find(
            (contract) => contract.id === invoiceData.contract
          );

          const newInvoice: Invoice = {
            ...invoiceWithoutDataId,
            ...invoiceData,
            supplier: contract ? contract.supplier : undefined,
            supplied: contract ? contract.supplied : undefined,
            siteName:
              sites.find((site) => site.id === invoiceData.site)?.name || ""
          };

          newInvoices.push(newInvoice);
        } else {
          newInvoices.push({ ...invoiceWithoutDataId });
        }
      });

      setInvoices(newInvoices);
    },
    [sites]
  );

  useEffect(() => {
    if (invoicesResponse) {
      processInvoices(invoicesResponse, setInvoices, contracts);
    }
  }, [invoicesResponse, contracts, processInvoices]);

  useEffect(() => {
    if (archivedInvoicesResponse) {
      processInvoices(archivedInvoicesResponse, setArchivedInvoices, contracts);
    }
  }, [archivedInvoicesResponse, contracts, processInvoices]);

  async function handleGenerateInvoices() {
    const postUrl = urls.api.generateInvoices(variantId);
    setIsGeneratingInvoices(true);
    return postDataAndPollResponse(postUrl, {})
      .then(() => {
        showToast("success", "Alle angeforderten Rechnungen wurden erstellt.");
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        setIsGeneratingInvoices(false);
        queryClient.invalidateQueries({
          queryKey: [
            "invoices",
            {
              variantId
            }
          ]
        });
      });
  }

  function handleArchiveInvoices(selected: Array<Invoice>) {
    if (!invoices) {
      return;
    }

    setIsArchiveLoading(true);

    const archivePromises = selected.map((item) => {
      return reverseInvoice(item.id);
    });

    let success = false;
    Promise.all(archivePromises)
      .then(() => {
        success = true;
        queryClient.invalidateQueries({
          queryKey: ["invoices", { variantId }]
        });
        queryClient.invalidateQueries({
          queryKey: ["archivedInvoices", { variantId }]
        });
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        if (success) {
          setInvoices(
            invoices.filter(
              (inv) => selected.find((item) => item.id === inv.id) === undefined
            )
          );
          setSelection([]);
          setSelectAll(false);
        }

        setIsArchiveLoading(false);
        setArchiveModalOpen(false);
      });
  }

  function handleChangeInvoiceStatus(state: InvoiceState, invoiceId: string) {
    const url = urls.api.invoiceUpdateState(invoiceId);
    let success = false;

    api
      .post(url, { state })
      .then(() => {
        success = true;
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        if (success && invoices) {
          const copy = [...invoices];
          const invoiceToUpdate = copy.find(
            (invoice) => invoice.id === invoiceId
          );

          if (invoiceToUpdate) {
            invoiceToUpdate.state = state;
            setInvoices(copy);
          }
        }
      });
  }
  function handleChangeReversedStatus(
    state: InvoiceState,
    reversedInvoiceId: string,
    archivedInvoiceId: string
  ) {
    const url = urls.api.invoiceUpdateState(reversedInvoiceId);
    let success = false;

    api
      .post(url, { state })
      .then(() => {
        success = true;
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        if (success && archivedInvoices) {
          const copy = [...archivedInvoices];
          const invoiceToUpdate = copy.find(
            (invoice) => invoice.id === archivedInvoiceId
          );

          if (invoiceToUpdate && invoiceToUpdate.reversalInvoice) {
            invoiceToUpdate.reversalInvoice.state = state;
            setArchivedInvoices(copy);
          }
        }
      });
  }

  function getArchiveConfirmationModalText() {
    const numberOfSelected = getSelectedData().length;
    if (numberOfSelected === 1) {
      return `Diese Rechnung`;
    } else {
      return `Diese ${getSelectedData().length} Rechnungen`;
    }
  }
  function showPreviewModalOrPreview() {
    if (contracts.length === 0) {
      showToast("warning", "Es sind keine Verträge vorhanden.");
    } else if (contracts.length === 1) {
      generateAndDownloadPreview(contracts[0].id);
    } else {
      setPreviewModalOpen(!previewModalOpen);
    }
  }

  function handleClickSapExport(month: number, year: number) {
    setSapExportModalOpen(false);
    generateAndDownloadSapExport(variantId, month, year);
  }

  return (
    <LoadOrError
      error={
        invoicesResponseError || archivedInvoicesResponseError || sitesError
      }
      loading={
        invoicesResponseLoading ||
        archivedInvoicesResponseLoading ||
        isSitesLoading
      }
    >
      {invoices && (
        <div className="Invoices">
          <Row>
            <Col>
              <div className="controls">
                <InvoicePreviewModal
                  closeModal={() => setPreviewModalOpen(false)}
                  contracts={contracts}
                  isModalOpen={previewModalOpen}
                />

                <InvoiceActionsDropdown
                  invoices={getSelectedData()}
                  isLoading={
                    isArchiveLoading ||
                    isGeneratingInvoices ||
                    invoicesResponseLoading
                  }
                  setArchiveModalOpen={setArchiveModalOpen}
                  onGenerateInvoices={handleGenerateInvoices}
                  onShowPreview={showPreviewModalOrPreview}
                />
                {project && project.sapExportActive && (
                  <SapExportButton
                    disabled={
                      exportLoading ||
                      isArchiveLoading ||
                      invoicesResponseLoading ||
                      isGeneratingInvoices
                    }
                    loading={exportLoading}
                    openSapExportModal={() => setSapExportModalOpen(true)}
                  />
                )}
                {sapExportModalOpen && (
                  <SapExportModal
                    closeModal={() => setSapExportModalOpen(false)}
                    onClickExport={handleClickSapExport}
                  />
                )}
                <ArchiveConfirmationModal
                  isLoading={isArchiveLoading}
                  isModalOpen={archiveModalOpen}
                  name={getArchiveConfirmationModalText()}
                  toggleModal={() => {
                    setArchiveModalOpen(!archiveModalOpen);
                  }}
                  onSuccess={() => handleArchiveInvoices(getSelectedData())}
                />
              </div>
              <Invoices
                contracts={contracts}
                customReactTableProps={customReactTableProps}
                invoices={invoices}
                persons={persons}
                onChangeStatus={handleChangeInvoiceStatus}
                onUpdateInvoice={onUpdateInvoice}
              />
            </Col>
          </Row>
          {archivedInvoices && (
            <Row>
              <Col>
                <ArchivedInvoices
                  contracts={contracts}
                  customReactTableProps={customReactTableProps}
                  invoices={archivedInvoices}
                  persons={persons}
                  onChangeStatus={handleChangeReversedStatus}
                />
              </Col>
            </Row>
          )}
          {currentUserIsInvoiceAdmin && (
            <Row>
              <Col>
                <InvoiceDropzone variantId={variantId} />
              </Col>
            </Row>
          )}
        </div>
      )}
    </LoadOrError>
  );
}

export { InvoicesDataLoader as DeliveryInvoices };
