import { DateInput as MantineDateInput, type DateValue } from "@mantine/dates";
import classNames from "classnames";
import { DateTime } from "luxon";
import * as React from "react";
import { parseManualDateInput } from "../../../../utils/parseManualDateInput";
import { Icon } from "../../Icon/Icon";
import { IconName } from "../../Icon/types";
import "./DateInput.scss";

interface DateInputProps {
  className?: string;
  date?: DateTime;
  disabled?: boolean;
  firstPossibleDate?: DateTime;
  id: string;
  lastPossibleDate?: DateTime;
  placeholder?: string;
  showCalendarIcon?: boolean;
  showClearDate?: boolean;
  onChange: (date: DateTime | null) => void;
}

function DateInput(
  {
    className,
    date,
    disabled,
    firstPossibleDate,
    id,
    lastPossibleDate,
    placeholder,
    showCalendarIcon,
    showClearDate,
    onChange
  }: DateInputProps,
  ref?: React.Ref<HTMLInputElement>
) {
  const parsedValue: DateValue = date?.toJSDate() || null;

  function handleChange(value: DateValue) {
    onChange(value ? DateTime.fromJSDate(value) : null);
  }

  return (
    <MantineDateInput
      className={classNames("DateInput", className)}
      clearable={showClearDate}
      dateParser={parseManualDateInput}
      disabled={disabled}
      id={id}
      maxDate={lastPossibleDate?.toJSDate()}
      minDate={firstPossibleDate?.toJSDate()}
      placeholder={placeholder}
      popoverProps={{
        zIndex: 1060
      }}
      ref={ref}
      rightSection={
        showCalendarIcon && (
          <Icon className="datepicker-calendar" name={IconName.Calendar} />
        )
      }
      value={parsedValue}
      valueFormat="DD.MM.YYYY"
      onChange={handleChange}
    />
  );
}

const ForwardedRefDatePicker = React.forwardRef(DateInput);

export { ForwardedRefDatePicker as DateInput, DateInputProps };
