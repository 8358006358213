export const seriesColors = [
  "#4269D0",
  "#EFB118",
  "#6CC5B0",
  "#FF725C",
  "#3CA951",
  "#FF8AB7",
  "#A463F2",
  "#97BBF5",
  "#9C6B4E",
  "#9498A0"
];
