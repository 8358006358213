import { Group } from "@mantine/core";
import * as Sentry from "@sentry/browser";
import React from "react";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Button, buttonColors } from "../../Buttons/Button/Button";

function DevSettings() {
  function handleSentryTrigger() {
    Sentry.captureMessage(
      "Frontend test message at " + new Date().toLocaleString()
    );
  }

  function triggerError() {
    // @ts-expect-error This is a test error for checking if source maps in Sentry are working
    return {}.map((x) => x);
  }

  return (
    <Portlet className="DevSettings" title="Entwicklereinstellungen">
      <Group gap="sm">
        <Button color={buttonColors.primary} onClick={handleSentryTrigger}>
          Testnachricht an Sentry schicken
        </Button>
        <Button color={buttonColors.danger} onClick={triggerError}>
          Testfehler triggern
        </Button>
      </Group>
    </Portlet>
  );
}

export { DevSettings };
