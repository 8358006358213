import React from "react";
import { BasicConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/BasicConfirmationModal/BasicConfirmationModal";

interface DiagramInfoModalProps {
  onConfirm: () => void;
  onCancel: () => void;
}

function DiagramInfoModal({ onCancel, onConfirm }: DiagramInfoModalProps) {
  return (
    <BasicConfirmationModal
      className="DiagramInfoModal"
      headerText="Hinweis zum Wechsel der Ansichten"
      isModalOpen
      toggleModal={() => onCancel()}
      onCancel={() => onCancel()}
      onConfirm={() => onConfirm()}
    >
      Hinweis: Elemente, die in der Messkonzeptansicht verschoben/verändert
      werden, werden ebenfalls in den andereren Ansichten verschoben/verändert.
      Ebenso werden Änderungen aus anderen Ansichten in der Messkonzeptansicht
      abgebildet.
    </BasicConfirmationModal>
  );
}

export { DiagramInfoModal };
