import type {
  components,
  SiteSetupProcessDataStep,
  SiteSetupProcessDataDefault_pv_plants_measurement_type,
  SiteSetupProcessDataConnection_meter_energy_data_origin,
  SiteSetupProcessDataDefault_consumer_type,
  SiteSetupProcessDataDefault_consumer_measurement_type,
  ConnectionMeterMeasurement_type,
  MeteringDirection
} from "../../types/api.types";

export type SiteSetupProcessRaw = components["schemas"]["SiteSetupProcessData"];
export type PVPlant = components["schemas"]["PVPlant"];
export type VoltageLevel = components["schemas"]["VoltageLevel"];
export type Address = components["schemas"]["AddressInfo"];
export type SupplyType = components["schemas"]["SupplyType"];
export type Consumer = components["schemas"]["Consumer"];
export type BatteryStorage = components["schemas"]["BatteryStorage"];
export type ConnectionMeter = components["schemas"]["ConnectionMeter"];
export type Operator = components["schemas"]["Operator"];

// frontend-only
export enum LocationType {
  MeteringLocation = "Messlokation",
  MarketLocation = "Marktlokation",
  None = "Keine"
}

export interface SiteSetupProcessForForm {
  step?: SiteSetupProcessDataStep;

  /* site */
  name: string;
  pvPlants?: Array<PVPlant> | null;
  siteAddress?: string | null;
  siteZip?: string | null;
  siteCity?: string | null;
  siteSupplyType?: SupplyType | null;
  connectionVoltageLevel?: VoltageLevel | null;
  consumers?: Array<Consumer> | null;
  batteryStorage?: BatteryStorage | null;
  gridOperator?: number | null;

  /* connection meter (in site) */
  connectionName?: string | null;
  connectionMeasurementType?: ConnectionMeterMeasurement_type | null;
  connectionMeteringDirection?: MeteringDirection | null;
  connectionMeteringServiceProvider?: number | null;
  connectionLocationType: LocationType; // frontend-only
  connectionMeteringLocation?: string | null;
  connectionMarketLocationFeedin?: string | null;
  connectionMarketLocationFeedout?: string | null;

  defaultPvPlantsMeasurementType?: SiteSetupProcessDataDefault_pv_plants_measurement_type | null;
  connectionMeterEnergyDataOrigin?: SiteSetupProcessDataConnection_meter_energy_data_origin | null;
  voltageLevelDeterminedAutomatically?: boolean | null;
  consumerAmount?: number | null;
  defaultConsumerType?: SiteSetupProcessDataDefault_consumer_type | null;
  defaultConsumerMeasurementType?: SiteSetupProcessDataDefault_consumer_measurement_type | null;
  allTenantAddressesAreSameAsSiteAddress?: boolean | null;
  allTenantsParticipatingInMieterstrom?: boolean | null;
  siteHasBatteryStorage?: boolean | null;
  isBatteryStorageMeasuredSeparately?: boolean | null;
}
