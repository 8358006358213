import _ from "lodash";
import { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { memo, useCallback, useEffect, useMemo, useState } from "react";
import { Col, Row } from "reactstrap";

import "react-datasheet/lib/react-datasheet.css";
import type { ApiResponse } from "../../api";
import api from "../../api";
import { useVariantMeters } from "../../hooks/useVariantMeters";
import urls from "../../urls";
import type { Meter, Site } from "../../utils/backend-types";
import type { MeasurementType } from "../../utils/enums";
import { Frequency } from "../../utils/enums";
import { showToast } from "../../utils/toast";
import { Alert, AlertColor } from "../Alert/Alert";
import { DateInput } from "../BuildingBlocks/Dates/DateInput/DateInput";
import { Icon } from "../BuildingBlocks/Icon/Icon";
import { IconName } from "../BuildingBlocks/Icon/types";
import { Section } from "../BuildingBlocks/Layout/Section";
import { Button } from "../Buttons/Button/Button";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { toStr } from "../DataSheet/data-sheet-utils";
import type {
  ChangedValue,
  CounterData,
  DataValue,
  GridElement,
  Index
} from "../DataSheet/DataSheet";
import { DataSheet, isSingleIndex } from "../DataSheet/DataSheet";
import {
  getEnergyDataImportRequests,
  pollDataImportRequests
} from "../EnergyData/EnergyDataUploadFlow/EnergyDataUpload";
import type { EnergyDataUploadPartialResult } from "../EnergyData/EnergyDataUploadFlow/EnergyDataUploadFlow";
import { EnergyDataUploadModal } from "../EnergyData/EnergyDataView/EnergyDataUploadModal/EnergyDataUploadModal";
import { openErrorAlertPopup } from "../ErrorAlertPopup/openErrorAlertPopup";
import loader from "../Loader";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { CounterDataViewMode } from "./counter-data-view-types";
import "./CounterDataView.scss";
import { getCounterDataViewModeByMeasurementType } from "./utils/getCounterDataViewModeByMeasurementType";

const NEW_LINES_FOR_FREQUENCY = {
  [Frequency.TenMinutes]: 144,
  [Frequency.QuarterHour]: 96,
  [Frequency.Hour]: 24,
  [Frequency.Day]: 31,
  [Frequency.Month]: 12,
  [Frequency.Year]: 2
};

const SPARSE_PAGE_SIZE = 20;
const NO_PAGE_SET = -1;
const INVALID_PAGE_SET = -2;
const CALORIFIC_VALUE_COLUMN_POSITION = 3;

export type OnSetRequestDataForMeter = (
  requestData: Record<string, Record<string, DataValue>>
) => void;

export type OnSetRequestDataForGas = (
  requestData: Record<number, Record<number, DataValue>>
) => void;

interface CounterDataViewForMeterProps {
  data: [CounterData];
  variantId: number;
  mode: CounterDataViewMode;
  meters: Array<Meter>;
  sites: Array<Site>;
  meterId?: number;
  onSetRequestData: OnSetRequestDataForMeter;
  onReload: () => void;
  isGasConnectionDataView: false;
}
interface CounterDataViewForGasProps {
  data: [CounterData];
  variantId: number;
  mode: CounterDataViewMode;
  meters: Array<Meter>;
  sites: Array<Site>;
  meterId?: number;
  onSetRequestData: OnSetRequestDataForGas;
  onReload: () => void;
  isGasConnectionDataView: true;
}

function CounterDataView({
  data,
  variantId,
  mode,
  meters,
  sites,
  meterId,
  onSetRequestData,
  isGasConnectionDataView,
  onReload
}: CounterDataViewForMeterProps | CounterDataViewForGasProps) {
  const DESCRIPTION_TEXT = isGasConnectionDataView
    ? "Sie können hier Messwerte für den Gas-Netzanschlusspunkt eintragen," +
      " aus Excel kopieren oder hochladen. Geänderte Zellen sind farblich hinterlegt." +
      " Zustandszahlen sind ohne Einheit und die Brennwerte in kWh/m³"
    : "Sie können hier Messwerte für den Zähler eintragen, aus Excel" +
      "kopieren oder hochladen. Geänderte Zellen sind farblich hinterlegt." +
      "Alle Werte sind in der Einheit kWh angegeben.";

  const PAGE_SIZE =
    mode === CounterDataViewMode.SparseIndex ? SPARSE_PAGE_SIZE : 96;
  const [storedData, setStoredData] = useState<CounterData>(
    cleanSparseData(data[0], mode)
  );
  const [changedValues, setChangedValues] = useState<Array<ChangedValue>>([]);
  const [requestingNewLines, setRequestingNewLines] = useState(false);
  const [isUploadModalOpen, setIsUploadModalOpen] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [skipDate, setSkipDate] = useState<DateTime | undefined>(undefined);
  const canUploadFile = changedValues.length === 0 && !uploading;

  const storedDataIds = Object.keys(storedData.header);

  const requestNewLines = useCallback(
    async (append: boolean) => {
      if (requestingNewLines) {
        return;
      }

      setRequestingNewLines(true);

      let referenceDateTimeString: string;

      if (storedData.index.length === 0) {
        const today = DateTime.local().set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0
        });

        referenceDateTimeString = today.toISO() as string;
      } else {
        if (append) {
          const last = storedData.index.length - 1;
          referenceDateTimeString = storedData.index[last][0];
        } else {
          referenceDateTimeString = storedData.index[0][0];
        }
      }

      const frequency = storedData.frequency;
      const numberNewLines = getNumberOfNewLinesForFrequency(frequency, mode);

      function buildUrlToGetDateRange(): string {
        // need one more period because either the last (prepending) or first (appending) returned index value
        // already exists in the current index, which is the reference date.
        const periods = numberNewLines + 1;
        const params = {
          freq: frequency,
          periods: periods
        };

        if (append) {
          params["start"] = referenceDateTimeString;
        } else {
          params["end"] = referenceDateTimeString;
        }

        params["cumulative"] = mode === CounterDataViewMode.SingleIndex;

        return urls.api.dateRange(params);
      }

      let newIndexValues: Index = [];

      if (
        mode == CounterDataViewMode.SparseIndex &&
        isSingleIndex(newIndexValues, mode)
      ) {
        if (storedData.index.length === 0) {
          newIndexValues.push([referenceDateTimeString]);
        } else if (append) {
          const newDateString = DateTime.fromISO(referenceDateTimeString)
            .plus({ days: 1 })
            .toISO() as string;
          newIndexValues.push([newDateString]);
        } else {
          const newDateString = DateTime.fromISO(referenceDateTimeString)
            .plus({ days: -1 })
            .toISO() as string;
          newIndexValues.push([newDateString]);
        }
      } else {
        const url = buildUrlToGetDateRange();
        let response: ApiResponse<Exclude<Index, []>>;
        try {
          response = await api.get(url);
        } catch (error) {
          openErrorAlertPopup(error);
          setRequestingNewLines(false);
          return;
        }
        // remove the duplicated index value
        if (append) {
          newIndexValues = response.data.slice(1);
        } else {
          newIndexValues = response.data.slice(0, numberNewLines);
        }
      }

      function addNullsToValues() {
        const nulls = _.times(numberNewLines, _.constant(null));
        return storedDataIds.reduce(
          (
            obj: Record<number, Array<DataValue>>,
            counterId: number | string
          ) => {
            if (append) {
              obj[counterId] = [...storedData.values[counterId], ...nulls];
            } else {
              obj[counterId] = [...nulls, ...storedData.values[counterId]];
            }
            return obj;
          },
          {}
        );
      }

      function addNewIndexValues(): Index {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore typescript gets confused here with the possible array sizes
        return append
          ? [...storedData.index, ...newIndexValues]
          : [...newIndexValues, ...storedData.index];
      }

      setStoredData({
        ...storedData,
        index: addNewIndexValues(),
        values: addNullsToValues()
      });
      setRequestingNewLines(false);
    },
    [requestingNewLines, storedData, storedDataIds, mode]
  );

  useEffect(() => {
    if (storedData.index.length === 0) {
      requestNewLines(false);
    }
  }, [storedData, requestNewLines]);

  const initialPage = useMemo(() => {
    if (!skipDate || !storedData || storedData.index.length === 0) {
      return NO_PAGE_SET;
    }

    const frequency = storedData.frequency;
    const numLines = getNumberOfNewLinesForFrequency(frequency, mode);
    const startOfSkipDateTime = skipDate.startOf("day");
    let i = 0;

    for (i; i < storedData.index.length; i += numLines) {
      const index = storedData.index[i];

      if (mode === CounterDataViewMode.SparseIndex) {
        const indexDateTime = DateTime.fromISO(index[0]);

        if (indexDateTime >= startOfSkipDateTime) {
          break;
        }
      } else {
        if (index.length === 2) {
          const indexDateTimeFrom = DateTime.fromISO(index[0]);
          const indexDateTimeTo = DateTime.fromISO(index[1]);

          if (
            startOfSkipDateTime.equals(indexDateTimeFrom.startOf("day")) ||
            startOfSkipDateTime.equals(indexDateTimeTo.startOf("day"))
          ) {
            break;
          }
        } else {
          const indexDateTime = DateTime.fromISO(index[0]);

          if (startOfSkipDateTime.equals(indexDateTime.startOf("day"))) {
            break;
          }
        }
      }
    }

    const desiredPage = Math.floor(i / PAGE_SIZE);
    const lastPossiblePage = Math.floor(
      (storedData.index.length - 1) / PAGE_SIZE
    );
    const lastPage = Math.floor(storedData.index.length / PAGE_SIZE);
    const desiredPageIsValid =
      i < storedData.index.length &&
      desiredPage <= lastPossiblePage &&
      desiredPage <= lastPage;

    return desiredPageIsValid ? desiredPage : INVALID_PAGE_SET;
  }, [skipDate, storedData, PAGE_SIZE, mode]);

  function toggleUploadModal(active?: boolean) {
    if (active && canUploadFile) {
      setIsUploadModalOpen(true);
    } else if (!active) {
      setIsUploadModalOpen(false);
    }
  }

  function handleUpdateChangedIndex(
    oldIndexPos,
    newIndex: [string] | [string, string],
    givenChangedValues: Array<ChangedValue>
  ) {
    const duplicate = findDuplicateInIndex(storedData.index, newIndex);

    if (duplicate) {
      showToast(
        "error",
        "Der gewünschte Datumseintrag existiert bereits. Bitte editieren Sie den vorhandenen Eintrag."
      );
      return givenChangedValues;
    } else {
      //We know that a copied index is still an index
      const newStoredIndex = [...storedData.index] as Index;
      newStoredIndex[oldIndexPos] = newIndex;

      const removedNewValueEntries = givenChangedValues.filter(
        (c) => !isIndexEqual(c.index, newIndex)
      );

      let mappedChanges = removedNewValueEntries.map(
        (changedValue): ChangedValue => {
          if (isIndexEqual(changedValue.index, storedData.index[oldIndexPos])) {
            return {
              index: newIndex,
              value: changedValue.value,
              columnId: changedValue.columnId
            };
          } else {
            return changedValue;
          }
        }
      );

      const existingData = Object.keys(storedData.values).some(
        (key) => storedData.values[key][oldIndexPos] !== null
      );

      if (existingData) {
        mappedChanges = mappedChanges.filter(
          (c) => !isIndexEqual(c.index, storedData.index[oldIndexPos])
        );

        for (const id of storedDataIds) {
          mappedChanges.push({
            index: storedData.index[oldIndexPos],
            value: null,
            columnId: id
          });

          if (
            !mappedChanges.find(
              (change) =>
                change.columnId === id && isIndexEqual(change.index, newIndex)
            )
          ) {
            mappedChanges.push({
              index: newIndex,
              value: storedData.values[id][oldIndexPos],
              columnId: id
            });
          }
        }
      }

      const sortedIndexAndValues = sortIndexAndValues(
        newStoredIndex,
        storedData.values
      );

      setStoredData({
        ...storedData,
        index: sortedIndexAndValues.sortedIndex,
        values: sortedIndexAndValues.sortedValues
      });
      return mappedChanges;
    }
  }

  function handleUpdateChangedValues(changedValues: Array<ChangedValue>) {
    const requestData =
      prepareChangedValuesForPatchRequestAndEmitEvent(changedValues);

    setChangedValues(changedValues);
    onSetRequestData(requestData);
  }

  function handleEnergyUploadStarted(
    energyDataUploadPartialResults: Array<EnergyDataUploadPartialResult>
  ) {
    setUploading(true);

    Promise.allSettled(
      energyDataUploadPartialResults.map((result) => result.uploadPromise)
    ).then(() => {
      getEnergyDataImportRequests(variantId)
        .then((data) => {
          const dataImportRequestPromises = pollDataImportRequests(
            data.results
          );

          Promise.allSettled(dataImportRequestPromises).then(onReload);
        })
        .catch((error) => {
          openErrorAlertPopup(error);
          onReload();
        });
    });
  }

  function getRequestDataEntry(changesForCounter: Array<ChangedValue>) {
    return changesForCounter.reduce(
      (obj: Record<string | number, DataValue>, change) => {
        obj[change.index[0].replace("T", " ")] = change.value; // replace the T from 2020-01-01T00:00:00 because this caused issues with the decamelizing
        return obj;
      },
      {}
    );
  }

  function prepareChangedValuesForPatchRequestAndEmitEvent(
    changedValues: Array<ChangedValue>
  ) {
    // optimal data structure for storing the changes here and for the
    // patch request differ. This method performs the conversion
    const counterIds = _.uniq(changedValues.map((v) => v.columnId));
    const requestData = counterIds.reduce(
      (
        requestData: Record<string | number, Record<string, DataValue>>,
        counterId
      ) => {
        const changesForCounter = changedValues.filter(
          (v) => v.columnId === counterId
        );
        const requestDataEntry = getRequestDataEntry(changesForCounter);

        if (isGasConnectionDataView) {
          requestData[counterId] = requestDataEntry;
        } else if (!data[0].labels) {
          return {};
        } else {
          requestData[data[0].labels[counterId]] = requestDataEntry;
        }

        return requestData;
      },
      {}
    );

    return requestData;
  }
  function kWhValueRenderer(
    cell: GridElement,
    index: number,
    secondIndex: number
  ): string {
    if (typeof cell.value === "number") {
      const hasConversion =
        meters.find((meter) => meter.id === meterId)?.conversionFactor !== null;
      if (isGasConnectionDataView) {
        if (secondIndex === CALORIFIC_VALUE_COLUMN_POSITION) {
          return toStr(cell.value) + " kWh/m³";
        } else {
          return toStr(cell.value);
        }
      } else if (!hasConversion) {
        return toStr(cell.value) + " kWh";
      }
    }

    return toStr(cell.value);
  }

  return (
    <div className="CounterDataView">
      <Section>{DESCRIPTION_TEXT}</Section>
      <Row>
        <Col>
          <Button color="secondary" onClick={() => requestNewLines(false)}>
            Zeilen oben anfügen
          </Button>
          <Button color="secondary" onClick={() => requestNewLines(true)}>
            Zeilen unten anfügen
          </Button>
        </Col>
        <Col sm="auto">
          <SpinButton
            color="brand"
            disabled={!canUploadFile}
            spin={uploading}
            onClick={() => toggleUploadModal(true)}
          >
            Messwerte hochladen
          </SpinButton>
        </Col>
      </Row>
      <Section>
        {storedData && storedData.index.length > 0 ? (
          <div className="jump-controls">
            <div className="jump-text-date">
              <p className="jump-text">
                Suchen <Icon className="search-icon" name={IconName.Calendar} />
              </p>
              <DateInput
                date={skipDate}
                id="CounterDataViewDatePicker"
                showClearDate
                onChange={(date) => setSkipDate(date ?? undefined)}
              />
            </div>
            {initialPage === INVALID_PAGE_SET && (
              <Alert color={AlertColor.Info}>
                Für den ausgewählten Zeitpunkt konnten keine Messwerte gefunden
                werden.
              </Alert>
            )}
          </div>
        ) : (
          <p />
        )}
        <DataSheet
          changedValues={changedValues}
          customValueRenderer={kWhValueRenderer}
          initialData={storedData}
          initialPage={initialPage < 0 ? NO_PAGE_SET : initialPage}
          mode={mode}
          pageSize={PAGE_SIZE}
          onUpdateChangedIndex={handleUpdateChangedIndex}
          onUpdateChangedValues={handleUpdateChangedValues}
        />
      </Section>
      <EnergyDataUploadModal
        defaultMeterId={meterId}
        isOpen={isUploadModalOpen}
        meters={meters}
        sites={sites}
        variantId={variantId}
        onFileUploadStarted={handleEnergyUploadStarted}
        onToggle={() => toggleUploadModal(false)}
      />
    </div>
  );
}

const InnerCounterDataTabWithLoader = loader(CounterDataView);

export interface CounterDataViewLoaderWrapperProps {
  meterId: number;
  variantId: number;
  measurementType: MeasurementType;
  isGasConnectionDataView: boolean;
  onSetRequestData: OnSetRequestDataForMeter | OnSetRequestDataForGas;
}

function CounterDataViewLoaderWrapper({
  meterId,
  variantId,
  measurementType,
  isGasConnectionDataView,
  onSetRequestData
}: CounterDataViewLoaderWrapperProps) {
  const dataUrls: Array<string> = isGasConnectionDataView
    ? [urls.api.gasConnectionOption(meterId)]
    : [urls.api.energyDataRawData(meterId, false)];
  const {
    meters,
    sites,
    isLoading: isMetersLoading,
    error: metersError
  } = useVariantMeters(variantId);

  const mode: CounterDataViewMode =
    getCounterDataViewModeByMeasurementType(measurementType);

  return (
    <LoadOrError error={metersError} loading={isMetersLoading}>
      {meters && (
        <InnerCounterDataTabWithLoader
          dataUrls={dataUrls}
          defaultMeterId={meterId}
          isGasConnectionDataView={isGasConnectionDataView}
          meters={meters}
          mode={mode}
          sites={sites}
          variantId={variantId}
          onSetRequestData={onSetRequestData}
        />
      )}
    </LoadOrError>
  );
}

CounterDataViewLoaderWrapper.propTypes = {
  /** ID of the meter for which the counters should be displayed **/
  meterId: PropTypes.number.isRequired,
  measurementType: PropTypes.string.isRequired,
  /** Called with an object where keys are counter ids and
   * values corresponds to the response structure to update
   * the data for that counter**/
  onSetRequestData: PropTypes.func.isRequired,
  /** Variant ID the meter is assigned to **/
  variantId: PropTypes.number.isRequired
};

function getNumberOfNewLinesForFrequency(
  frequency?: Frequency,
  mode?: CounterDataViewMode
): number {
  if (mode === CounterDataViewMode.SparseIndex) {
    return 1;
  }

  if (frequency && frequency in NEW_LINES_FOR_FREQUENCY) {
    return NEW_LINES_FOR_FREQUENCY[frequency];
  }

  return 2;
}

export function sortIndexAndValues(
  index: Index,
  values: Record<number, Array<DataValue>>
): { sortedIndex: Index; sortedValues: Record<number, Array<DataValue>> } {
  const sorted = index
    .map((index: [string] | [string, string], number: number) => {
      const obj = {
        index: index,
        valueColumns: {}
      };

      for (const counterId of Object.keys(values)) {
        obj.valueColumns[counterId] = values[counterId][number];
      }

      return obj;
    })
    .sort((a, b) => {
      return a.index[0].localeCompare(b.index[0]);
    });

  const sortedValues = {};

  for (const s of sorted) {
    for (const counterId of Object.keys(s.valueColumns)) {
      if (sortedValues[counterId]) {
        sortedValues[counterId].push(s.valueColumns[counterId]);
      } else {
        sortedValues[counterId] = [s.valueColumns[counterId]];
      }
    }
  }

  return {
    //a mapped index is still an index
    sortedIndex: sorted.map((i) => i.index) as Index,
    sortedValues
  };
}

function cleanSparseData(
  data: CounterData,
  mode: CounterDataViewMode
): CounterData {
  const dataIds = Object.keys(data.header);
  if (mode === CounterDataViewMode.SparseIndex) {
    const newIndex: Index = [];
    const newValues: Record<number, Array<DataValue>> = dataIds.reduce(
      (obj, id) => {
        obj[id] = [];
        return obj;
      },
      {}
    );

    for (let i = 0; i < data.index.length; i++) {
      const hasValues = Object.keys(data.values).some(
        (counterId) =>
          data.values[counterId][i] !== null &&
          data.values[counterId][i] !== undefined
      );

      if (
        hasValues &&
        isSingleIndex(newIndex, mode) &&
        isSingleIndex(data.index, mode)
      ) {
        newIndex.push(data.index[i]);
        for (const counterId of Object.keys(data.values)) {
          if (newValues[counterId]) {
            newValues[counterId].push(data.values[counterId][i]);
          } else {
            newValues[counterId] = [data.values[counterId][i]];
          }
        }
      }
    }

    return {
      ...data,
      index: newIndex,
      values: newValues
    };
  } else {
    return data;
  }
}

export function findDuplicateInIndex(
  index: Index,
  entry: [string] | [string, string]
): boolean {
  //Working with sets is difficult because of the nested arrays so we use a nested some() call
  //https://stackoverflow.com/questions/29760644/storing-arrays-in-es6-set-and-accessing-them-by-value
  return index.some(
    (indexEntry) =>
      !indexEntry.some(
        (indexColumn, indexColumnIndex) =>
          !DateTime.fromISO(indexColumn).equals(
            DateTime.fromISO(entry[indexColumnIndex])
          )
      )
  );
}

function isIndexEqual(index1: Array<string>, index2: Array<string>): boolean {
  return !index1.some((element, nr) => element !== index2[nr]);
}

const CounterDataViewMemoized = memo(CounterDataViewLoaderWrapper);

export { CounterDataViewMemoized as CounterDataView };
