type NonNullableRecord<T> = {
  [K in keyof T]: number;
};

function sumByKeys<T extends Record<string, number | null>>(
  data: Array<T>
): NonNullableRecord<T> {
  return data.reduce((acc, curr) => {
    Object.keys(curr).forEach((key) => {
      acc[key as keyof T] = (acc[key as keyof T] || 0) + (curr[key] || 0);
    });
    return acc;
  }, {} as NonNullableRecord<T>);
}

export { sumByKeys };
