import { generatePath } from "react-router-dom";
import { appendQueryString } from "./api/helpers";
import { startPageApi } from "./api/subApis/startPageApi";
import type { LocationOverviewDataQueryParams } from "./components/AdminDashboard/LocationOverview/LocationOverview.types";
import type { TodoUserDecision } from "./components/Todos/common";
import { ROUTES } from "./routes";
import type { TodoCategory, VariantId } from "./utils/backend-types";
import { Unit } from "./utils/enums";
import { decamelize } from "./utils/SnakeCamelConversion";

export interface TodosApiUrlOptions {
  variantId?: number;
  demo?: boolean;
  byUser?: boolean;
  userDecision: TodoUserDecision;
  category: TodoCategory | Array<TodoCategory>;
  isRecurring?: boolean;
}

declare const BACKEND_BASE_URL: string | undefined;
const BASE_URL = BACKEND_BASE_URL || "";

export enum OptiSupportEndpoints {
  DatenerfassungRedispatch = "datenerfassung-redispatch",
  EnergiedatenBereitstellen = "energiedaten-bereitstellen",
  EnergiedatenAusreichend = "energiedaten-ausreichend",
  BerechnungDesAbschopfungsbetrags = "berechnung-des-absch%C3%B6pfungsbetrags",
  BeruecksichtigungFiktiveMengen = "ber%C3%BCcksichtigung-fiktive-mengen",
  OnboardingProzessPVDirekt = "onboarding-prozess-pv-direktlieferung",
  GutschriftErstellenParagraph6 = "gutschrift-erstellen-paragraph-6",
  GutschriftenNummernKreiseParagraph6 = "erstellen-individueller-gutschriftsnummernkreise",
  KommunaleBeteiligungNachParagraph6Eeg = "kommunale-beteiligung-nach-6-eeg",
  ZahlungsinformationenZurKommunalenBeteiligungNach6Eeg = "erstellung-von-zahlungsinformationen-zur-kommunalen-beteiligung-nach-6-eeg",
  RegulatorischeAufgaben = "regulatorischen-aufgaben",
  ScadaDatenUntermessschnittstelle = "scada-daten/untermessschnittstelle",
  Meldedaten = "meldetypen-die-opti.node-ihnen-zur-verfügung-stellt"
}

export enum OperatorFeatures {
  Basic = "basic",
  Stromsteuer = "stromsteuer",
  Paragraph6EEG = "paragraph_6_eeg",
  Abschoepfung = "abschoepfung",
  Parkabrechnung = "parkabrechnung",
  RevenueMonitoring = "erloesmonitoring",
  Invoices = "invoices",
  GuaranteeOfOrigin = "guarantee_of_origin",
  OptiCharge = "opticharge",
  PPAaaS = "ppaaas"
}

const urls = {
  startPage: startPageApi,
  api: {
    parkabrechnungPoolContracts: (variantId: number) =>
      appendQueryString("/api/parkabrechnung/pool-contracts/", {
        variant: variantId
      }),
    parkabrechnungPoolContract: (contractId: string) =>
      `/api/parkabrechnung/pool-contracts/${contractId}/`,
    parkabrechnungContractFormOptions: ({
      variantId
    }: {
      variantId: VariantId;
    }) =>
      appendQueryString(
        "/api/parkabrechnung/contracts/form-options/get-form-options/",
        { variant: variantId }
      ),
    parkabrechnungBelieferten: ({
      projectId,
      queryParams
    }: {
      projectId: string;
      queryParams: Record<string, string>;
    }) =>
      appendQueryString(
        `/api/parkabrechnung/${projectId}/consumer_companies/`,
        queryParams
      ),
    dataAvailabilityForFeedInProceeds: (
      contractId: string,
      startDate: string,
      endDate: string
    ) =>
      appendQueryString(
        `/api/parkabrechnung/${contractId}/data_availability_for_feedin_proceeds/`,
        {
          start_date: startDate,
          end_date: endDate
        }
      ),
    exportParkabrechnungFeedinProceeds: (
      contractId: string,
      startDate: string,
      endDate: string
    ) =>
      appendQueryString(`/api/parkabrechnung/${contractId}/feedin_proceeds/`, {
        start_date: startDate,
        end_date: endDate
      }),
    exportParkabrechnungDrittlieferung: ({
      projectId,
      queryParams
    }: {
      projectId: string;
      queryParams: Record<string, string | number>;
    }) =>
      appendQueryString(
        `/api/parkabrechnung/${projectId}/third_party_deliveries/`,
        queryParams
      ),
    exportParkabrechnungNetzbezug: ({
      projectId,
      queryParams
    }: {
      projectId: string;
      queryParams: {
        start_date: string;
        end_date: string;
        pool_contract_id?: string;
      };
    }) =>
      appendQueryString(
        `/api/parkabrechnung/${projectId}/grid_feedouts/`,
        queryParams
      ),

    exportParkabrechnungNetzbezugPerContract: ({
      projectId,
      queryParams
    }: {
      projectId: string;
      queryParams: {
        startDate: string;
        endDate: string;
        poolContractId: number;
      };
    }) => appendQueryString(`api/parkabrechnung/${projectId}/`, queryParams),
    ssoConfig: () => "api/configuration/sso/",
    componentsBase: (siteId: number) =>
      appendQueryString(`api/components-base/`, { site: siteId }),
    energyDataAcquisitionTags: () => "api/mbk/energy-data-acquisition-tags/",
    estimationProcedures: () => "api/mbk/estimation-procedures/",
    identityList: () => "api/mbk/identity-list/",
    deleteFullMbk: (siteId: number) => `/api/mbk/${siteId}/delete-full-mbk/`,
    mbk: (siteId: number) => `/api/mbk/${siteId}/`,
    mieterstromMbk: (siteId: number) =>
      `/api/mbk/create-for-mieterstrom/${siteId}/`,
    onboardingAssistantDocuments: (siteId: number) =>
      appendQueryString("api/onboarding-assistant/onboarding-todo-documents/", {
        site: siteId
      }),
    uploadSiteFile: (siteId: number) =>
      `api/onboarding-assistant/msb-documents/${siteId}/upload/`,
    siteCanGenerateDocuments: (siteId: number) =>
      appendQueryString(
        "api/onboarding-assistant/msb-documents/can-generate/",
        {
          site: siteId
        }
      ),
    siteDocumentsFiles: (siteId: number) =>
      appendQueryString("api/onboarding-assistant/msb-documents/", {
        site: siteId
      }),
    generateGuaranteeOfAttorney: (siteId: number) =>
      `/api/onboarding-assistant/msb-documents/${siteId}/generate-template/`,
    getGuaranteeOfAttorney: (siteId: number, taskId: string) =>
      appendQueryString(
        `/api/onboarding-assistant/msb-documents/${siteId}/generate-template/`,
        {
          task_id: taskId
        }
      ),
    siteWizardUpdate: (siteId: number) =>
      `api/onboarding-assistant/site-wizard/${siteId}/update-done/`,
    generateSiteDocuments: (siteId: number) =>
      `api/onboarding-assistant/msb-documents/${siteId}/generate/`,
    onboardingPhaseUpdateDone: () =>
      "api/onboarding-assistant/site-wizard/update-onboarding-phase-done/",
    onboardingProcessUpdateDone: () =>
      "api/onboarding-assistant/site-wizard/update-onboarding-process-done/",
    siteWizard: (siteId: number) =>
      `api/onboarding-assistant/site-wizard/${siteId}/`,
    siteNotifications: (siteId: number) => `api/site-notifications/${siteId}/`,
    energyWizard: (siteId: number) =>
      `api/onboarding-assistant/energy-wizard/${siteId}/`,
    energyWizardUpdate: (siteId: number) =>
      `api/onboarding-assistant/energy-wizard/${siteId}/update-done/`,
    customersList: () => "api/customers/",
    projectsList: () => "api/projects/",
    projectsDetail: (projectId: string) => `api/projects/${projectId}/`,
    projectsShare: (projectId: string) =>
      `${urls.api.projectsDetail(projectId)}share/`,
    projectsRevoke: (projectId: string) =>
      `/api/projects/${projectId}/revoke_share/`,
    projectsCopy: (projectId: string) => `api/projects/${projectId}/copy/`,
    analyzerProjectsCreate: () => "/api/projects-create-analyzer/",
    managerProjectsCreate: () => "/api/projects-create-manager/",
    setSiteInvoicing: (siteId: number, active: boolean) =>
      `api/sites/${siteId}/${active ? "activate" : "deactivate"}-invoicing/`,
    activateMeteringConceptV2: (siteId: number) =>
      `api/sites/${siteId}/activate-metering-concept-v2/`,
    deactivateMeteringConceptV2: (siteId: number) =>
      `api/sites/${siteId}/deactivate-metering-concept-v2/`,
    setSiteGo: (siteId: number, active: boolean) =>
      `api/sites/${siteId}/${active ? "activate" : "deactivate"}-go/`,
    setIsPremiumSite: (siteId: number, active: boolean) =>
      `api/sites/${siteId}/${active ? "activate" : "deactivate"}-premium/`,
    setSitePremiumTodos: (siteId: number, active: boolean) =>
      `api/sites/${siteId}/${active ? "activate" : "deactivate"}-premium-todos/`,
    sketchData: (siteId: number) =>
      appendQueryString("api/sketch-data/", { site: siteId }),
    extendedSketchData: (siteId: number) =>
      appendQueryString("api/extended-sketch-data/", { site: siteId }),
    meteringConceptSketchData: (siteId: number) =>
      appendQueryString("api/metering-concept-sketch-data/", {
        site: siteId
      }),
    mkExport: () => `api/mk-export/`,
    mkExportFinished: (taskId: string) =>
      appendQueryString("api/mk-export/", {
        task_id: taskId
      }),
    sketchElementsUpdate: (id: number | string) => `api/sketch-elements/${id}/`,
    sketchEdges: () => "/api/sketch-edges/",
    sketchEdgesDetail: (id: number) => `/api/sketch-edges/${id}/`,
    meteringConceptSketchEdgesDetail: (id: string) =>
      `/api/sketch-edges-with-connection-point-positions/${id}/`,
    loadprofilesList: () => "/api/loadprofiles/",
    loadprofilesListGeneration: (variantId: number) =>
      appendQueryString(urls.api.loadprofilesList(), {
        type: "generation",
        variant: variantId
      }),
    loadprofilesListConsumption: (variantId: number) =>
      appendQueryString(urls.api.loadprofilesList(), {
        type: "consumption",
        variant: variantId
      }),
    loadprofilesDetail: (loadprofileId: number) =>
      `/api/loadprofiles/${loadprofileId}/`,
    loadprofilesAssignToProject: (loadprofileId: number) =>
      `${urls.api.loadprofilesDetail(loadprofileId)}assign_to_project/`,
    loadprofilesUnassignFromProject: (loadprofileId: number) =>
      `${urls.api.loadprofilesDetail(loadprofileId)}unassign_from_project/`,
    loadprofilesGraph: (loadprofileId: number) =>
      `${urls.api.loadprofilesDetail(loadprofileId)}graph/`,
    loadprofilesDownload: (loadprofileId: number) =>
      `${urls.api.loadprofilesDetail(loadprofileId)}download/`,
    loadprofilesTemplateYears: () =>
      `${urls.api.loadprofilesList()}potential_download_years/`,
    loadprofilesTemplateDownload: () =>
      `${urls.api.loadprofilesList()}download_template/`,
    loadprofileCategories: () => "/api/loadprofile-categories/",
    deliveryConstraintsDetail: (constraintId: number) =>
      `/api/delivery-constraints/${constraintId}/`,
    deliveryConstraintsList: (siteId: number) =>
      appendQueryString("/api/delivery-constraints/create-and-list/", {
        site: siteId
      }),
    meteringConcept: (variantId: number) =>
      `/api/variants/${variantId}/metering-concepts/cashflows-per-person/`,
    meteringConceptEnergyFlows: (variantId: number) =>
      `/api/variants/${variantId}/metering-concepts/energy-flows/`,
    meteringConceptEnergyFlowsTimespan: (
      energyFlowsUrl: string,
      start: string,
      end: string
    ) => appendQueryString(energyFlowsUrl, { start: start, end: end }),
    meteringConceptLogMessages: (variantId: number) =>
      `/api/variants/${variantId}/metering-concept-log-messages/`,
    meteringServiceProviders: () => `/api/metering_service_providers/`,
    meteringServiceProvider: (id: number) =>
      `/api/metering_service_providers/${id}/`,
    variantDetail: (variantId: number) => `api/variants/${variantId}/`,
    variantCopy: (variantId: number) => `api/variants/${variantId}/copy/`,
    exportProjectAsExcel: (variantId: number) =>
      `/api/variants/${variantId}/export-project-as-excel/`,
    regulatoryDutiesListByVariant: (variantId: number) =>
      `/api/variants/${variantId}/duties/`,
    regulatoryDutiesList: () => `api/regulatory-duties/`,
    regulatoryDutyEventExport: (variantId: number, eventId: number) =>
      `/api/variants/${variantId}/duty-events/${eventId}/event.ics/`,
    todos: ({
      variantId,
      demo,
      userDecision,
      category,
      byUser,
      isRecurring
    }: TodosApiUrlOptions) =>
      appendQueryString("api/todos/", {
        variant: variantId,
        demo: demo,
        user_decision: userDecision,
        category: category,
        by_user: byUser,
        recurring: isRecurring
      }),
    systemCreatedTodos: (variantId: number) =>
      appendQueryString("api/todos/", {
        variant: variantId,
        system_created: "True"
      }),
    todosCreate: () => "api/todos/",
    todosDetail: (todoId: number) => `api/todos/${todoId}/`,
    todosUpdateList: () => `/api/todos/amend-todo-list-with-regulatory-duties/`,
    todoDocuments: () => "/api/todo-documents/",
    todoDocument: (documentId: number) => `/api/todo-documents/${documentId}/`,
    todoDocumentRename: (documentId: number) =>
      `/api/todo-documents/${documentId}/rename/`,
    todoDocumentDownload: (documentId: number) =>
      `/api/todo-documents/${documentId}/download/`,
    todoDownloadSelectedDocuments: (todoId: number) =>
      `/api/todos/${todoId}/download-selected-documents/`,
    todoDeleteSelectedDocuments: (todoId: number) =>
      `/api/todos/${todoId}/delete-selected-documents/`,
    todoCanGenerateDocuments: (todoId: number) =>
      `/api/todos/${todoId}/can-generate-documents/`,
    todoGenerateDocuments: (todoId: number) =>
      `/api/todos/${todoId}/generate-documents/`,
    todoGenerateProformaDocuments: (todoId: number) =>
      `/api/todos/${todoId}/generate-proforma-documents/`,
    todoDocumentProvided: (todoId: number) =>
      `/api/todos/${todoId}/update-document-provided/`,
    todoNotify: (todoId: number) => `/api/todos/${todoId}/notify/`,
    todoNoteCreate: () => "api/todo-notes/",
    todoNoteUpdate: (noteId: number) =>
      `${urls.api.todoNotesDetail(noteId)}perform-todo-note-update/`,
    todoNotes: (todoId: number) =>
      appendQueryString("api/todo-notes/", {
        todo: todoId
      }),
    todoNotesDetail: (noteId: number) => `/api/todo-notes/${noteId}/`,
    parameterSetCollection: () => "api/parameter-collection/",
    meterDataGaps: (
      firstDate: string,
      lastDate: string,
      unit: Unit,
      meter: number
    ) =>
      appendQueryString("api/meter-data-gaps/data/", {
        first_date: firstDate,
        last_date: lastDate,
        unit,
        meter
      }),
    energyDataAcquisitions: (variantId: number) =>
      `/api/energy-data-acquisitions/?variant=${variantId}`,
    energyDataAcquisitionsBySite: (siteId: number, date: string) =>
      appendQueryString(`/api/energy-data-acquisitions/`, {
        date: date,
        sites: siteId
      }),
    energyDataAcquisitionsWithFilters: (variantId: number, filters: string) =>
      `${urls.api.energyDataAcquisitions(variantId)}${filters}`,
    energyDataAcquisitionsCrud: (siteId: number) =>
      `/api/energy-data-acquisitions-crud/?site=${siteId}`,
    energyDataAcquisitionsCrudDetail: (edaId: number) =>
      `/api/energy-data-acquisitions-crud/${edaId}/`,
    energyDataDisplayData: () => `/api/energy-data-display/data/`,
    energyDataRawData: (
      meter: number,
      showMeterNamesInHeader: boolean,
      firstDate?: string,
      lastDate?: string,
      unit?: Unit
    ) =>
      appendQueryString("/api/meter-data-display/data/", {
        meter,
        show_meter_names_in_header: showMeterNamesInHeader,
        first_date: firstDate,
        last_date: lastDate,
        unit: unit
      }),
    energyDataRawDataRequestDownload: () =>
      `/api/metering-data-export/download/`,
    energyDataRawDataDownload: (taskId: string) =>
      appendQueryString(`/api/metering-data-export/download/`, {
        task_id: taskId
      }),
    energyDataAcquisitionFilters: (projectId?: string) =>
      appendQueryString("/api/energy-data-acquisition-filters/", {
        project: projectId
      }),
    energyDataAcquisitionFiltersDetail: (filterId: number) =>
      `/api/energy-data-acquisition-filters/${filterId}/`,
    energyDataAcquisitionFilterOptions: (projectId?: string) =>
      appendQueryString("/api/energy-data-acquisition-filter-options/", {
        project: projectId
      }),
    energyDataAcquisitionDeliveryModels: (siteId: number) =>
      appendQueryString(`/api/delivery-model/`, { site: siteId }),
    energyDataTemplateDownload: (
      variantId: number,
      firstDate: string,
      lastDate: string,
      selectedMeter: number | null,
      unit = Unit.KilowattHour
    ) =>
      appendQueryString(`/api/energy-data-transfer/template/`, {
        variant: variantId,
        first_date: firstDate,
        last_date: lastDate,
        selected_meter: selectedMeter,
        unit: unit
      }),
    energyDataTemplateMeters: (variantId: number) =>
      appendQueryString("/api/energy-data-transfer/meters-for-template/", {
        variant: variantId
      }),
    energyDataRequestDownload: () => `/api/energy-data-transfer/download/`,
    energyDataDownload: (taskId: string) =>
      appendQueryString(`/api/energy-data-transfer/download/`, {
        task_id: taskId
      }),
    energyDataUpload: () => "/api/data-import-requests/upload/",
    requestSupportForDataUpload: (requestId: string) =>
      `/api/data-import-requests/${requestId}/support/`,
    energyDataUploadFiles: () => "/api/data-import-requests/files/",
    fileUploadWithMeterRetry: (requestId: string) =>
      `/api/data-import-retry/${requestId}/meter/`,
    fileUploadWithOriginRetry: (requestId: string) =>
      `/api/data-import-retry/${requestId}/origin/`,
    fileUploadWithParameterSetRetry: (requestId: string) =>
      `/api/data-import-retry/${requestId}/parameter/`,
    energyDataUploadImportRequests: ({
      variant,
      page,
      page_size,
      ordering
    }: {
      variant?: number;
      page?: number;
      page_size?: number;
      ordering?: string;
    }) =>
      appendQueryString(
        `/api/data-import-requests/`,
        {
          variant,
          page,
          page_size,
          ordering: decamelize(ordering ?? "")
        },
        { skipNull: true, skipEmptyString: true }
      ),
    energyDataUploadImportRequest: (requestId: string) =>
      `/api/data-import-requests/${requestId}/`,
    energyDataUploadImportRequestDetails: (requestId: string) =>
      `/api/data-import-request-with-details/${requestId}/`,
    fileOriginsRequests: () => "api/file-origins/",
    energyDataSufficiencyAssessment: (
      siteId: number,
      year: number,
      taskId?: string
    ) =>
      appendQueryString("/api/data-sufficiency-assessment/", {
        site: siteId,
        year: year,
        task_id: taskId
      }),
    energyDataAcquisitonSuggestions: (siteId: number) =>
      appendQueryString(`/api/energy-data-acquisitions/suggestions/`, {
        site: siteId
      }),
    machineAccountConfigurations: (variant?: number) =>
      appendQueryString(
        "/api/machine-account-configuration/",
        { variant },
        { skipNull: true, skipEmptyString: true }
      ),
    machineAccountConfiguration: (id: number | string, variant?: number) =>
      appendQueryString(
        `/api/machine-account-configuration/${id}/`,
        { variant },
        { skipNull: true, skipEmptyString: true }
      ),
    reimportFormachineAccountConfiguration: (
      id: number | string,
      variant?: number
    ) =>
      appendQueryString(
        `/api/machine-account-configuration/${id}/reupload/`,
        { variant },
        { skipNull: true, skipEmptyString: true }
      ),
    simulationCreate: (siteId: number) =>
      appendQueryString(`/api/simulations/`, { site: siteId }),
    simulationDetail: (simulationId: string) =>
      `api/simulations/${simulationId}/`,
    evaluationDetail: (evaluationId: string) =>
      `api/evaluations/${evaluationId}/`,
    gasConnection: (gasConnectionId: number) =>
      `/api/gas-connections/${gasConnectionId}/`,
    gasConnectionOption: (gasConnectionId: number) =>
      `/api/gas-connections/${gasConnectionId}/gas-state-acquisition-data/`,
    gasConnections: (siteId: number) =>
      appendQueryString(`/api/gas-connections/`, { site: siteId }),
    paragraph6CreateCredit: () =>
      `/api/paragraph-6-eeg-credit-generation/start-generation/`,
    paragraph6DraftCreditStatus: () =>
      `/api/paragraph-6-eeg-credit-generation/get-draft-credit/`,
    generators: (siteId: number) =>
      appendQueryString(`/api/generators/`, { site: siteId }),
    generatorsByPerson: (personId: number) =>
      appendQueryString(`/api/generators/`, { person: personId }),
    generatorsByVariant: (variantId: number) =>
      appendQueryString(`/api/generators/`, { variant: variantId }),
    generator: (generatorId: number) => `/api/generators/${generatorId}/`,
    calculateVBH: (componentId: number, years: Array<number>) =>
      appendQueryString(`api/kwkanlage/calculate-vbh/`, {
        anlage: componentId,
        years: years
      }),
    importGeneratorsFromMastr: (siteId?: number) =>
      appendQueryString("/api/mastr-generators/", { site: siteId }),
    getCreatedGeneratorsFromMastr: (taskId: string) =>
      appendQueryString(
        "/api/mastr-generators/get-created-generator-from-mastr-result/",
        { task_id: taskId }
      ),
    updateGeneratorWithChangesFromMastr: (generatorId: number) =>
      `/api/mastr-generators/${generatorId}/update-generator-with-changes-from-mastr/`,
    getChangesFromMastr: (generatorId: number) =>
      `/api/mastr-generators/${generatorId}/get-changes-from-mastr/`,
    getChangesFromMastrResult: (taskId: string) =>
      appendQueryString(
        "/api/mastr-generators/get-changes-from-mastr-result/",
        {
          task_id: taskId
        }
      ),
    uploadGeneratorBatchImport: (
      siteId: number,
      clean?: boolean,
      ignoreOtherSites?: boolean
    ) =>
      appendQueryString(`/api/batch-mastr-generators/batch_import/`, {
        site: siteId,
        clean,
        ignore_other_sites: ignoreOtherSites
      }),
    getCreatedGeneratorsFromBatchImport: (taskId: string) =>
      appendQueryString("/api/batch-mastr-generators/get_created/", {
        task_id: taskId
      }),
    triggerGeneratorRules: (generatorId: number) =>
      `/api/generators/${generatorId}/trigger-rules/`,
    operationPeriods: (componentId: number) =>
      appendQueryString(`/api/operation-periods/`, { component: componentId }),
    operationPeriodsMeteringReadingsRequired: (componentId: number) =>
      appendQueryString(`/api/operation-periods/meter_readings_required/`, {
        component: componentId
      }),
    seeWind: (id: number) => `/api/seewind/${id}/`,
    consumers: (siteId: number) =>
      appendQueryString(`/api/consumers/`, { site: siteId }),
    consumersByVariant: (variantId: number) =>
      appendQueryString(`/api/consumers/`, { variant: variantId }),
    consumer: (consumerId: number) => `/api/consumers/${consumerId}/`,
    triggerConsumerRules: (consumerId: number) =>
      `/api/consumers/${consumerId}/trigger-rules/`,
    siteStatusOverview: () => "/api/site-status-overview/",
    siteStatusOverviewByYear: (year: number) =>
      `/api/site-status-overview/${year}/`,
    siteOverview: () => "/api/site-overview/",
    siteSearch: (search: string, limit: number, offset: number) =>
      appendQueryString("/api/site-search/", { search, limit, offset }),
    storages: (siteId: number) =>
      appendQueryString(`/api/storages/`, { site: siteId }),
    storage: (storageId: number) => `/api/storages/${storageId}/`,
    triggerStorageRules: (storageId: number) =>
      `/api/storages/${storageId}/trigger-rules/`,
    personsByVariantId: (variantId: number) =>
      appendQueryString(`/api/persons/`, { variant: variantId }),
    personsBySiteId: (siteId: number) =>
      appendQueryString(`/api/persons/`, { site: siteId }),
    person: (personId: number) => `/api/persons/${personId}/`,
    uploadPersonImage: (personId: number, fieldName: string) =>
      appendQueryString(`/api/persons/${personId}/upload_image/`, {
        field: fieldName
      }),
    deletePersonImage: (personId: number, fieldName: string) =>
      appendQueryString(`/api/persons/${personId}/delete_image/`, {
        field: fieldName
      }),
    uploadDeliveryImage: (deliveryId: number) =>
      `/api/deliveries/${deliveryId}/upload_identification_graphic/`,
    deleteDeliveryImage: (deliveryId: number) =>
      `/api/deliveries/${deliveryId}/delete_identification_graphic/`,
    uploadDeliveryFile: (deliveryId: number) =>
      `/api/deliveries/${deliveryId}/upload_identification_graphic_file/`,
    importPersonsFromMastr: () => "/api/mastr-companies/",
    getCreatedPersonFromMastr: (taskId: string) =>
      appendQueryString("/api/mastr-companies/", { task_id: taskId }),
    connections: (siteId: number) =>
      appendQueryString(`/api/connections/`, { site: siteId }),
    connection: (connectionId: number) => `/api/connections/${connectionId}/`,
    meters: (siteId: number) =>
      appendQueryString(`/api/meters/`, { site: siteId }),
    meterOptions: (siteId: number, instance: number) =>
      appendQueryString(`/api/meters/`, { site: siteId, instance: instance }),
    meteringConceptMeterOptions: (siteId: number, instance: number) =>
      appendQueryString(`/api/metering-concept-meter-fields/`, {
        site: siteId,
        instance: instance
      }),
    standaloneSketchUpdate: (nodeId: string) =>
      `api/sketch-elements-with-relative-positions/${nodeId}/`,
    standaloneSketchElementsTextBlock: () =>
      `api/standalone-sketch-elements/text-blocks/`,
    standaloneSketchElementsTextBlocks: (siteId: number) =>
      appendQueryString("api/standalone-sketch-elements/text-blocks/", {
        site: siteId
      }),
    standaloneSketchElementsTextBlockOptions: (textBlockId: string) =>
      `api/standalone-sketch-elements/text-blocks/${textBlockId}/`,
    standaloneSketchElementContainer: () =>
      `api/standalone-sketch-elements/containers/`,
    standaloneSketchElementsContainers: (siteId: number) =>
      appendQueryString("api/standalone-sketch-elements/containers/", {
        site: siteId
      }),
    standaloneSketchElementsContainerOptions: (containerId: string) =>
      `api/standalone-sketch-elements/containers/${containerId}/`,
    meterParameters: (configurationId: number) =>
      `/api/sub-metering-configurations/${configurationId}/get-meter-parameters/`,
    updateOrCreateMeterParameters: (configurationId: number) =>
      `/api/sub-metering-configurations/${configurationId}/update-or-create-meter-parameters/`,
    findMeterSuggestions: (configurationId: number) =>
      `/api/sub-metering-configurations/${configurationId}/find-meter-suggestions/`,
    getMeterSuggestions: (taskId: string) =>
      appendQueryString(
        `/api/sub-metering-configurations/get-meter-suggestions/`,
        { task_id: taskId }
      ),
    meter: (meterId: number) => `/api/meters/${meterId}/`,
    meteringConceptMeter: (meterId: number) =>
      `/api/metering-concept-meter-fields/${meterId}/`,
    consumptionShare: (consumptionShareId?: number) =>
      `/api/consumption-shares/${consumptionShareId ?? ""}`,
    ghostNodes: (siteId: number) =>
      appendQueryString(`api/ghost-nodes/`, { site: siteId }),
    ghostNode: (ghostNodeId: number) => `api/ghost-nodes/${ghostNodeId}/`,
    meteringLocations: (siteId: number) =>
      appendQueryString("/api/metering-locations/", { site: siteId }),
    meteringLocation: (meteringLocationId: number) =>
      `/api/metering-locations/${meteringLocationId}/`,
    marketLocations: (siteId: number) =>
      appendQueryString("/api/market-locations/", { site: siteId }),
    marketLocation: (marketLocationId: number) =>
      `/api/market-locations/${marketLocationId}/`,
    meteringOrMarketLocations: (siteId: number) =>
      appendQueryString("/api/metering-or-market-locations/", { site: siteId }),
    meteringOrMarketLocation: (meteringOrMarketLocationId: number) =>
      `/api/metering-or-market-locations/${meteringOrMarketLocationId}`,
    meteringOrMarketLocationsOverview: (
      queryParams: LocationOverviewDataQueryParams | null
    ) =>
      appendQueryString(
        "/api/metering-or-market-locations-overview/info/",
        queryParams,
        {
          arrayFormat: "comma"
        }
      ),
    suggestedMeteringOrMarketLocation: (generatorId: number) =>
      appendQueryString(
        "/api/einspeisezeitreihe-melo-malo/suggest_abrechnungsrelevante_einspeisezeitreihe_melo_malo/",
        { generator: generatorId }
      ),

    dispatchPVInvestmentCalculation: (scenarioId: number) =>
      `api/scenarios/${scenarioId}/dispatch_investment_calculation/`,
    downloadPVInvestmentCalculation: (scenarioId: string, taskId: string) =>
      appendQueryString(
        `/api/scenarios/${scenarioId}/download_investment_calculation/`,
        { task_id: taskId }
      ),
    individualGridFeesAnalyses: (variantId: number) =>
      `/api/variants/${variantId}/analyses/individual-grid-fees/`,
    downloadSelectedTodoDocuments: (variantId: number) =>
      `api/variants/${variantId}/download-selected-todos-documents/`,
    storageAssessmentAnalyses: (variantId: number) =>
      `/api/variants/${variantId}/analyses/battery-storage/`,
    updateTodoStatus: (todoId: number) => `/api/todos/${todoId}/update_status/`,
    updateTodoResponsible: (todoId: number) =>
      `/api/todos/${todoId}/update_responsible/`,
    updateTodoPriority: (todoId: number) =>
      `/api/todos/${todoId}/update_priority/`,
    updateTodoUserDecision: (todoId: number) =>
      `/api/todos/${todoId}/update_user_decision/`,
    updateTodoUserDecisions: () => `/api/todos/update-user-decisions/`,
    updateTodoDueDate: (todoId: number) =>
      `/api/todos/${todoId}/update_due_date/`,
    updateTodoPerson: (todoId: number) => `/api/todos/${todoId}/update_person/`,
    updateTodoOverdue: (todoId: number) =>
      `/api/todos/${todoId}/update_overdue/`,
    todoRuleAssessment: (todoId: number) =>
      `/api/todos/${todoId}/trigger-rule-assessment/`,
    computationAvailable: (variantId: number) =>
      `/api/computation-available/${variantId}/`,
    todoCalendarIntegrations: (variantId: number) =>
      appendQueryString(`/api/todo-calendars/`, { variant: variantId }),
    todoCalendarIntegrationDetail: (calendarIntegrationId: number) =>
      `/api/todo-calendars/${calendarIntegrationId}/`,
    todoCalendarIntegrationRefresh: (calendarIntegrationId: number) =>
      `/api/todo-calendars/${calendarIntegrationId}/refresh/`,
    settings: () => "/api/settings/",
    meterEnergyData: () => `api/update-meter-energy-data/`,
    counterDataForGasConnection: (gasConnectionId: number) =>
      `/api/gas-state-acquisition/${gasConnectionId}/data/`,
    dateRange: (params) => appendQueryString("api/date-range/", params),
    changePassword: () => "/api/users/me/change-password/",
    currentUserIsInvoiceAdmin: () => "/api/users/is-invoice-admin/",
    currentUserCanAssessManagerSiteFinancially: () =>
      "/api/users/can-assess-manager-site-financially/",
    allocatedMeteringValueRequest: (variantId: number) =>
      `/varianten/${variantId}/allocated-metering-values/`, // this is not an APIView in the backend
    allocatedMeteringValueDownload: (variantId: number, taskId: string) =>
      appendQueryString(
        `/varianten/${variantId}/allocated-metering-values/`, // this is not an APIView in the backend
        {
          task_id: taskId
        }
      ),
    anlagenStromstg: (params) =>
      appendQueryString("/api/anlagestromstg/", params),
    anlagenStromstgDetailUrl: (id: number) => `/api/anlagestromstg/${id}/`,
    anlageStromstgAddGenerator: (id: number) =>
      `/api/anlagestromstg/${id}/add-generator/`,
    anlageStromstgSetGenerators: (id: number) =>
      `/api/anlagestromstg/${id}/set-generators/`,
    anlagenStromstgListGenerators: (id: number) =>
      `/api/anlagestromstg/${id}/list-generators/`,
    subMeteringConfigurations: (variantId: number) =>
      appendQueryString("/api/sub-metering-configurations/", {
        variant: variantId
      }),
    subMeteringConfiguration: (id: number) =>
      `/api/sub-metering-configurations/${id}/`,
    subMeteringConfigurationMeterAssignments: (configurationId: number) =>
      `/api/sub-metering-configurations/${configurationId}/set-meter-assignments/`,
    subMeteringConfigurationHistoricalDataImport: (configurationId: number) =>
      `/api/sub-metering-configurations/${configurationId}/trigger_data_import/`,
    subMeteringConfigurationImportLogs: (
      configurationId: number,
      queryOptions: { offset: number; limit: number }
    ) =>
      appendQueryString(
        `/api/sub-metering-configurations/${configurationId}/sub-metering-logs/`,
        queryOptions
      ),
    invoices: (variantId: number, isArchived?: boolean) =>
      appendQueryString("/api/invoices/", {
        variant: variantId,
        archived: isArchived
      }),
    generateInvoices: (variantId: number) =>
      appendQueryString("/api/invoices/generate_invoices/", {
        variant: variantId
      }),
    generateSapExport: (variantId: number, month: number, year: number) =>
      appendQueryString(`/api/invoice-export/${variantId}`, {
        month: month,
        year: year
      }),
    getSapExport: (variantId: number, taskId: string) =>
      appendQueryString(`/api/invoice-export/${variantId}`, {
        task_id: taskId
      }),
    generatePreviewInvoices: (contractId: string) =>
      `/api/invoice-preview/${contractId}`,
    getPreviewInvoice: (contractId, taskId: string) =>
      appendQueryString(`/api/invoice-preview/${contractId}`, {
        task_id: taskId
      }),
    uploadInvoices: () => "/api/invoices-files/",
    invoice: (id: string) => `/api/invoices/${id}`,
    archiveInvoice: (id: string) => `/api/invoices/${id}/reverse/`,
    activeCompanies: (variantId: number) =>
      `/api/variants/${variantId}/active-companies/`,
    downloadInvoice: (id: string) => `/api/invoices/${id}/download/`,
    invoiceUpdateState: (id: string) => `/api/invoices/${id}/update_state/`,
    sendInvoiceToSupplied: (id: string) =>
      `/api/invoices/${id}/send_invoice_to_supplied/`,
    advancePaymentsPlans: (variantId: number) =>
      appendQueryString("/api/advance-payments-plans/not-archived/", {
        variant: variantId
      }),
    advancePaymentsPlan: (id: string) => `/api/advance-payments-plans/${id}/`,
    generateAdvancePaymentsPlans: (variantId: number, year: number) =>
      appendQueryString(
        "/api/advance-payments-plans/generate_advance_payments_plans/",
        { variant: variantId, year }
      ),
    archivedAdvancePaymentsPlans: (variantId: number) =>
      appendQueryString("/api/advance-payments-plans/archived/", {
        variant: variantId
      }),
    archiveAdvancePaymentsPlan: (id: string) =>
      `/api/advance-payments-plans/${id}/archive/`,
    downloadAdvancePaymentsPlan: (id: string) =>
      `/api/advance-payments-plans/${id}/download/`,
    fictionalEnergyValues: (variantId: number) =>
      appendQueryString(`/api/fiktive-strommengen/`, {
        variant: variantId
      }),
    fictionalEnergyValue: (id: string) => `/api/fiktive-strommengen/${id}/`,
    contracts: (variantId: number) =>
      appendQueryString("/api/contracts/", {
        variant: variantId
      }),
    contractFormOptions: (variantId: number) =>
      appendQueryString("/api/contracts/get-choices/", { variant: variantId }),
    contract: (id: string) => `/api/contracts/${id}/`,
    contractUpdateState: (id: string) => `/api/contracts/${id}/update_state/`,
    contractSupplierGenerators: (contractId: string, siteId: number) =>
      appendQueryString(`/api/contracts/${contractId}/supplier_generators/`, {
        site: siteId
      }),
    contractGetPaymentsSumFromPaymentsPlan: (
      contractId: string,
      year: number
    ) =>
      appendQueryString(
        `/api/contracts/${contractId}/get_payments_sum_from_payments_plan/`,
        {
          year
        }
      ),
    contractAllowanceSendInvoiceViaEmail: (id: string) =>
      `/api/contracts/${id}/got_allowance_to_send_invoice_via_email/`,
    rlmGridFees: (siteId: number) =>
      appendQueryString(`/api/rlm-grid-fees/`, { site: siteId }),
    slpGridFees: (siteId: number) =>
      appendQueryString(`/api/slp-grid-fees/`, { site: siteId }),
    deliveries: (variantId: number) =>
      appendQueryString("/api/deliveries/", {
        variant: variantId
      }),
    deliveryFormOptions: (variantId: number) =>
      appendQueryString("/api/deliveries/get-choices/", { variant: variantId }),
    delivery: (id: string) => `/api/deliveries/${id}/`,
    deliveryPrices: (id: string) => `/api/deliveries/${id}/working-prices/`,
    deliveryPricesPrognosis: (id: string) =>
      `/api/deliveries/${id}/working-prices-prognosis/`,
    deliveryPricesConcessionFees: (id: string) =>
      `/api/deliveries/${id}/concession-fees/`,
    deliveryPricesPartialTransferGridPrices: (id: string) =>
      `/api/deliveries/${id}/partial-transfer-grid-prices/`,
    deliveryGridUsagePowerPrices: (id: string) =>
      `api/deliveries/${id}/grid-usage-power-prices/`,
    deliverySpotMarketPricesAvailable: (siteId: number) =>
      appendQueryString(`/api/deliveries/are-spot-market-prices-available/`, {
        site: siteId
      }),
    report: (reportId: number) => `/api/reports/${reportId}/`,
    reportDownload: (reportId: number) =>
      `${urls.api.report(reportId)}download/`,
    reports: (variantId: number) =>
      appendQueryString("/api/reports/", { variant: variantId }),
    reportsOptions: (projectId: string) =>
      appendQueryString("/api/reports/", { project: projectId }),
    jobStatus: (taskId: string) =>
      appendQueryString("/api/job/status/", { task_ids: taskId }),
    performanceMonitoringOverview: (projectId: string, date: string) =>
      appendQueryString(`/api/performance-monitoring-overview/${projectId}/`, {
        date: date
      }),
    performanceMonitoringCreationDates: (projectId: string) =>
      `/api/performance-monitoring-overview/${projectId}/available-dates/`,
    direktvermarkter: () => "/api/direktvermarkter/",
    marketingContracts: (personId: number) =>
      appendQueryString("/api/vermarktungsvertraege/", { person: personId }),
    marketingContract: (id: number) => `/api/vermarktungsvertraege/${id}/`,
    checkSubMeteringConnection: (configId: number) =>
      `/api/sub-metering-configurations/${configId}/check-connection/`,
    guaranteeOfOriginSites: (variant) =>
      appendQueryString("/api/guarantee-of-origin-sites/", {
        variant
      }),
    dispatchGuaranteeOfOriginCheck: () =>
      "/api/guarantee-of-origin-generation/start-data-check/",
    fetchGuaranteeOfOriginCheck: (taskId?: string) =>
      appendQueryString("/api/guarantee-of-origin-generation/check/", {
        task_id: taskId
      }),
    dispatchGuaranteeOfOriginGeneration: () =>
      "/api/guarantee-of-origin-generation/start-generation/",
    fetchGuaranteeOfOriginDocument: (taskId: string) =>
      appendQueryString("/api/guarantee-of-origin-generation/document/", {
        task_id: taskId
      }),
    mieterstromBatchImport: {
      dispatchCheck: () => "/api/mieterstrom-batch-import/start-check/",
      fetchCheck: (taskId: string) =>
        appendQueryString("/api/mieterstrom-batch-import/check/", {
          task_id: taskId
        }),
      dipatchSiteCreation: () => "/api/sites/create-with-mieterstrom-template/",
      fetchSite: (taskId?: string) =>
        appendQueryString("/api/sites/create-with-mieterstrom-template/", {
          task_id: taskId
        })
    },
    mieterstromProcesses: {
      listOperatorChanges: (siteId) =>
        `/api/mieterstrom-processes/site-operator-change-processes/${siteId}/`,
      updateOperatorChanges: (siteId) =>
        `/api/mieterstrom-processes/site-operator-change-processes/${siteId}/update-done/`,
      listTenantChanges: (siteId) =>
        `/api/mieterstrom-processes/site-tenant-change-processes/${siteId}/`,
      updateTenantChanges: (siteId) =>
        `/api/mieterstrom-processes/site-tenant-change-processes/${siteId}/update-done/`
    },
    featureFlags: () => "/api/feature-flags/",
    operators: (args: {
      projectId?: string;
      variantId?: number;
      activeFeatures?: Array<OperatorFeatures>;
      withDetails?: boolean;
    }) => appendQueryString("/api/configurator/operators/", args)
  },

  apiWithoutCamelization: {
    sites: () => "api/sites/",
    sitesList: (variantId: number) =>
      appendQueryString(urls.apiWithoutCamelization.sites(), {
        variant: variantId
      }),
    sitesDetail: (siteId: number) => `api/sites/${siteId}/`,
    validateSEENumber: () => "/api/site-setup-assistant/validate-pv-plant/",
    meteringConceptTodos: (siteId: number) =>
      appendQueryString("api/onboarding-assistant/metering-concept-todos/", {
        site: siteId
      }),
    consumerHistory: (consumerId: number) =>
      `api/consumers/${consumerId}/get-history/`,
    contractHistory: (id: string) => `/api/contracts/${id}/get-history/`,
    currentUser: () => "/api/users/me/",
    deliveryHistory: (id: string) => `api/deliveries/${id}/get-history/`,
    direktvermarktungContracts: (variantId?: string) =>
      appendQueryString(`/api/direktvermarktungsvertraege/`, {
        variant_id: variantId
      }),
    direktvermarktungContract: (contractId: string) =>
      `/api/direktvermarktungsvertraege/${contractId}/`,
    direktvermarktungUnassignedGenerators: (contractUuid: string) =>
      `/api/direktvermarktungsvertraege/${contractUuid}/unassigned-generators/`,
    direktvermarktungGeneratorShares: (contractUuid: string) =>
      `/api/direktvermarktungsvertraege/${contractUuid}/generation-shares/`,
    direktvermarktungGeneratorShare: (
      contractUuid: string,
      generatorId: number
    ) =>
      `/api/direktvermarktungsvertraege/${contractUuid}/generation-shares/${generatorId}/`,
    generatorHistory: (generatorId: number) =>
      `api/generators/${generatorId}/get-history/`,
    meterHistory: (meterId: number) => `/api/meters/${meterId}/get-history/`,
    meteringOrMarketLocationsHistory: (id: number) =>
      `/api/metering-or-market-locations/${id}/get-history/`,
    meteringServiceProviderHistory: (id: number) =>
      `/api/metering_service_providers/${id}/get-history/`,
    oauthApplication: (applicationId: string) =>
      `/api/oauth/applications/${applicationId}/`,
    oauthApplications: () => "/api/oauth/applications/",
    oauthApplicationsOptionsForCreate: () =>
      "/api/oauth/applications/options-for-create/",
    paragraph6Companies: (variantId: number) =>
      `/api/paragraph-6-eeg-contracts/list-companies/?variant=${variantId}`,
    paragraph6Contracts: () => `/api/paragraph-6-eeg-contracts/`,
    paragraph6Contract: (contractId: string) =>
      `/api/paragraph-6-eeg-contracts/${contractId}/`,
    paragraph6Credit: (creditId: string) =>
      `/api/paragraph-6-eeg-credits/${creditId}/`,
    paragraph6CreditDocument: (creditId: string) =>
      `${urls.apiWithoutCamelization.paragraph6Credit(creditId)}download/`,
    paragraph6CreditUpdateState: (creditId: string) =>
      `${urls.apiWithoutCamelization.paragraph6Credit(creditId)}update-state/`,
    paragraph6Cancellation: (cancellationId: string) =>
      `/api/paragraph-6-eeg-cancellations/${cancellationId}/`,
    paragraph6Cancellations: (variantId: number) =>
      appendQueryString(`/api/paragraph-6-eeg-cancellations/`, {
        variant_id: variantId
      }),
    paragraph6CreateCancellation: () =>
      `/api/paragraph-6-eeg-cancellation-generation/start-generation/`,

    paragraph6CancellationDocument: (cancellationId: string) =>
      `${urls.apiWithoutCamelization.paragraph6Cancellation(cancellationId)}download/`,
    paragraph6ErzeugerAnteile: (contractId: string) =>
      `/api/paragraph-6-eeg-contracts/${contractId}/erzeuger-anteile/`,
    paragraph6ErzeugerAnteil: (contractId: string, generatorId: number) =>
      `/api/paragraph-6-eeg-contracts/${contractId}/erzeuger-anteile/${generatorId}/`,
    paragraph6UnassignedGenerators: (contractId: string) =>
      `/api/paragraph-6-eeg-contracts/${contractId}/unassigned-generators/`,
    paragraph6VariantContracts: (variantId: number) =>
      appendQueryString(urls.apiWithoutCamelization.paragraph6Contracts(), {
        variant_id: variantId
      }),
    paragraph6VariantCredits: (variantId: number) =>
      appendQueryString("/api/paragraph-6-eeg-credits/", {
        variant: variantId
      }),
    paragraph6CreatableCredits: (variantId: number) =>
      appendQueryString(
        `/api/paragraph-6-eeg-credit-generation/list-creatable-credits/`,
        { variant: variantId }
      ),
    paragraph6CreditErrors: (variantId: number) =>
      appendQueryString(
        "/api/paragraph-6-eeg-credit-generation/list-non-creatable-credits/",
        { variant: variantId }
      ),
    paragraph6CreditNumberRange: (creditNumberRangeId: string) =>
      `/api/paragraph-6-eeg-credit-number-ranges/${creditNumberRangeId}/`,
    paragraph6CreditNumberRanges: (variantId: number) =>
      appendQueryString(`/api/paragraph-6-eeg-credit-number-ranges/`, {
        variant_id: variantId
      }),
    personHistory: (personId: number) =>
      `/api/persons/${personId}/get-history/`,
    revenueMonitoringPerPerson: (args: { personId: number }) =>
      appendQueryString("/api/erloesmonitoring/revenue-for-person/", args),
    revenueMonitoringPerGenerator: (args: { generatorIds: Array<number> }) =>
      appendQueryString("/api/erloesmonitoring/revenue-for-generators/", args),
    siteCategories: (projectId: string) =>
      appendQueryString("api/site-categories/", {
        project: projectId
      }),
    siteCategoryForSite: (siteId: number) =>
      appendQueryString("api/site-categories/", {
        site: siteId
      }),
    siteCategoriesSummary: () => "/api/site-categories-summary/",
    sitePlausibility: (siteId: number, start: string, end: string) =>
      appendQueryString("/api/site-plausibility/", {
        site: siteId,
        start,
        end
      }),
    systemUsers: () => "/api/users/system/",
    userProfile: () => "/api/profile/",
    siteSetupAssistant: {
      mieterStromData: (siteSetupProcessId: string) =>
        `/api/site-setup-assistant/mieterstrom-data/${siteSetupProcessId}/`,
      sites: (variantId: number) =>
        appendQueryString(
          "api/site-setup-assistant/sites-with-setup-information/",
          {
            variant: variantId
          }
        )
    },
    variantDetail: (variantId: number) => `api/variants/${variantId}/`,
    generationShares: (siteId: number) =>
      appendQueryString("/api/generation-shares/", { site_id: siteId }),
    generationSharesDetail: (generator_id: number) =>
      `/api/generation-shares/${generator_id}/`
  },

  optiSupport: (endpoint?: string) =>
    `https://support.node.energy/knowledge/${endpoint ?? ""}`,
  optiCharge: () => "https://charge.node.energy/",
  auth: {
    login: () => "/auth/login/",
    loginWithCode: () => "/auth/login/code/",
    logout: () => "/auth/logout/",
    refresh: () => "/auth/refresh/",
    activate2FaMethod: (method: string) => `/auth/${method}/activate/`,
    confirm2FaMethod: (method: string) => `/auth/${method}/activate/confirm/`,
    deactivate2FaMethod: () => "/auth/mfa/deactivate/",
    active2FaMethods: () => "/auth/mfa/user-active-methods/",
    authConfig: () => "/auth/mfa/config/",
    generate2FaCode: () => "/auth/code/request/",
    resetPassword: () => "/auth/password-reset/",
    resetPasswordConfirm: () => "/auth/password-reset/confirm/",
    setPasswordConfirm: () => "/auth/set-password/confirm/",
    validateToken: () => "/auth/password-reset/validate-token/",
    microsoftLogin: () => `${BASE_URL}/microsoft/login`,
    roedlLogin: () => `${BASE_URL}/aad/roedl/login`
  },
  analyzerVariantStructure: (variantId: number, projectId: string) =>
    generatePath(ROUTES.analyzerVariantStructure, {
      variantId: variantId.toString(),
      projectId: projectId
    }),
  managerProjectView: (projectId: string) =>
    generatePath(ROUTES.managerProjectView, { projectId: projectId }),
  projectList: (selectedProjectId: string) =>
    ROUTES.analyzerProjectList +
    (selectedProjectId ? `?erweiterteVarianten=${selectedProjectId}` : ""),
  analyze: (variantId: number, projectId: string) =>
    generatePath(ROUTES.analyse, {
      variantId: variantId.toString(),
      projectId: projectId
    }),
  loadprofileManagerList: (projectId: string) =>
    generatePath(ROUTES.loadprofileManagerList, { projectId: projectId }),
  simulation: (simulationId: string, projectId: string) =>
    generatePath(ROUTES.simulation, {
      simulationId: simulationId,
      projectId: projectId
    }),
  siteStatusPage: (siteId: number) => `/site-status/${siteId}`,
  siteStatusPageByYear: (siteId: number, year: number) =>
    `/site-status/${siteId}/${year}`
};

export default urls;
