import { ActionIcon } from "@mantine/core";
import React from "react";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";

export enum ChartTableSwitchState {
  Chart = "chart",
  Table = "table"
}

interface ChartTableSwitchProps {
  value: ChartTableSwitchState;
  setValue: (value: ChartTableSwitchState) => void;
}

function ChartTableSwitch({ value, setValue }: ChartTableSwitchProps) {
  return (
    <ActionIcon.Group>
      <ActionIcon
        aria-label="Charts"
        size="md"
        variant={value === ChartTableSwitchState.Chart ? "filled" : "default"}
        onClick={() => setValue(ChartTableSwitchState.Chart)}
      >
        <Icon name={IconName.AreaChart} />
      </ActionIcon>
      <ActionIcon
        aria-label="Tabelle"
        size="md"
        variant={value === ChartTableSwitchState.Table ? "filled" : "default"}
        onClick={() => setValue(ChartTableSwitchState.Table)}
      >
        <Icon name={IconName.Table} />
      </ActionIcon>
    </ActionIcon.Group>
  );
}

export { ChartTableSwitch };
