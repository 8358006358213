import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import { apiWithoutCamelization } from "../../../api";
import urls from "../../../urls";
import type { DirektvermarktungContractOverviewResponse } from "../Direktvermarktung.types";

interface UseDirektvermarktungContractsOptions {
  variantId: string | null;
  options?: Partial<
    UseQueryOptions<Array<DirektvermarktungContractOverviewResponse>>
  >;
}

export function useDirektvermarktungContracts({
  variantId,
  options
}: UseDirektvermarktungContractsOptions) {
  const { data, isLoading, error } = useQuery({
    queryKey: ["direktvermarktungContracts"],
    queryFn: () => fetchDirektvermarktungContracts(variantId ?? undefined),
    ...options
  });

  async function fetchDirektvermarktungContracts(variantId?: string) {
    const direktvermarktungContractsUrl =
      urls.apiWithoutCamelization.direktvermarktungContracts(variantId);
    const response = await apiWithoutCamelization.get<
      Array<DirektvermarktungContractOverviewResponse>
    >(direktvermarktungContractsUrl);
    return response.data;
  }

  return {
    data,
    isLoading,
    error
  };
}
