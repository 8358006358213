import { Highlight } from "@mantine/core";
import React from "react";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { DeleteIcon } from "../../../Buttons/DeleteIcon";
import { EditIcon } from "../../../Buttons/EditIcon";
import "./DirektvermarktungContractTitle.scss";

type DirektvermarktungContractTitleProps = {
  name: string;
  id: string;
  generatorCount?: number;
  onClickDelete: (contractId: string) => void;
  onClickEdit: () => void;
  highlight?: string;
  isLoading?: boolean;
};

function DirektvermarktungContractTitle({
  isLoading,
  name,
  id,
  generatorCount,
  onClickDelete,
  onClickEdit,
  highlight = ""
}: DirektvermarktungContractTitleProps) {
  return (
    <div className="DirektvermarktungContractTitle">
      <div className="contract-name">
        <Highlight highlight={highlight}>{name}</Highlight>
      </div>
      <div className="generator-count">
        {isLoading ? (
          "Lade"
        ) : !generatorCount ? (
          <>
            <Icon name={IconName.ExclamationOrange} /> Keine
          </>
        ) : (
          generatorCount
        )}{" "}
        Erzeuger
      </div>
      <div className="action-panel">
        <span
          className="edit-icon"
          onClick={(e) => {
            onClickEdit();
            e.stopPropagation();
          }}
        >
          <EditIcon tooltipText={"Vertrag bearbeiten"} />
        </span>
        <DeleteIcon
          tooltipText="Vertrag löschen"
          onClick={(e) => {
            e.stopPropagation();
            onClickDelete(id);
          }}
        />
      </div>
    </div>
  );
}

export { DirektvermarktungContractTitle };
