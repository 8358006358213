import { merge } from "lodash";
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_SortingState,
  useMantineReactTable
} from "mantine-react-table";
import React, { useMemo, useState } from "react";
import { getDefaultMRTOptions } from "../../../mantine/getDefaultMRTOptions";
import { getMRTTopToolbarCustomActionsRenderer } from "../../../mantine/getMRTTopToolbarCustomActionsRenderer";
import { DeleteButton } from "../../../mantine/mrt/components/rowActions/DeleteButton";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { sortBackendDates } from "../../../utils/dates/sortBackendDates";
import type { OAuthApplicationResponse } from "../ApiSettings.types";
import { CreateOAuthApplicationModal } from "../CreateOAuthApplicationModal/CreateOAuthApplicationModal";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "created",
    desc: true
  }
];

interface OAuthApplicationsTableProps {
  applications: Array<OAuthApplicationResponse>;
  onDelete: (id: OAuthApplicationResponse) => void;
}

function OAuthApplicationsTable({
  applications,
  onDelete
}: OAuthApplicationsTableProps) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const columns = useMemo<Array<MRT_ColumnDef<OAuthApplicationResponse>>>(
    () => [
      {
        accessorFn: (row) =>
          luxonDateTimeToBackendDateOrDateTime(
            backendDateOrDateTimeToLuxonDateTime(row.created)
          ),
        id: "created",
        header: "Registriert am",
        enableColumnFilter: false,
        sortingFn: (rowA, rowB) =>
          sortBackendDates(rowA.original.created, rowB.original.created)
      },
      {
        accessorKey: "name",
        header: "Name der Applikation",
        enableColumnFilter: false
      }
    ],
    []
  );

  const defaultOptions = getDefaultMRTOptions<OAuthApplicationResponse>({
    emptyRowsFallbackText: "Keine Daten vorhanden.",
    searchTextInput: {
      placeholder: "Suche nach registrierten OAuth-Applikationen"
    }
  });

  const tableOptions = merge({}, defaultOptions, {
    ...getMRTTopToolbarCustomActionsRenderer<OAuthApplicationResponse>({
      create: {
        text: "Registriere neue Applikation",
        onClick: () => setIsModalOpen(true)
      },
      delete: {
        hide: true
      }
    }),
    displayColumnDefOptions: {
      "mrt-row-actions": {
        visibleInShowHideMenu: false
      }
    },
    enableColumnActions: true,
    enableRowActions: true,
    initialState: {
      showGlobalFilter: true,
      sorting: DEFAULT_SORTED
    },
    renderRowActions: ({ row }) => (
      <DeleteButton
        label="OAuth-Applikation löschen"
        onClick={() => onDelete(row.original)}
      />
    )
  });

  const table = useMantineReactTable({
    ...tableOptions,
    columns,
    data: applications
  });

  return (
    <div className="RegisteredOAuthApplicationsTable">
      <MantineReactTable table={table} />
      <CreateOAuthApplicationModal
        isOpen={isModalOpen}
        onClose={() => setIsModalOpen(false)}
      />
    </div>
  );
}

export { OAuthApplicationsTable, OAuthApplicationsTableProps };
