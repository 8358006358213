import { LineChart, type LineChartSeries } from "@mantine/charts";
import { ColorSwatch, Group, Paper, Stack, Text } from "@mantine/core";
import classNames from "classnames";
import { DateTime } from "luxon";
import React, { useMemo, useState } from "react";
import { ReferenceArea } from "recharts";
import { seriesColors } from "../../../../mantine/charts/mantine.constants";
import { THEME_VARS } from "../../../../utils/constants";
import { DATE_TIME_DISPLAY_FORMAT } from "../../../../utils/dates/dates.constants";
import { Button } from "../../../Buttons/Button/Button";
import { type CounterData } from "../../../DataSheet/DataSheet";
import "./AcquisitionLinePlot.scss";

type ZoomState = {
  refAreaLeft?: string | number;
  refAreaRight?: string | number;
  left: string | number;
  right: string | number;
  top: string | number;
  bottom: string | number;
};
const initialZoomState = {
  refAreaLeft: undefined,
  refAreaRight: undefined,
  left: "dataMin",
  right: "dataMax",
  top: "dataMax",
  bottom: "dataMin"
};

interface AcquisitionLinePlotProps {
  acquisitionSeries: CounterData;
  yAxisTitle: string;
}

function AcquisitionLinePlot({
  acquisitionSeries,
  yAxisTitle
}: AcquisitionLinePlotProps) {
  const [zoomState, setZoomState] = useState<ZoomState>(initialZoomState);
  const [activeSeries, setActiveSeries] = useState<Array<string>>([]);

  function handleLegendToggle(seriesId: string) {
    setActiveSeries((prev) =>
      prev.includes(seriesId)
        ? prev.filter((id) => id !== seriesId)
        : [...prev, seriesId]
    );
  }

  const dataIds = Object.keys(acquisitionSeries.header);
  const series: Array<LineChartSeries & { color: string }> = dataIds.map(
    (id, idIndex) => ({
      name: id,
      label:
        acquisitionSeries.labels?.[id].replaceAll("<br>", " ") || "Unbekannt",
      color: seriesColors[idIndex % seriesColors.length]
    })
  );
  const data = useMemo(
    () =>
      acquisitionSeries.index.map((index, indexIndex) => ({
        date: DateTime.fromISO(index[0]).toMillis(),
        ...dataIds.reduce(
          (result, id) => ({
            ...result,
            [id]: acquisitionSeries.values[id][indexIndex]
          }),
          {}
        )
      })),
    [acquisitionSeries, dataIds]
  );

  function zoomIn() {
    if (
      !zoomState.refAreaLeft ||
      !zoomState.refAreaRight ||
      zoomState.refAreaLeft === zoomState.refAreaRight ||
      zoomState.refAreaRight === ""
    ) {
      setZoomState({ ...zoomState, refAreaLeft: "", refAreaRight: "" });
      return;
    }

    // xAxis domain
    if (
      zoomState.refAreaLeft &&
      zoomState.refAreaRight &&
      zoomState.refAreaLeft > zoomState.refAreaRight
    )
      [zoomState.refAreaLeft, zoomState.refAreaRight] = [
        zoomState.refAreaRight,
        zoomState.refAreaLeft
      ];

    // yAxis domain
    const allYKeys = Object.keys(data[0]).filter((key) => key !== "date");
    const indexLeft = data.findIndex(
      (entry) => entry.date === zoomState.refAreaLeft
    );
    const indexRight = data.findIndex(
      (entry) => entry.date === zoomState.refAreaRight
    );
    const allVisibleValues = data
      .slice(indexLeft, indexRight + 1)
      .map((entry) => [...allYKeys.map((key) => entry[key])])
      .flat();
    const bottom = Math.min(...allVisibleValues);
    const top = Math.max(...allVisibleValues);

    setZoomState({
      ...zoomState,
      refAreaLeft: undefined,
      refAreaRight: undefined,
      left: zoomState.refAreaLeft,
      right: zoomState.refAreaRight,
      top: top,
      bottom: bottom
    });
  }

  function zoomOut() {
    setZoomState(initialZoomState);
  }

  return (
    <Group align="start" className="AcquisitionLinePlot" gap={0}>
      <Stack w="80%">
        <Group>
          <Button
            className="zoom-out-btn"
            disabled={
              zoomState.left === "dataMin" && zoomState.right === "dataMax"
            }
            size="small"
            onClick={zoomOut}
          >
            Zoom zurücksetzen
          </Button>
        </Group>
        <LineChart
          connectNulls={false}
          curveType="stepAfter"
          data={data}
          dataKey="date"
          h={500}
          lineChartProps={{
            onMouseDown: (e) => {
              setZoomState({
                ...zoomState,
                refAreaLeft: e.activeLabel || "",
                refAreaRight: e.activeLabel || ""
              });
            },
            onMouseMove: (e) =>
              zoomState.refAreaLeft &&
              setZoomState({ ...zoomState, refAreaRight: e.activeLabel || "" }),
            onMouseUp: zoomIn
          }}
          lineProps={(series) => ({ hide: activeSeries.includes(series.name) })}
          referenceLines={[
            { x: zoomState.refAreaLeft },
            { x: zoomState.refAreaRight },
            { y: 0, stroke: THEME_VARS.customGrey }
          ]}
          series={series}
          tooltipProps={{
            content: ({ label: timestamp, payload }) => (
              <ChartTooltip
                allSeriesLabels={acquisitionSeries.labels || {}}
                payload={payload}
                timestamp={
                  typeof timestamp === "number"
                    ? DateTime.fromMillis(timestamp).toFormat(
                        DATE_TIME_DISPLAY_FORMAT
                      )
                    : timestamp
                }
              />
            )
          }}
          withDots={false}
          xAxisLabel="Zeitraum"
          xAxisProps={{
            allowDataOverflow: true,
            domain: [zoomState.left, zoomState.right],
            tickFormatter: (timeStamp) =>
              DateTime.fromMillis(timeStamp).toFormat(DATE_TIME_DISPLAY_FORMAT),
            type: "number"
          }}
          yAxisLabel={yAxisTitle}
          yAxisProps={{
            allowDataOverflow: true,
            domain: ([dataMin, dataMax]) => [
              Math.min(0, dataMin),
              Math.max(0, dataMax)
            ],
            type: "number"
          }}
        >
          <ReferenceArea
            x1={zoomState.refAreaLeft}
            x2={zoomState.refAreaRight}
            yAxisId={"left"}
          />
        </LineChart>
      </Stack>
      <ChartLegend
        activeSeries={activeSeries}
        series={series}
        onClick={handleLegendToggle}
      />
    </Group>
  );
}

interface ChartTooltipProps {
  allSeriesLabels: Record<string, string>;
  timestamp: string;
  payload;
}

/*  Mantine doesn't allow changes to the recharts tooltip: https://mantine.dev/charts/line-chart/#custom-tooltip */
function ChartTooltip({
  allSeriesLabels,
  timestamp,
  payload
}: ChartTooltipProps) {
  if (!payload) return null;

  return (
    <Paper
      className="AcquisitionLinePlot ChartTooltip"
      px="md"
      py="sm"
      radius="md"
      shadow="md"
      withBorder
    >
      <Text fw={500} mb={5}>
        {timestamp}
      </Text>
      {payload.map((item) => (
        <Group justify="space-between" key={item.name}>
          <Group gap="sm">
            <div className="dot" style={{ backgroundColor: item.color }} />
            <Text fz="sm">
              {allSeriesLabels[item.name].replaceAll("<br>", " ") ||
                "Unbekannt"}
            </Text>
          </Group>
          <Text fz="sm">{item.value}</Text>
        </Group>
      ))}
    </Paper>
  );
}

interface ChartLegendProps {
  series: Array<LineChartSeries & { color: string }>;
  activeSeries: Array<string>;
  onClick: (seriesId: string) => void;
}

function ChartLegend({ series, activeSeries, onClick }: ChartLegendProps) {
  return (
    <Stack className="ChartLegend" gap={0} mah={500} pl="md" w="20%">
      {series.map((item) => {
        const isActive = !activeSeries.includes(item.name);
        return (
          <Button
            className={classNames(
              "legend-item",
              isActive ? "active" : "inactive"
            )}
            key={item.name}
            noBackground
            noBorder
            onClick={() => onClick(item.name)}
          >
            <ColorSwatch
              bd={isActive ? "none" : "2px solid " + item.color}
              color={isActive ? item.color : "#fff"}
              size="var(--mantine-font-size-md)"
            />
            <Text className="legend-label" ms="sm">
              {item.label}
            </Text>
          </Button>
        );
      })}
    </Stack>
  );
}

export { AcquisitionLinePlot, AcquisitionLinePlotProps };
