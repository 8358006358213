import { Group } from "@mantine/core";
import * as React from "react";
import { TodoStatus } from "../../../utils/backend-types";
import { showToast } from "../../../utils/toast";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { Mode } from "../../Todos/common";
import { useTodos } from "../../Todos/hooks/useTodos";
import { TodosDueSoon } from "./TodosDueSoon";
import { TodosProgress } from "./TodosProgress";

interface TodoStatsProps {
  variantId: number;
}

function TodoStats({ variantId }: TodoStatsProps) {
  const {
    todos,
    isLoading: todosLoading,
    error: todosError
  } = useTodos({
    mode: Mode.Normal,
    variantId,
    onUpdateError: (error) => showToast("error", error)
  });

  const allTodos = todos ?? [];
  const openTodos = allTodos.filter((todo) => todo.status === TodoStatus.Open);
  const doneTodos = allTodos.filter((todo) => todo.status === TodoStatus.Done);

  return (
    <LoadOrError
      className="TodoStats"
      error={todosError}
      loading={todosLoading}
    >
      {todos && (
        <Group mb="lg" wrap="nowrap">
          <TodosDueSoon todos={openTodos} />
          <TodosProgress
            done={doneTodos.length}
            total={openTodos.length + doneTodos.length}
          />
        </Group>
      )}
    </LoadOrError>
  );
}

export { TodoStats, TodoStatsProps };
