import {
  ConnectionMeterMeasurement_type,
  MeteringDirection,
  SiteSetupProcessDataDefault_consumer_measurement_type,
  SiteSetupProcessDataDefault_consumer_type,
  SiteSetupProcessDataDefault_pv_plants_measurement_type,
  VoltageLevel
} from "../../../types/api.types";
import urls from "../../../urls";
import type { FormFieldData } from "../../BuildingBlocks/Forms/Controllers/FormFieldController";
import {
  LocationType,
  type SiteSetupProcessForForm
} from "../SiteSetupAssistant.types";

export const SITE_SETUP_ASSISTANT_FORM = {
  name: {
    name: "name",
    type: "text",
    label: "Bezeichnung der Liegenschaft"
  },
  defaultPvPlantsMeasurementType: {
    name: "defaultPvPlantsMeasurementType",
    type: "dropdown",
    label: "Auf welche Art wird die Erzeugung der PV-Anlage(n) gemessen?",
    choices: [
      {
        value: SiteSetupProcessDataDefault_pv_plants_measurement_type.single,
        displayName: "Lastgang"
      },
      {
        value:
          SiteSetupProcessDataDefault_pv_plants_measurement_type.arbitrary_cumulative,
        displayName: "Zählerstände (selbst abgelesen)"
      },
      {
        value: SiteSetupProcessDataDefault_pv_plants_measurement_type.no_meter,
        displayName: "Kein Zähler vorhanden"
      }
    ]
  },
  siteAddress: {
    name: "siteAddress",
    type: "text",
    label: "Straße und Hausnummer"
  },
  siteZip: {
    name: "siteZip",
    type: "text",
    label: "Postleitzahl"
  },
  siteCity: {
    name: "siteCity",
    type: "text",
    label: "Ort"
  },
  connectionVoltageLevel: {
    name: "connectionVoltageLevel",
    type: "dropdown",
    label: "Spannungsebene",
    choices: [
      { value: VoltageLevel.Low, displayName: "Niederspannung" },
      {
        value: VoltageLevel.MediumTransformation,
        displayName: "Umspannung zu Niederspannung"
      },
      { value: VoltageLevel.Medium, displayName: "Mittelspannung" },
      {
        value: VoltageLevel.HighTransformation,
        displayName: "Umspannung zu Mittelspannung"
      },
      { value: VoltageLevel.High, displayName: "Hochspannung" },
      {
        value: VoltageLevel.ExtraHighTransformation,
        displayName: "Umspannung zu Hochspannung"
      },
      { value: VoltageLevel.ExtraHigh, displayName: "Höchstspannung" }
    ]
  },
  connectionMeasurementType: {
    name: "connectionMeasurementType",
    type: "radio",
    label: "Art der Messung",
    choices: [
      {
        value: ConnectionMeterMeasurement_type.single,
        displayName: "Lastgang"
      },
      {
        value: ConnectionMeterMeasurement_type.arbitrary_cumulative,
        displayName: "Zählerstände (selbst abgelesen)"
      },
      {
        value: ConnectionMeterMeasurement_type.virtual,
        displayName: "Kein physischer Zähler (z.B. virtueller Summenzähler)"
      }
    ]
  },
  connectionMeteringDirection: {
    name: "connectionMeteringDirection",
    type: "dropdown",
    label: "Messrichtung",
    choices: [
      {
        value: MeteringDirection.Erzeugung_Einspeisung,
        displayName: "Erzeugung/Einspeisung"
      },
      {
        value: MeteringDirection.Verbrauch_Entnahme,
        displayName: "Verbrauch/Entnahme"
      },
      {
        value: MeteringDirection.Vier_Quadranten_Zaehler,
        displayName: "Vier-Quadranten-Zähler"
      },
      {
        value: MeteringDirection.Zweirichtungszaehler,
        displayName: "Zweirichtungszähler"
      }
    ]
  },
  connectionMeteringServiceProvider: {
    name: "connectionMeteringServiceProvider",
    type: "async-select",
    label: "Messstellenbetreiber",
    placeholder: "Name oder Marktpartneridentifikationsnummer",
    dataUrl: urls.api.meteringServiceProviders()
  },
  connectionLocationType: {
    name: "connectionLocationType",
    type: "radio",
    label: "Ich kenne Folgendes:",
    choices: [
      { value: LocationType.MeteringLocation, displayName: "Messlokation" },
      { value: LocationType.MarketLocation, displayName: "Marktlokation" },
      { value: LocationType.None, displayName: "Keines davon" }
    ]
  },
  connectionMarketLocationFeedin: {
    name: "connectionMarketLocationFeedin",
    label: "Marktlokation (Einspeisung)",
    type: "text"
  },
  connectionMarketLocationFeedout: {
    name: "connectionMarketLocationFeedout",
    label: "Marktlokation (Entnahme)",
    type: "text"
  },
  connectionMeteringLocation: {
    name: "connectionMeteringLocation",
    label: "Messlokation",
    type: "text"
  },
  siteHasBatteryStorage: {
    name: "siteHasBatteryStorage",
    type: "boolean",
    label: "Befindet sich ein Batteriespeicher in der Liegenschaft?"
  },
  isBatteryStorageMeasuredSeparately: {
    name: "isBatteryStorageMeasuredSeparately",
    type: "boolean",
    label: "Hat der Batteriespeicher einen separaten Zähler?"
  },
  consumerAmount: {
    name: "consumerAmount",
    type: "number",
    label: "Anzahl der Verbrauchsstellen",
    placeholder: "Anzahl eingeben",
    min: 1,
    max: 200
  },
  defaultConsumerType: {
    name: "defaultConsumerType",
    type: "radio",
    label:
      "Vornehmlicher Typ der Belieferten (kann später im Detail bearbeitet werden)",
    choices: [
      {
        value: SiteSetupProcessDataDefault_consumer_type.private_household,
        displayName: "Privathaushalt(e)"
      },
      {
        value: SiteSetupProcessDataDefault_consumer_type.industry,
        displayName: "Gewerbe"
      }
    ]
  },
  defaultConsumerMeasurementType: {
    name: "defaultConsumerMeasurementType",
    type: "radio",
    label:
      "Alle Verbraucher werden wie folgt gemessen (kann später im Detail bearbeitet werden)",
    choices: [
      {
        value: SiteSetupProcessDataDefault_consumer_measurement_type.single,
        displayName: "Lastgang"
      },
      {
        value:
          SiteSetupProcessDataDefault_consumer_measurement_type.arbitrary_cumulative,
        displayName: "Zählerstände (selbst abgelesen)"
      },
      {
        value: SiteSetupProcessDataDefault_consumer_measurement_type.no_meter,
        displayName: "Kein Zähler vorhanden"
      }
    ]
  }
} as const satisfies FormFieldData<SiteSetupProcessForForm>;
