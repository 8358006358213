import { Group } from "@mantine/core";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { usePagedContent } from "../../../hooks/usePagedContent";
import { MantinePagination } from "../../../mantine/components/MantinePagination/MantinePagination";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { NoContracts } from "../../NoContracts/NoContracts";
import { useParagraph6Contracts } from "../hooks/useParagraph6Contracts";
import { Paragraph6ContractInformation } from "./Paragraph6ContractInformation/Paragraph6ContractInformation";
import { Paragraph6ContractModals } from "./Paragraph6ContractModals/Paragraph6ContractModals";
import "./Paragraph6ContractPage.scss";

const INITIAL_PAGE_SIZE = 5;

interface Paragraph6ContractPageProps {
  variantId: number;
}

function Paragraph6ContractPage({ variantId }: Paragraph6ContractPageProps) {
  const [newContractModalOpen, setNewContractModalOpen] = useState(false);
  const [newGeneratorModalOpen, setNewGeneratorModalOpen] = useState(false);
  const [modalContractId, setModalContractId] = useState<string | null>(null);
  const [pageSize, setPageSize] = useState(INITIAL_PAGE_SIZE);
  const [page, setPage] = useState(1);

  const { paragraph6Contracts, isLoading: paragraph6ContractsLoading } =
    useParagraph6Contracts(variantId);

  const { pagedItems: pagedParagraph6Contracts } = usePagedContent({
    items: paragraph6Contracts ?? [],
    pageSize,
    page,
    onChangePage: setPage
  });
  const showPagination = paragraph6Contracts
    ? paragraph6Contracts.length > INITIAL_PAGE_SIZE
    : false;

  const navigate = useNavigate();

  function handleClickEditContract(contractId: string) {
    navigate(contractId);
  }

  function handleClickNewGeneratorShare(contractId: string) {
    setModalContractId(contractId);
    setNewGeneratorModalOpen(true);
  }

  function handleClickEditGeneratorShare(
    generatorShareId: number,
    contractId: string
  ) {
    setModalContractId(contractId);
    navigate(`erzeuger-bearbeiten/${generatorShareId}`);
  }

  return (
    <div className="Paragraph6ContractPage">
      <Paragraph6ContractModals
        contractId={modalContractId}
        newContractModalOpen={newContractModalOpen}
        newGeneratorModalOpen={newGeneratorModalOpen}
        templates={paragraph6Contracts?.filter(
          (contract) => contract.is_template
        )}
        variantId={variantId}
        onNewContractModalClose={() => setNewContractModalOpen(false)}
        onNewGeneratorModalClose={() => setNewGeneratorModalOpen(false)}
      />
      {paragraph6Contracts && paragraph6Contracts.length > 0 ? (
        <>
          <div className="contracts-header">
            <IconButton
              color={buttonColors.brand}
              iconName={IconName.Plus}
              onClick={() => setNewContractModalOpen(true)}
            >
              Vertrag hinzufügen
            </IconButton>
          </div>
          <div className="contracts-body">
            {pagedParagraph6Contracts[page - 1].map((contract) => (
              <Paragraph6ContractInformation
                contract={contract}
                key={contract.id}
                onClickEditContract={handleClickEditContract}
                onClickEditGeneratorShare={(generatorShareId) =>
                  handleClickEditGeneratorShare(generatorShareId, contract.id)
                }
                onClickNewGeneratorShare={handleClickNewGeneratorShare}
              />
            ))}
          </div>
          {showPagination && (
            <Group justify="flex-end" my="lg">
              <MantinePagination
                page={page}
                pageSize={pageSize}
                totalPages={pagedParagraph6Contracts.length}
                onChangePage={setPage}
                onChangePageSize={(size) =>
                  setPageSize(size ?? INITIAL_PAGE_SIZE)
                }
              />
            </Group>
          )}
        </>
      ) : (
        <LoadOrError
          loading={paragraph6ContractsLoading}
          loadingMessage="Verträge werden geladen ..."
        >
          <NoContracts
            onClickAddContract={() => setNewContractModalOpen(true)}
          />
        </LoadOrError>
      )}
    </div>
  );
}

export { Paragraph6ContractPage, Paragraph6ContractPageProps };
