import classNames from "classnames";
import _ from "lodash";
import type { DateTime } from "luxon";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import api from "../../../api";
import { useContainerRef } from "../../../hooks/useContainerRef";
import { useProject } from "../../../hooks/useProject";
import urls from "../../../urls";
import { Alert, AlertColor } from "../../Alert/Alert";
import { DateInput } from "../../BuildingBlocks/Dates/DateInput/DateInput";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import type { Acquisition } from "../../EnergyData/EnergyDataView/EnergyDataView";
import { openErrorAlertPopup } from "../../ErrorAlertPopup/openErrorAlertPopup";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { CreateEdaModal } from "../CreateEdaModal/CreateEdaModal";
import { EdaDetailModal } from "../EdaDetailModal/EdaDetailModal";
import { EdaTable } from "../EdaTable/EdaTable";
import { EditEdaModal } from "../EditEdaModal/EditEdaModal";
import "./EdaVisualization.scss";

interface AcquisitionSuggestion {
  read: Acquisition;
  write: DetailAcquisition;
}

export interface DetailAcquisition extends Acquisition {
  annualConsumption: number | null;
  constantLoadKw: number | null;
  consumerType: string | null;
  customLabel: string | null;
  estimationEnergyData: number | null;
  estimationEnergyDataExampleMeasuring: number | null;
  estimationProcedure: string | null;
  formulaStr: string | null;
  meter: number;
  meteringOrMarketLocation: string | null;
  numberOfConsumers: number | null;
  ratioOfEnergyData: number | null;
  references: Array<string>;
  securityBuffer: number | null;
}

export interface AcquisitionWithSuggestion extends Acquisition {
  write?: DetailAcquisition;
  suggestion?: boolean;
}

interface EdaVisualizationProps {
  filterDate: DateTime;
  initialAcquisitions: Array<Acquisition> | undefined;
  initialAcquisitionsError: Error | null;
  initialAcquisitionsIsLoading: boolean;
  projectId: string;
  siteId: number;
  onChangeFilterDate: (date: DateTime | null) => void;
}

function EdaVisualization({
  filterDate,
  initialAcquisitions,
  initialAcquisitionsError,
  initialAcquisitionsIsLoading,
  projectId,
  siteId,
  onChangeFilterDate
}: EdaVisualizationProps) {
  const [acquisitions, setAcquisitions] = useState<
    Array<AcquisitionWithSuggestion>
  >([]);
  const [selectedAcquisitionId, setSelectedAcquisitionId] = useState<
    number | null
  >(null);
  const {
    containerNode: exportButtonContainerNode,
    containerRef: exportButtonContainerRef
  } = useContainerRef();
  const { project } = useProject(projectId);
  const isStaff = useShouldShowStaffView();

  const [isCreateModalOpen, setIsCreateModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isSuggestionsLoading, setIsSuggestionsLoading] = useState(false);
  const [showNoSuggestionsInfo, setShowNoSuggestionsInfo] = useState(false);

  useEffect(() => {
    const initialOrderedAcquistions = _.orderBy(
      initialAcquisitions,
      ["publicId"],
      ["asc"]
    );
    setAcquisitions(initialOrderedAcquistions);
  }, [initialAcquisitions]);

  const handleOpenDetailModal = useCallback(
    (id) => setSelectedAcquisitionId(id),
    [setSelectedAcquisitionId]
  );

  function handleCloseDetailModal() {
    setSelectedAcquisitionId(null);
  }

  function handleToggleCreateModal() {
    setIsCreateModalOpen(!isCreateModalOpen);
  }

  function handleToggleEditModal() {
    setIsEditModalOpen(!isEditModalOpen);
  }

  function handleCreate(newAcquisition: Acquisition) {
    const newAcquisitions = [...acquisitions, newAcquisition];

    setIsCreateModalOpen(false);
    setAcquisitions(newAcquisitions);
    setSelectedAcquisitionId(newAcquisition.id);
  }

  function handleUpdate(changedAcquisition: Acquisition) {
    const updatedAcquisitions = [...acquisitions];
    const changedAcquisitionIndex = updatedAcquisitions.findIndex(
      (acquisition) => acquisition.id === changedAcquisition.id
    );

    updatedAcquisitions[changedAcquisitionIndex] = changedAcquisition;

    setIsEditModalOpen(false);
    setAcquisitions(updatedAcquisitions);
  }

  function handleDelete() {
    if (!selectedAcquisitionId) {
      return;
    }

    const oldAcquisitions = [...acquisitions];
    const newAcquisitions = acquisitions.filter(
      (acquisition) => acquisition.id !== selectedAcquisitionId
    );
    setAcquisitions(newAcquisitions);

    api
      .delete(urls.api.energyDataAcquisitionsCrudDetail(selectedAcquisitionId))
      .catch((error) => {
        openErrorAlertPopup(error);
        setAcquisitions(oldAcquisitions);
      })
      .finally(() => setSelectedAcquisitionId(null));
  }

  function handleGetSuggestions() {
    const savedAcquisitions = acquisitions.filter((a) => a.suggestion !== true);

    setIsSuggestionsLoading(true);
    setShowNoSuggestionsInfo(false);
    setAcquisitions(savedAcquisitions);

    api
      .get<Array<AcquisitionSuggestion>>(
        urls.api.energyDataAcquisitonSuggestions(siteId)
      )
      .then((response) => {
        if (response.data.length === 0) {
          setShowNoSuggestionsInfo(true);
        }

        const suggestedAcquisitions = response.data.map((suggestion) => {
          return {
            ...suggestion.read,
            write: suggestion.write,
            suggestion: true
          };
        });

        setAcquisitions([...suggestedAcquisitions, ...savedAcquisitions]);
      })
      .catch((error) => {
        openErrorAlertPopup(error);
      })
      .finally(() => {
        setIsSuggestionsLoading(false);
      });
  }

  const handleSaveSuggestedAcquisition = useCallback(
    (acquisitionDataToCreate) => {
      setIsSuggestionsLoading(true);
      const postUrl = urls.api.energyDataAcquisitionsCrud(siteId);
      api
        .post(postUrl, acquisitionDataToCreate)
        .then((response) => {
          const newAcquisitions = acquisitions.map((a) =>
            _.isEqual(a.write, acquisitionDataToCreate)
              ? { ...response.data }
              : a
          );
          setAcquisitions(newAcquisitions);
        })
        .catch((error) => {
          openErrorAlertPopup(error);
        })
        .finally(() => {
          setIsSuggestionsLoading(false);
        });
    },
    [setIsSuggestionsLoading, acquisitions, setAcquisitions, siteId]
  );

  const selectedAcquisiton = acquisitions.find(
    (acquisition) => acquisition.id === selectedAcquisitionId
  );

  return (
    <div className="EdaVisualization">
      <div className="eda-buttons">
        <div className="date-filter">
          <span className="label">Messkonzept am</span>
          <DateInput
            date={filterDate}
            id="eda-visualization-date-filter"
            onChange={onChangeFilterDate}
          />
        </div>
        <div className="control-buttons">
          {(isStaff || project?.customerMbkWriteAccessActive) && (
            <IconButton
              className="control-button-new"
              color="brand"
              iconName={IconName.PlusCircle}
              role="button"
              onClick={() => setIsCreateModalOpen(true)}
            >
              Neue Energiedatenerfassung
            </IconButton>
          )}
          {(isStaff || project?.customerMbkWriteAccessActive) && (
            <SpinButton
              className="control-button-get"
              color="secondary"
              role="button"
              spin={isSuggestionsLoading}
              onClick={handleGetSuggestions}
            >
              Vorschläge anbieten
            </SpinButton>
          )}
          <div ref={exportButtonContainerRef} />
        </div>
      </div>
      {showNoSuggestionsInfo && (
        <Alert
          color={AlertColor.Info}
          withCloseButton
          onClose={() => setShowNoSuggestionsInfo(false)}
        >
          Es können keine weiteren Energiedatenerfassungen automatisch angelegt
          werden.
        </Alert>
      )}
      <LoadOrError
        error={initialAcquisitionsError}
        loading={initialAcquisitionsIsLoading}
      >
        <EdaTable
          acquisitions={acquisitions}
          className={classNames({ "showing-alert": showNoSuggestionsInfo })}
          exportButtonContainerNode={exportButtonContainerNode}
          loading={isSuggestionsLoading}
          readOnly={!isStaff && !project?.customerMbkWriteAccessActive}
          onClickLabelRow={handleOpenDetailModal}
          onSaveSuggestion={handleSaveSuggestedAcquisition}
        />
      </LoadOrError>
      <EdaDetailModal
        acquisition={selectedAcquisiton}
        isOpen={selectedAcquisitionId !== null}
        readOnly={!isStaff && !project?.customerMbkWriteAccessActive}
        onClickDelete={handleDelete}
        onCloseModal={handleCloseDetailModal}
        onOpenEditAcquisitionModal={handleToggleEditModal}
      />
      <CreateEdaModal
        isOpen={isCreateModalOpen}
        siteId={siteId}
        onCreate={handleCreate}
        onToggle={handleToggleCreateModal}
      />
      <EditEdaModal
        acquisitionId={selectedAcquisitionId}
        isOpen={isEditModalOpen}
        siteId={siteId}
        onToggle={handleToggleEditModal}
        onUpdate={handleUpdate}
      />
    </div>
  );
}

EdaVisualization.propTypes = {
  siteId: PropTypes.number.isRequired
};

export { EdaVisualization };
