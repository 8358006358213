import type { DateTime } from "luxon";
import React from "react";
import type { SiteBasic } from "../../../utils/backend-types";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { showToast } from "../../../utils/toast";
import {
  DatePresetType,
  UncontrolledDateRangePicker
} from "../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";
import { Select } from "../../BuildingBlocks/Forms/Select/Select";
import { buttonColors } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import type { GuaranteeOfOriginPeriod } from "../GuaranteeOfOrigin.types";
import { useGOCheck } from "../hooks/useGOCheck";
import { useGOGenerate } from "../hooks/useGOGenerate";
import "./GuaranteeOfGenerationView.scss";
import { GuaranteeOfOriginCheckResult } from "./GuaranteeOfOriginCheckResult/GuaranteeOfOriginCheckResult";

interface SiteOption {
  value: number;
  label: string;
}

export interface GuaranteeOfOriginGenerationViewProps {
  sites: Array<SiteBasic>;
}

function GuaranteeOfOriginGenerationView({
  sites
}: GuaranteeOfOriginGenerationViewProps) {
  const [startDate, setStartDate] = React.useState<DateTime | undefined>(
    undefined
  );
  const [endDate, setEndDate] = React.useState<DateTime | undefined>(undefined);
  const [selectedSite, setSelectedSite] = React.useState<
    SiteOption | undefined
  >(undefined);

  const siteSelectionEnabled = !!startDate && !!endDate;
  const periods = asListOfPeriods(selectedSite, startDate, endDate);
  const {
    result,
    loading: isCheckLoading,
    error: checkError
  } = useGOCheck(periods);
  const { generateAndDownloadDocument, loading: isGenerationLoading } =
    useGOGenerate();
  const documentGenerationIsEnabled =
    result.length > 0 &&
    result.every((r) => r.isOnboardingComplete) &&
    !isCheckLoading;

  function handleDateRangeChanged(change: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) {
    const { startDate, endDate } = change;
    setStartDate(startDate ?? undefined);
    setEndDate(endDate ?? undefined);
  }

  function asListOfPeriods(
    site?: SiteOption,
    startDate?: DateTime,
    endDate?: DateTime
  ): Array<GuaranteeOfOriginPeriod> {
    if (!site || !startDate || !endDate) {
      return [];
    }

    return [
      {
        site: site.value,
        startDate: luxonDateTimeToBackendDateOrDateTime(startDate),
        endDate: luxonDateTimeToBackendDateOrDateTime(endDate)
      }
    ];
  }

  function handleSiteChange(site: SiteOption) {
    setSelectedSite(site);
  }

  function siteAsOption(site: SiteBasic): SiteOption {
    return {
      value: site.id,
      label: site.name
    };
  }

  if (sites.length === 0) {
    return <div>Keine Liegenschaften verfügbar</div>;
  }

  function handleDocumentGeneration() {
    generateAndDownloadDocument(periods)
      .then(() => {
        showToast(
          "success",
          "Der Herkunftsnachweis für die ausgewählte Liegenschaft wurde erfolgreich erstellt."
        );
      })
      .catch((e) => {
        showToast("error", e);
      });
  }

  return (
    <div className="GuaranteeOfOriginGenerationView">
      <div className="controls">
        <UncontrolledDateRangePicker
          presets={DatePresetType.MonthYear}
          onChange={handleDateRangeChanged}
        />
        <Select
          isDisabled={!siteSelectionEnabled}
          options={sites.map(siteAsOption)}
          placeholder={
            siteSelectionEnabled
              ? "Wählen Sie eine Liegenschaft aus"
              : "Bitte zuerst einen Zeitraum wählen"
          }
          value={selectedSite}
          onChange={handleSiteChange}
        />
        <SpinButton
          color={buttonColors.brand}
          disabled={!documentGenerationIsEnabled}
          spin={isGenerationLoading}
          onClick={handleDocumentGeneration}
        >
          Dokument erstellen
        </SpinButton>
      </div>
      {periods.length > 0 && (
        <LoadOrError
          error={checkError}
          loading={isCheckLoading}
          loadingMessage="Einen Moment bitte, wir prüfen die verfügbaren Daten."
        >
          <GuaranteeOfOriginCheckResult result={result} />
        </LoadOrError>
      )}
    </div>
  );
}

export { GuaranteeOfOriginGenerationView };
