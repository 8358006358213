import { Combobox, Paper, useCombobox } from "@mantine/core";
import * as React from "react";
import { useEffect, useImperativeHandle } from "react";

interface SuggestionPopupProps {
  items: string[];
  command: (command: { id: string }) => void;
  posX: number;
  posY: number;
}

interface SuggestionPopupHandle {
  onKeyDown: (event: KeyboardEvent) => boolean;
}

function SuggestionPopup(
  { items, command, posX, posY }: SuggestionPopupProps,
  ref: React.Ref<SuggestionPopupHandle>
) {
  const combobox = useCombobox();

  useEffect(() => {
    combobox.updateSelectedOptionIndex();
  }, [combobox, items]);

  useImperativeHandle(ref, () => ({
    onKeyDown: (event: KeyboardEvent) => {
      if (event.key === "ArrowUp") {
        combobox.selectPreviousOption();
        return true;
      }

      if (event.key === "ArrowDown") {
        combobox.selectNextOption();
        return true;
      }

      if (event.key === "Enter") {
        combobox.clickSelectedOption();
        return true;
      }

      return false;
    }
  }));

  const handleSelection = (value: string) => {
    const index = parseInt(value);
    const item = items[index];

    if (item) {
      command({ id: item });
    }
  };

  return (
    <Combobox size="sm" store={combobox} onOptionSubmit={handleSelection}>
      <Combobox.EventsTarget>
        {/* Mantine requires a child node here, but we are controlling the combobox from outside via onKeyDown */}
        <div className="empty-target" />
      </Combobox.EventsTarget>

      <Paper
        p="sm"
        pos="absolute"
        shadow="sm"
        // if we'd use `left` directly, that would translate to Mantine spacing scale & mess up the positioning
        // interestingly, `top` does not have this issue
        style={{ left: posX, zIndex: 10000 }}
        top={posY}
        withBorder
      >
        <Combobox.Options>
          {items.length ? (
            items.map((item, index) => (
              <Combobox.Option key={index} value={index.toString()}>
                {item}
              </Combobox.Option>
            ))
          ) : (
            <Combobox.Empty>Keine Ergebnisse ...</Combobox.Empty>
          )}
        </Combobox.Options>
      </Paper>
    </Combobox>
  );
}

const ForwardedRefSuggestionPopup = React.forwardRef(SuggestionPopup);

export {
  ForwardedRefSuggestionPopup as SuggestionPopup,
  SuggestionPopupProps,
  SuggestionPopupHandle
};
