import classNames from "classnames";
import React from "react";
import type { RoundedNumberFormatProps } from "../../../../../utils/RoundedNumberFormat";
import { RoundedNumberFormat } from "../../../../../utils/RoundedNumberFormat";
import { InputWrapper } from "../../utils/InputWrapper";
import "../styles/form-control.scss";
import "./NumberInput.scss";

interface NumberInputProps
  extends Pick<
    RoundedNumberFormatProps,
    | "decimalScale"
    | "disabled"
    | "id"
    | "max"
    | "min"
    | "name"
    | "onBlur"
    | "onFocus"
    | "placeholder"
    | "step"
    | "value"
  > {
  inputGroupText?: string;
  invalid?: boolean;
  warned?: boolean;
  className?: string;
  extraInputClasses?: string;
  errorText?: string;
  onChange: (value: number | undefined | null) => void;
  onKeyPressEnter?: () => void;
}

function NumberInput(
  {
    inputGroupText,
    className,
    extraInputClasses,
    errorText,
    invalid,
    warned,
    onChange,
    onKeyPressEnter,
    ...otherProps
  }: NumberInputProps,
  ref?: React.Ref<HTMLInputElement>
) {
  function handleKeyUp(e: React.KeyboardEvent<HTMLInputElement>) {
    if (e.key === "Enter" && onKeyPressEnter) {
      onKeyPressEnter();
    }
  }

  const inputClassName = classNames(
    "form-control",
    "m-input",
    extraInputClasses,
    {
      "is-invalid": invalid,
      warning: !invalid && warned
    }
  );

  return (
    <InputWrapper
      className={classNames("NumberInput", className)}
      errorText={errorText}
      inputGroupText={inputGroupText}
    >
      <RoundedNumberFormat
        {...otherProps}
        className={inputClassName}
        displayType="input"
        getInputRef={ref}
        onKeyUp={handleKeyUp}
        onValueChange={({ floatValue }) => onChange(floatValue)}
      />
    </InputWrapper>
  );
}

const ForwardedRefNumberInput = React.forwardRef(NumberInput);

export { ForwardedRefNumberInput as NumberInput, NumberInputProps };
