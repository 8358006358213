import React, { useCallback, useEffect, useState } from "react";
import type { Column } from "react-table";
import type { DateRangeConsumptionShare } from "../../../utils/backend-types";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { DateInput } from "../../BuildingBlocks/Dates/DateInput/DateInput";
import { DeleteIcon } from "../../Buttons/DeleteIcon";
import { EditIcon } from "../../Buttons/EditIcon";
import { SaveIcon } from "../../Buttons/SaveIcon";
import { NumberValueCell } from "../../ChangeableFieldWidget/ChangeableFieldTable/Cells/NumberValueCell";
import { CustomReactTable } from "../../CustomReactTable/CustomReactTable";

interface ConsumptionShareTableProps {
  initialData: Array<DateRangeConsumptionShare>;
  onChangeConsumptionShareData: (
    index: number,
    consumerId?: number | null,
    percentile?: string | null,
    firstDate?: string | null,
    lastDate?: string | null
  ) => void;
  onClickDeleteRow(index: number): void;
  consumers: Array<{ consumer: number; consumerName: string }>;
}

interface ConsumptionShareData {
  [key: number]: string;
  firstDate: string | null;
  lastDate: string | null;
}

function ConsumptionShareTable({
  initialData,
  onChangeConsumptionShareData,
  onClickDeleteRow,
  consumers
}: ConsumptionShareTableProps) {
  const [columns, setColumns] = useState<
    Column<ConsumptionShareData>[] | undefined
  >([]);
  const [consumptionShareData, setConsumptionShareData] = useState<
    Array<ConsumptionShareData>
  >([]);
  const [editActivatedRow, setEditActivatedRow] = useState<number | null>(null);

  const handleChangeUserInput = useCallback(
    (
      index: number,
      consumerId?: number | null,
      percentile?: string | null,
      firstDate?: string | null,
      lastDate?: string | null
    ) => {
      onChangeConsumptionShareData(
        index,
        consumerId ?? null,
        percentile ? percentile : null,
        firstDate ?? initialData[index].firstDate ?? null,
        lastDate ?? initialData[index].lastDate ?? null
      );
    },
    [initialData, onChangeConsumptionShareData]
  );

  useEffect(() => {
    const consumerObjects = consumers.map((consumer) => {
      return {
        consumerId: consumer.consumer,
        displayName: consumer.consumerName
      };
    });
    const initialColumns: Column<ConsumptionShareData>[] = [
      {
        Header: "Gültig von",
        minWidth: 150,
        accessor: "firstDate",
        style: { display: "flex", alignItems: "center" },
        Cell: (cellInfo) => {
          return (
            <ConsumptionShareTableDateCell
              date={cellInfo.value}
              isEditable={
                editActivatedRow === cellInfo.index && cellInfo.value !== null
              }
              key={cellInfo.index}
              onChangeDate={(value) => {
                handleChangeUserInput(cellInfo.index, null, null, value, null);
              }}
            />
          );
        }
      },
      {
        Header: "Gültig bis",
        minWidth: 150,
        accessor: "lastDate",
        style: { display: "flex", alignItems: "center" },
        Cell: (cellInfo) => {
          return (
            <ConsumptionShareTableDateCell
              date={cellInfo.value}
              isEditable={
                editActivatedRow === cellInfo.index && cellInfo.value !== null
              }
              key={cellInfo.index}
              onChangeDate={(value) => {
                handleChangeUserInput(cellInfo.index, null, null, null, value);
              }}
            />
          );
        }
      }
    ];
    consumerObjects.forEach((consumer) => {
      initialColumns.push({
        Header: consumer.displayName,
        accessor: consumer.consumerId.toString(),
        minWidth: 150,
        Cell: (cellInfo) => {
          return (
            <ConsumptionShareTableConsumerCell
              isEditable={editActivatedRow === cellInfo.index}
              key={consumer.consumerId.toString()}
              value={cellInfo.value}
              onChange={(value) =>
                handleChangeUserInput(
                  cellInfo.index,
                  consumer.consumerId,
                  value
                )
              }
            />
          );
        },
        style: { display: "flex", alignItems: "center" }
      });
    });
    initialColumns.push({
      Header: "",
      accessor: "actions",
      minWidth: 40,
      Cell: (cellInfo) => {
        return (
          <ConsumptionShareTableControlCell
            canDelete={consumptionShareData.length > 1}
            editModeActivated={editActivatedRow === cellInfo.index}
            onClickDeleteRow={() => onClickDeleteRow(cellInfo.index)}
            onClickEdit={() => {
              setEditActivatedRow((prevState) => {
                if (prevState === cellInfo.index) {
                  return null;
                }
                return cellInfo.index;
              });
            }}
          />
        );
      },
      style: { display: "flex", alignItems: "center" }
    });
    setColumns(initialColumns);
  }, [
    handleChangeUserInput,
    editActivatedRow,
    onClickDeleteRow,
    consumptionShareData.length,
    consumers
  ]);

  useEffect(() => {
    const newConsumptionShareData: Array<ConsumptionShareData> = [];
    if (initialData) {
      initialData.forEach((dateRangeShares) => {
        const consumptionShareDataObject: ConsumptionShareData = {
          firstDate: dateRangeShares.firstDate ?? null,
          lastDate: dateRangeShares.lastDate ?? null
        };
        dateRangeShares.consumerConsumptionShares.forEach((consumer) => {
          if (consumer.consumptionPercentile) {
            consumptionShareDataObject[consumer.consumer] =
              consumer.consumptionPercentile;
          }
        });

        newConsumptionShareData.push(consumptionShareDataObject);
      });
    }
    setConsumptionShareData(newConsumptionShareData);
  }, [initialData]);

  return (
    <div className="consumption-share-table-container">
      <h6 style={{ marginBottom: "30px" }}>Verbrauchsanteil-Übersicht</h6>
      <CustomReactTable
        columns={columns}
        data={consumptionShareData}
        minRows={0}
        showPageJump
        showPagination
        sortable={false}
      />
    </div>
  );
}

interface ConsumptionShareTableDateCellProps {
  date: string | null;
  onChangeDate: (value: string | null) => void;
  isEditable: boolean;
}

function ConsumptionShareTableDateCell({
  date,
  isEditable,
  onChangeDate
}: ConsumptionShareTableDateCellProps) {
  return (
    <div>
      {isEditable ? (
        <DateInput
          date={date ? backendDateOrDateTimeToLuxonDateTime(date) : undefined}
          id={""}
          onChange={(value) =>
            onChangeDate(
              value ? luxonDateTimeToBackendDateOrDateTime(value) : null
            )
          }
        />
      ) : (
        (date ?? "/")
      )}
    </div>
  );
}

interface ConsumptionShareTableControlCellProps {
  onClickEdit: () => void;
  onClickDeleteRow: () => void;
  editModeActivated: boolean;
  canDelete: boolean;
}

function ConsumptionShareTableControlCell({
  onClickEdit,
  onClickDeleteRow,
  editModeActivated,
  canDelete
}: ConsumptionShareTableControlCellProps) {
  return (
    <div>
      {editModeActivated ? (
        <SaveIcon tooltipText={"Speichern"} onClick={onClickEdit} />
      ) : (
        <EditIcon tooltipText={"Bearbeiten"} onClick={onClickEdit} />
      )}
      <DeleteIcon
        disabled={!canDelete}
        tooltipText={"Löschen"}
        onClick={onClickDeleteRow}
      />
    </div>
  );
}

interface ConsumptionShareTableConsumerCellProps {
  isEditable: boolean;
  onChange: (value: string) => void;
  value: string;
}

function ConsumptionShareTableConsumerCell({
  isEditable,
  onChange,
  value
}: ConsumptionShareTableConsumerCellProps) {
  return (
    <div>
      {isEditable ? (
        <NumberValueCell
          inputGroupText="%"
          value={value ? parseFloat(value) : 0}
          onChange={(val) => onChange(val.toString())}
        />
      ) : (
        <div>{value ?? "0"}%</div>
      )}
    </div>
  );
}

export { ConsumptionShareTable };
