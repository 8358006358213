import React, { useId, useState } from "react";
import { Collapse } from "reactstrap";
import { Icon } from "../Icon/Icon";
import { IconName } from "../Icon/types";
import "./Accordion.scss";

type AccordionProps = {
  children: React.ReactNode;
  header: string | React.ReactNode;
  initialIsOpen?: boolean;
};

function Accordion({ children, header, initialIsOpen }: AccordionProps) {
  const [isOpen, setIsOpen] = useState(initialIsOpen ?? false);
  const accordionId = useId();

  return (
    <div className="Accordion">
      <button
        aria-controls={accordionId}
        aria-expanded={isOpen}
        className="accordion-header"
        type="button"
        onClick={() => setIsOpen(!isOpen)}
      >
        <Icon
          className="accordion-icon"
          name={isOpen ? IconName.AngleDown : IconName.AngleRight}
        />
        <HeaderComponent header={header} />
      </button>
      <Collapse id={accordionId} isOpen={isOpen} role="region">
        {children}
      </Collapse>
    </div>
  );
}

function HeaderComponent({ header }: { header: string | React.ReactNode }) {
  if (typeof header === "string") {
    return <span className="accordion-header-text">{header}</span>;
  }

  return <>{header}</>;
}

export { Accordion, AccordionProps };
