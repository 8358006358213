import { Flex, Paper, Text } from "@mantine/core";
import React from "react";

interface DataGapsChartTooltipProps {
  dateString: string;
  dataGaps: number;
  label: string;
}

function DataGapsChartTooltip({
  dateString,
  dataGaps,
  label
}: DataGapsChartTooltipProps) {
  if (!dateString || !dataGaps) return null;
  return (
    <Paper px="sm" py="xs" radius="sm" shadow="sm" withBorder>
      <Text fw={700} fz="sm" mb="xs">
        {label}
      </Text>

      <Flex justify="space-between">
        <Text fw={500} fz="xs">
          Datum:
        </Text>{" "}
        <Text fw={500} fz="xs">
          {dateString}
        </Text>
      </Flex>

      <Flex justify="space-between">
        <Text fw={500} fz="xs" mr="sm">
          Anzahl Datenlücken:
        </Text>{" "}
        <Text fw={500} fz="xs">
          {dataGaps}/96
        </Text>
      </Flex>
    </Paper>
  );
}

export { DataGapsChartTooltip };
