import "@mantine/charts/styles.css";
import { MantineProvider } from "@mantine/core";
import "@mantine/core/styles.css";
import { DatesProvider } from "@mantine/dates";
import "@mantine/dates/styles.css";
import "@mantine/tiptap/styles.css";
import * as Sentry from "@sentry/browser";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import dayjs from "dayjs";
import "dayjs/locale/de";
import customParseFormat from "dayjs/plugin/customParseFormat";
import "mantine-react-table/styles.css";
import React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ToastContainer } from "react-toastify";
import { mantineTheme } from "../../mantine/mantineTheme";
import { ErrorFallback } from "../Layout/ErrorFallback";
import { Tooltip } from "../Tooltip/Tooltip";
import { Page } from "./Page";

const queryClient = new QueryClient();

dayjs.extend(customParseFormat);

const logError = (error: Error, info: { componentStack: string }) => {
  Sentry.captureException(error, { extra: info });
};

function App() {
  return (
    <MantineProvider theme={mantineTheme}>
      <DatesProvider settings={{ locale: "de" }}>
        <QueryClientProvider client={queryClient}>
          <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
            <Page />
          </ErrorBoundary>
          <ToastContainer />
          <Tooltip />
        </QueryClientProvider>
      </DatesProvider>
    </MantineProvider>
  );
}

export { App };
