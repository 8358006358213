import { Title } from "@mantine/core";
import React from "react";
import type { Control, FieldErrors } from "react-hook-form";
import { FormFieldController } from "../../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { Paragraph6ContractFormFields } from "../../../../../Paragraph6.types";
import { PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA } from "../Paragraph6ContractForm.constants";

interface FristenProps {
  control: Control<Paragraph6ContractFormFields>;
  errors: FieldErrors<Paragraph6ContractFormFields>;
}

function Fristen({ control, errors }: FristenProps) {
  return (
    <section>
      <Title mt="lg" order={6}>
        Vertragsbedingungen
      </Title>
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.signing_date}
        error={errors.signing_date}
      />
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.begin}
        error={errors.begin}
      />
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.minimum_term}
        error={errors.minimum_term}
      />
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.end}
        error={errors.end}
      />
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.billing_method_and_period}
        error={errors.billing_method_and_period}
      />
      <FormFieldController
        control={control}
        data={PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA.billing_period_start}
        error={errors.billing_period_start}
      />
    </section>
  );
}

export { Fristen };
