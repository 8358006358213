import classNames from "classnames";
import React from "react";
import { IconName } from "../BuildingBlocks/Icon/types";
import { ActionIcon } from "./ActionIcon";
import "./DeleteIcon.scss";

interface EraserIconProps {
  tooltipText?: string;
  disabled?: boolean;
  className?: string;
  onClick?: (e: React.MouseEvent<HTMLElement>) => void;
}

function EraserIcon({
  tooltipText,
  disabled,
  className,
  onClick
}: EraserIconProps) {
  return (
    <ActionIcon
      className={classNames(
        "DeleteIcon",
        "delete-icon",
        { disabled },
        className
      )}
      data-testid="icon-button-erase"
      iconName={IconName.Eraser}
      tooltipText={tooltipText}
      onClick={onClick}
    />
  );
}

export { EraserIcon, EraserIconProps };
