import classNames from "classnames";
import React, { useEffect, useRef } from "react";
import type {
  FieldErrors,
  FieldPath,
  FieldValues,
  PathValue,
  RegisterOptions,
  UseFormRegister,
  UseFormSetValue,
  UseFormUnregister,
  UseFormWatch
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import {
  DateRangePicker,
  type DateRangePickerProps
} from "../../../../Dates/DateRangePicker/DateRangePicker";
import { FormFieldInfoText } from "../../../FormField/FormFieldInfoText/FormFieldInfoText";
import { FormFieldLabel } from "../../../FormField/FormFieldLabel/FormFieldLabel";
import { HelperText } from "../../../FormField/HelperText/HelperText";
import { getErrorKeyFromFieldError } from "../../../FormField/HelperText/utils/getErrorKeyFromFieldError";
import { isFieldError } from "../../../FormField/HelperText/utils/isFieldError";
import "./DateRangeInput.scss";

interface DateRangeInputProps<
  T extends FieldValues,
  TName extends FieldPath<T>
> {
  registerOptions?: {
    [key in TName]: RegisterOptions<T, TName>;
  };
  startDateFieldName: TName;
  endDateFieldName: TName;
  register: UseFormRegister<T>;
  setValue: UseFormSetValue<T>;
  unregister: UseFormUnregister<T>;
  watch: UseFormWatch<T>;
  errors: FieldErrors<T>;
  label?: string;
  infoText?: string;
}

function DateRangeInput<T extends FieldValues, TName extends FieldPath<T>>({
  registerOptions,
  startDateFieldName,
  endDateFieldName,
  register,
  setValue,
  unregister,
  watch,
  errors,
  label = "Zeitraum",
  infoText
}: DateRangeInputProps<T, TName>) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    register(startDateFieldName, {
      required: registerOptions?.[startDateFieldName].required
    });
    register(endDateFieldName, {
      required: registerOptions?.[endDateFieldName].required
    });

    return () => {
      unregister(startDateFieldName);
      unregister(endDateFieldName);
    };
  }, [
    startDateFieldName,
    endDateFieldName,
    registerOptions,
    register,
    unregister
  ]);

  const startDateError = isFieldError(errors[startDateFieldName])
    ? errors[startDateFieldName]
    : undefined;
  const endDateError = isFieldError(errors[endDateFieldName])
    ? errors[endDateFieldName]
    : undefined;
  const dateRangeErrorKey = getErrorKeyFromFieldError(
    startDateError || endDateError
  );
  const dateRangeError = dateRangeErrorKey && t(dateRangeErrorKey);

  const required =
    registerOptions?.[startDateFieldName]?.required ||
    registerOptions?.[endDateFieldName]?.required;

  const startDateValue = watch(startDateFieldName);
  const endDateValue = watch(endDateFieldName);

  const handleDatePickerChange: DateRangePickerProps["onChange"] = function ({
    startDate,
    endDate
  }) {
    setValue(
      startDateFieldName,
      (startDate
        ? luxonDateTimeToBackendDateOrDateTime(startDate)
        : null) as PathValue<T, TName>
    );
    setValue(
      endDateFieldName,
      (endDate
        ? luxonDateTimeToBackendDateOrDateTime(endDate)
        : null) as PathValue<T, TName>
    );
  };

  return (
    <div
      className={classNames("DateRangeInput", {
        "is-invalid": dateRangeError
      })}
      ref={ref}
    >
      <FormFieldLabel
        formFieldId="due-date-start-date"
        label={label}
        required={required === true}
      />
      <DateRangePicker
        endDateValue={endDateValue}
        startDateValue={startDateValue}
        withinPortal={false}
        onChange={handleDatePickerChange}
        onChangeEndDateValue={(value) =>
          setValue(endDateFieldName, value as PathValue<T, TName>)
        }
        onChangeStartDateValue={(value) =>
          setValue(startDateFieldName, value as PathValue<T, TName>)
        }
      />
      <HelperText error={dateRangeError} />
      {infoText && <FormFieldInfoText infoText={infoText} />}
    </div>
  );
}

export { DateRangeInput, DateRangeInputProps };
