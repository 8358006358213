import { Title } from "@mantine/core";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useNavigate, useParams } from "react-router-dom";
import { ROUTES } from "../../routes";
import { SiteSetupProcessDataStep } from "../../types/api.types";
import { showToast } from "../../utils/toast";
import { setErrorsFromResponseData } from "../BuildingBlocks/Forms/utils/setErrorsFromResponseData";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { SiteSetupAssistantFormControls } from "./Forms/SiteSetupAssistantFormControls/SiteSetupAssistantFormControls";
import { useSiteSetupAssistant } from "./hooks/useSiteSetupAssistant";
import { useSiteSetupAssistantMutations } from "./hooks/useSiteSetupAssistantMutations";
import "./SiteSetupAssistant.scss";
import {
  LocationType,
  type SiteSetupProcessForForm
} from "./SiteSetupAssistant.types";
import { SiteSetupAssistantNavigation } from "./SiteSetupAssistantNavigation/SiteSetupAssistantNavigation";
import { formatBackendDataToFrontend } from "./utils/formatBackendDataToFrontend";
import { formatFrontendDataToBackend } from "./utils/formatFrontendDataToBackend";
import { getTitleFromStep } from "./utils/getTitleFromStep";

function SiteSetupAssistant() {
  const navigate = useNavigate();
  const { projectId, siteId, siteSetupProcessId } = useParams();
  const {
    data: siteSetupAssistantData,
    isLoading,
    error
  } = useSiteSetupAssistant(siteSetupProcessId);
  const [activeStep, setActiveStep] = useState<
    SiteSetupProcessDataStep | undefined
  >(undefined);
  const defaultStep = SiteSetupProcessDataStep.name_and_pv_plants;
  const [navigationBlocked, setNavigationBlocked] = useState<boolean>(false);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const { control, formState, watch, setError, getValues, setValue } =
    useForm<SiteSetupProcessForForm>({ mode: "onBlur" });

  useEffect(() => {
    // manual form initialization since the data is not available at the time of form creation
    const frontendData = formatBackendDataToFrontend(siteSetupAssistantData);
    if (frontendData) {
      Object.keys(frontendData).forEach((key) => {
        let newValue = frontendData[key];
        if (key === "pvPlants" && (!newValue || newValue.length === 0)) {
          newValue = [{ see_number: "" }];
        }
        if (key === "siteHasBatteryStorage" && !newValue) {
          newValue = false;
        }
        if (key === "isBatteryStorageMeasuredSeparately" && !newValue) {
          newValue = false;
        }

        setValue(key as keyof SiteSetupProcessForForm, newValue);
      });
      if (activeStep === undefined && frontendData.step) {
        setActiveStep(frontendData.step);
      }
    }
  }, [activeStep, siteSetupAssistantData, setValue]);

  const { updateMutation: updateSiteSetupAssistant } =
    useSiteSetupAssistantMutations(siteSetupProcessId);

  async function handleStep(stepTo: SiteSetupProcessDataStep) {
    await saveProgress();
    setActiveStep(stepTo);
  }

  async function handleSaveProgress() {
    await saveProgress();
    showToast("success", "Ihre Liegenschaftskonfiguration wurde gespeichert.");
    navigate(ROUTES.managerProjectList + projectId);
  }

  async function saveProgress() {
    setIsSubmitting(true);

    // remove unused market/metering locations since they might be invalid
    const locationType = getValues().connectionLocationType;
    if (locationType === LocationType.MarketLocation) {
      setValue("connectionMeteringLocation", null);
    } else if (locationType === LocationType.MeteringLocation) {
      setValue("connectionMarketLocationFeedin", null);
      setValue("connectionMarketLocationFeedout", null);
    } else if (locationType === LocationType.None) {
      setValue("connectionMeteringLocation", null);
      setValue("connectionMarketLocationFeedin", null);
      setValue("connectionMarketLocationFeedout", null);
    }

    try {
      await updateSiteSetupAssistant.mutateAsync(
        formatFrontendDataToBackend({
          ...getValues(),
          step: activeStep || defaultStep
        })
      );
    } catch (error) {
      setErrorsFromResponseData<SiteSetupProcessForForm>(
        error,
        watch(),
        setError,
        t("errors.UnknownError")
      );
    } finally {
      setIsSubmitting(false);
    }
  }

  return (
    <LoadOrError error={error} loading={isLoading}>
      {siteId && siteSetupAssistantData && (
        <Portlet
          bodyClassName="site-setup-assistant-portlet-body"
          className="SiteSetupAssistant"
          footer={
            <SiteSetupAssistantFormControls
              activeStep={activeStep || defaultStep}
              isSubmitting={isSubmitting}
              navigationBlocked={navigationBlocked || !formState.isValid}
              onCancel={() => navigate(ROUTES.managerProjectList + projectId)}
              onSaveProgress={handleSaveProgress}
              onStep={handleStep}
            />
          }
        >
          <Title
            className="SiteSetupAssistantTitle"
            fw="500"
            mb="lg"
            mt="sm"
            order={3}
            ta="center"
          >
            {getTitleFromStep(activeStep || defaultStep)}
          </Title>
          <form className="site-setup-form">
            <SiteSetupAssistantNavigation
              formControl={control}
              formErrors={formState.errors}
              step={activeStep || defaultStep}
              watch={watch}
              onBlockNavigation={setNavigationBlocked}
              onChangeStep={(step) => handleStep(step)}
              onSetFormValue={setValue}
            />
          </form>
        </Portlet>
      )}
    </LoadOrError>
  );
}

export { SiteSetupAssistant };
