import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { type Todo } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

const statusFilterData = [
  { value: "true", label: "Bereit" },
  { value: "false", label: "Keine" }
];

export function getDocumentProvidedColumn(): MRT_ColumnDef<Todo> {
  return {
    accessorKey: TodosListColumnKeys.DocumentProvided,
    header: "Dokumentenstatus",
    Header: () => (
      <>
        Dokumentenstatus
        {/* add when Bora and Paulina have written the text: <IconHelpText helpText="" /> */}
      </>
    ),
    enableGrouping: false,
    Cell: ({ row }) => (row.original.documentProvided ? "Bereit" : "Keine"),
    filterVariant: "multi-select",
    filterFn: (row, _, filterValue) => {
      if (filterValue.length === 0 || filterValue.length === 2) {
        return true;
      }
      if (filterValue.includes("true")) {
        return row.original.documentProvided;
      } else {
        return !row.original.documentProvided;
      }
    },
    filterTooltipValueFn: (value) =>
      statusFilterData.find((item) => item.value === value)?.label || "",
    mantineFilterMultiSelectProps: {
      data: statusFilterData
    }
  };
}
