import { type DateTime } from "luxon";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { luxonDateTimeToBackendDateOrDateTime } from "../../../../../utils/dates/luxonDateTimeToBackendDateOrDateTime";
import { type PPAFixedPriceComponent } from "../../../Direktvermarktung.types";

/**
 * Takes existing an array of PPAFixedPriceComponents and returns an updated array,
 * only if the date range has changed. Otherwise, returns null.
 * If there are no existing components, a new array of components will be returned.
 */
export function updatePPAFixedPriceComponentsOnDateRangeChange(
  existingPPAFixedPriceComponents: Array<PPAFixedPriceComponent> | undefined,
  firstSelectedDate: string,
  lastSelectedDate: string
): Array<Partial<PPAFixedPriceComponent>> | null {
  const firstDateTime = backendDateOrDateTimeToLuxonDateTime(firstSelectedDate);
  const lastDateTime = backendDateOrDateTimeToLuxonDateTime(lastSelectedDate);
  const validDateRange = Boolean(firstDateTime.isValid && lastDateTime.isValid);

  const shouldUpdate = shouldUpdatePPAFixedPriceComponents(
    validDateRange,
    existingPPAFixedPriceComponents,
    firstDateTime,
    lastDateTime
  );

  if (shouldUpdate) {
    const monthsBetween = lastDateTime.diff(firstDateTime, "months").months + 1;
    const monthsArray = Array.from({
      length: monthsBetween
    });

    const newComponents = monthsArray.map<Partial<PPAFixedPriceComponent>>(
      (_, index) =>
        createPpaFixedPriceComponent(
          firstDateTime,
          index,
          existingPPAFixedPriceComponents
        )
    );

    return newComponents;
  }

  return null;
}

/** Checks if the PPAFixedPriceComponents should be updated. */
function shouldUpdatePPAFixedPriceComponents(
  validDateRange: boolean,
  ppaFixedPriceComponents: Array<PPAFixedPriceComponent> | undefined,
  firstDateTime: DateTime,
  lastDateTime: DateTime
): boolean {
  if (!validDateRange) {
    return false;
  }

  if (ppaFixedPriceComponents && ppaFixedPriceComponents.length > 0) {
    const firstComponentDate = ppaFixedPriceComponents[0].start_date;
    const lastComponentDate =
      ppaFixedPriceComponents[ppaFixedPriceComponents.length - 1].end_date;
    const firstComponentDateTime =
      backendDateOrDateTimeToLuxonDateTime(firstComponentDate);
    const lastComponentDateTime =
      backendDateOrDateTimeToLuxonDateTime(lastComponentDate);

    if (
      !firstDateTime
        .startOf("month")
        .equals(firstComponentDateTime.startOf("month")) ||
      !lastDateTime.endOf("month").equals(lastComponentDateTime.endOf("month"))
    ) {
      // updates only if date range is different
      return true;
    }
  } else {
    // update always when there are no ppaFixedPriceComponents
    return true;
  }

  return false;
}

/** Creates a PPAFixedPriceComponent for a given month. Use existing revenue value, if possible. */
function createPpaFixedPriceComponent(
  firstDateTime: DateTime,
  monthOffset: number,
  existingPPAFixedPriceComponents: Array<PPAFixedPriceComponent> | undefined
): Partial<PPAFixedPriceComponent> {
  {
    const month = firstDateTime.plus({ months: monthOffset });
    const startDate = month.startOf("month");
    const endDate = month.endOf("month");
    const backendStartDate = luxonDateTimeToBackendDateOrDateTime(
      startDate,
      "ISO 8601"
    );
    const backendEndDate = luxonDateTimeToBackendDateOrDateTime(
      endDate,
      "ISO 8601"
    );
    const existingRevenue = existingPPAFixedPriceComponents?.find(
      (component) =>
        component.start_date === backendStartDate &&
        component.end_date === backendEndDate
    );

    return {
      start_date: backendStartDate,
      end_date: backendEndDate,
      revenue: existingRevenue?.revenue
    };
  }
}
