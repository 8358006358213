import { useQuery } from "@tanstack/react-query";
import type { UseQueryOptions } from "@tanstack/react-query";

import api from "../api";
import urls from "../urls";
import type { InvoiceResponse } from "../utils/backend-types";

export function useArchivedInvoices(
  variantId?: number | null,
  options?: Partial<UseQueryOptions<Array<InvoiceResponse> | undefined>>
) {
  const {
    data: archivedInvoices,
    isLoading,
    error
  } = useQuery({
    queryKey: ["archivedInvoices", { variantId }],
    queryFn: () => fetchArchivedInvoices(true, variantId),
    ...options
  });

  async function fetchArchivedInvoices(
    archived: boolean,
    variantId?: number | null
  ) {
    if (!variantId) {
      return undefined;
    }

    const invoicesUrl = urls.api.invoices(variantId, archived);
    const response = await api.get(invoicesUrl);

    return response.data;
  }

  return { archivedInvoices, isLoading, error };
}
