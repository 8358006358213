import { NumberInput, type NumberInputProps } from "@mantine/core";
import type { MRT_Cell, MRT_Row, MRT_RowData } from "mantine-react-table";
import React, { useCallback, useEffect, useState } from "react";

type ValueType = string | number;

interface MantineEditNumberInputProps<T extends MRT_RowData>
  extends NumberInputProps {
  alignRight?: boolean;
  cell: MRT_Cell<T, string | null>;
  columnId: string;
  errorText?: string | undefined;
  label?: string;
  overrideValue?: string;
  row: MRT_Row<T>;
}

/** Custom NumberInput Edit component for mantine-react-table.
 * Inspired by this workaround: https://github.com/KevinVandy/mantine-react-table/discussions/8#discussioncomment-8759454
 */
function MantineEditNumberInput<T extends MRT_RowData>({
  alignRight,
  cell,
  columnId,
  errorText,
  label,
  overrideValue,
  row,
  onChange,
  ...rest
}: MantineEditNumberInputProps<T>) {
  const [value, setValue] = useState<ValueType>(cell.getValue() ?? "");

  const saveInputValueToRowCache = useCallback(
    (newValue: string | null) => {
      //@ts-expect-error when using a generic type, the valuesCache is readonly
      row._valuesCache[columnId] = newValue;
    },
    [columnId, row]
  );

  useEffect(() => {
    saveInputValueToRowCache(value.toString());
  }, [value, saveInputValueToRowCache]);

  useEffect(() => {
    if (typeof overrideValue !== "undefined") {
      setValue(overrideValue);
    }
  }, [overrideValue, saveInputValueToRowCache]);

  function handleChange(newValue: ValueType) {
    onChange?.(newValue);
    setValue(newValue);
  }

  return (
    <NumberInput
      {...rest}
      aria-label={label}
      error={errorText}
      styles={{ input: { textAlign: alignRight ? "right" : "left" } }}
      value={value}
      onChange={handleChange}
    />
  );
}

export { MantineEditNumberInput };
