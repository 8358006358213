import type { Choice } from "../components/DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { FinancialDocumentType } from "../components/Paragraph6/Paragraph6.types";
import { Paragraph6EEGCreditResponseState } from "../types/api.types";
import {
  CancellationState,
  type LocationSection,
  LocationType,
  PaymentMethod
} from "./backend-types";
import { Frequency, PhysicalSize, Unit } from "./enums";

type ThemeVars = {
  brandColor: string;
  secondaryBrandColor: string;
  primaryColor: string;
  successColor: string;
  infoColor: string;
  dangerColor: string;
  warningColor: string;
  inputBorderColor: string;
  customGrey: string;
  customLightGrey: string;
  nodeEnergyBlack: string;
};

declare const THEME_VARS_MODULES: ThemeVars;

/** `THEME_VARS` is defined in webpack.config.js (ProvidePlugin), but must be manually defined
 * in Jest tests (package.json) and is not defined in backend tests.
 * We cast the type here for better DX in the frontend code. */
export const THEME_VARS =
  typeof THEME_VARS_MODULES !== "undefined"
    ? THEME_VARS_MODULES
    : ({} as ThemeVars);

export const FREQUENCY_OPTIONS = {
  [Frequency.TenMinutes]: "10 Minuten",
  [Frequency.QuarterHour]: "Viertelstunde",
  [Frequency.Hour]: "Stunde",
  [Frequency.Day]: "Tag",
  [Frequency.Month]: "Monat",
  [Frequency.Year]: "Jahr"
} as const;

export const FREQUENCY_CHOICES = {
  TEN_MINUTES: {
    value: Frequency.TenMinutes,
    displayName: FREQUENCY_OPTIONS[Frequency.TenMinutes]
  },
  QUARTER_HOUR: {
    value: Frequency.QuarterHour,
    displayName: FREQUENCY_OPTIONS[Frequency.QuarterHour]
  },
  HOUR: {
    value: Frequency.Hour,
    displayName: FREQUENCY_OPTIONS[Frequency.Hour]
  },
  DAY: { value: Frequency.Day, displayName: FREQUENCY_OPTIONS[Frequency.Day] },
  MONTH: {
    value: Frequency.Month,
    displayName: FREQUENCY_OPTIONS[Frequency.Month]
  },
  YEAR: {
    value: Frequency.Year,
    displayName: FREQUENCY_OPTIONS[Frequency.Year]
  }
} as const;

export const NETZBEZUG_FREQUENCIES: Record<string, Choice<string>> = {
  MONTH: { value: "month", displayName: "Monatlich" },
  YEAR: { value: "year", displayName: "Jährlich" }
} as const;

export const NETZBEZUG_FREQUENCY_CHOICES = Object.values(NETZBEZUG_FREQUENCIES);

export const UNITS = {
  [Unit.Watt]: {
    value: Unit.Watt,
    displayName: "W (Watt)",
    physicalSize: PhysicalSize.Power
  },
  [Unit.WattHour]: {
    value: Unit.WattHour,
    displayName: "Wh (Wattstunden)",
    physicalSize: PhysicalSize.Energy
  },
  [Unit.Kilowatt]: {
    value: Unit.Kilowatt,
    displayName: "kW (Kilowatt)",
    physicalSize: PhysicalSize.Power
  },
  [Unit.KilowattHour]: {
    value: Unit.KilowattHour,
    displayName: "kWh (Kilowattstunden)",
    physicalSize: PhysicalSize.Energy
  },
  [Unit.Megawatt]: {
    value: Unit.Megawatt,
    displayName: "MW (Megawatt)",
    physicalSize: PhysicalSize.Power
  },
  [Unit.MegawattHour]: {
    value: Unit.MegawattHour,
    displayName: "MWh (Megawattstunden)",
    physicalSize: PhysicalSize.Energy
  },
  [Unit.CubicMeter]: {
    value: Unit.CubicMeter,
    displayName: "m³ (Kubikmeter)",
    physicalSize: PhysicalSize.Volume
  }
};

export const FINANCIAL_DOCUMENT_TYPES = {
  [FinancialDocumentType.Credit]: "Gutschrift",
  [FinancialDocumentType.Cancellation]: "Stornierung",
  [FinancialDocumentType.PaymentInformation]: "Zahlungsinformationen"
} as const;

export const INVOICE_STATE_CHOICES: Array<
  Choice<Paragraph6EEGCreditResponseState>
> = [
  {
    displayName: "Versandfertig",
    value: Paragraph6EEGCreditResponseState.ready_to_send
  },
  {
    displayName: "Versendet",
    value: Paragraph6EEGCreditResponseState.sent
  },
  {
    displayName: "Bezahlt",
    value: Paragraph6EEGCreditResponseState.paid
  }
];

export const CANCELLATION_STATE_CHOICES: Array<Choice<CancellationState>> = [
  {
    displayName: "Versandfertig",
    value: CancellationState.ReadyToSend
  },
  {
    displayName: "Versendet",
    value: CancellationState.Sent
  },
  {
    displayName: "Erhalten",
    value: CancellationState.Received
  }
];

export const PAYMENT_METHOD_CHOICES: Array<Choice<PaymentMethod>> = [
  {
    value: PaymentMethod.BankTransfer,
    displayName: "Überweisung"
  },
  {
    value: PaymentMethod.DirectDebit,
    displayName: "Lastschrift"
  }
];

export const LOCATION_SECTIONS: Array<LocationSection> = [
  {
    title: "Interne Lieferungen",
    locationTypes: [LocationType.InternalDelivery],
    sourceLabel: "Erzeuger",
    targetLabel: "Verbraucher",
    key: "internal-deliveries"
  },
  {
    title: "Netzbezug",
    locationTypes: [
      LocationType.ResidualDelivery,
      LocationType.AccountedFeedOut
    ],
    sourceLabel: "Verbraucher",
    key: "accounted-feed-out-and-residual-delivery"
  },
  {
    title: "Netzeinspeisung",
    locationTypes: [LocationType.AccountedFeedIn],
    sourceLabel: "Erzeuger",
    key: "accounted-feed-in"
  }
];

export const EMPTY_FIELD = "--";
export const UNNAMED_OBJECT_NAME = "Nicht benannt";

let numSteps = 0;
export const CREATE_STEPS = {
  IMPORT_OR_CREATE: ++numSteps,
  CREATE_FORM: ++numSteps,
  LOADPROFILE_SELECT: ++numSteps,
  ANLAGE_TAX_FORM: ++numSteps,
  BUSINESS_RULES_FORM: ++numSteps,
  FINANCIAL_DATA_FORM: ++numSteps,
  DELIVERY_CONSTRAINTS: ++numSteps,
  CONFIRMATION_PAGE: ++numSteps,
  MASTR_IMPORT_CONFIRMATION_PAGE: ++numSteps,
  GAS_CONNECTION_DATA: ++numSteps
} as const;
export const EDIT_STEPS = {
  EDIT_FORM: ++numSteps,
  BUSINESS_RULES_FORM: ++numSteps,
  CONFIRMATION_PAGE: ++numSteps
} as const;
