import { Select, type SelectProps } from "@mantine/core";
import type { MRT_Cell, MRT_Row, MRT_RowData } from "mantine-react-table";
import React, { useEffect, useState } from "react";
import { LoadOrError } from "../../../../components/LoadOrError/LoadOrError";

type ValueType = string | null;

interface ControlledMantineEditSelectProps<T extends MRT_RowData>
  extends SelectProps {
  cell: MRT_Cell<T, ValueType>;
  columnId: string;
  errorText: string | undefined;
  isLoading?: boolean;
  label?: string;
  overrideValue?: string;
  row: MRT_Row<T>;
}

/** Custom controlled version of Mantine Select for mantine-react-table.
 * Inspired by this workaround: https://github.com/KevinVandy/mantine-react-table/discussions/8#discussioncomment-8759454
 */
function ControlledMantineEditSelect<T extends MRT_RowData>({
  cell,
  columnId,
  isLoading,
  errorText,
  label,
  overrideValue,
  row,
  ...rest
}: ControlledMantineEditSelectProps<T>) {
  const [value, setValue] = useState<ValueType>(cell.getValue() ?? null);

  useEffect(() => {
    //@ts-expect-error when using a generic type, the valuesCache is readonly
    row._valuesCache[columnId] = value;
  }, [value, columnId, row]);

  useEffect(() => {
    if (typeof overrideValue !== "undefined") {
      setValue(overrideValue);
    }
  }, [overrideValue]);

  function handleChange(newValue: ValueType) {
    setValue(newValue);
  }

  return (
    <LoadOrError loading={isLoading}>
      <Select
        {...rest}
        aria-label={label}
        error={errorText}
        value={value}
        onChange={handleChange}
      />
    </LoadOrError>
  );
}

export { ControlledMantineEditSelect };
