import { Flex, Tooltip } from "@mantine/core";
import { merge } from "lodash";
import { DateTime } from "luxon";
import { MantineReactTable, useMantineReactTable } from "mantine-react-table";
import React, { useState } from "react";
import { getDefaultMRTOptions } from "../../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTTableEditModeOptions } from "../../../../../mantine/getDefaultMRTTableEditModeOptions";
import { MantineEditNumberInput } from "../../../../../mantine/mrt/components/edit/MantineEditNumberInput";
import { submitOrCancelOnKeyPress } from "../../../../../mantine/mrt/utils/submitOrCancelOnKeyPress";
import { Accordion } from "../../../../BuildingBlocks/Accordion/Accordion";
import { FormFieldLabel } from "../../../../BuildingBlocks/Forms/FormField/FormFieldLabel/FormFieldLabel";
import { Button } from "../../../../Buttons/Button/Button";

export const PERIOD_FORMAT = "MMMM yyyy";
export const FIXED_PRICE_TABLE_ROW_LIMIT = 100;
const ALL_MONTHS_LABEL = "Alle Monate";

export interface DirektvermarktungContractFixedPriceTableData {
  period: string;
  revenue: number | undefined;
}

interface FixedPriceTableProps {
  data: Array<DirektvermarktungContractFixedPriceTableData>;
  onAllRevenuesChange: (newRevenue: number) => void;
  onRevenueChange: (
    newRevenue: number | undefined,
    startDate: DateTime,
    endDate: DateTime
  ) => void;
}

function FixedPriceTable({
  data,
  onRevenueChange,
  onAllRevenuesChange
}: FixedPriceTableProps) {
  const [allRevenuesValue, setAllRevenuesValue] = useState<number | undefined>(
    undefined
  );
  const dataWithEditAll =
    data.length === 0
      ? data
      : [
          {
            period: ALL_MONTHS_LABEL,
            revenue: allRevenuesValue
          },
          ...data
        ];

  function handleValueChange(value: number | undefined, period: string) {
    const periodDateTime = DateTime.fromFormat(period, PERIOD_FORMAT);
    const revenueStartDate = periodDateTime.startOf("month");
    const revenueEndDate = periodDateTime.endOf("month");

    onRevenueChange(value, revenueStartDate, revenueEndDate);
  }

  function handleApplySetAllRevenues() {
    if (typeof allRevenuesValue === "undefined") {
      return;
    }
    onAllRevenuesChange(allRevenuesValue);
  }

  const columns = [
    {
      accessorKey: "period",
      header: "Monat",
      enableEditing: false
    },
    {
      accessorKey: "revenue",
      header: "Tatsächlicher Erlös in €/MWh",
      Edit: ({ cell, column, row }) => {
        if (row.original.period === ALL_MONTHS_LABEL) {
          return (
            <Flex align={"center"} gap={"xs"} w={"100%"}>
              <MantineEditNumberInput
                alignRight
                cell={cell}
                columnId={column.id}
                label="Tatsächlicher Erlös in €/MWh"
                placeholder="-"
                row={row}
                style={{ flex: 1 }}
                onChange={(value) => {
                  setAllRevenuesValue(value as number);
                }}
              />
              <Tooltip
                label="Alle Monate werden mit diesem Wert überschrieben"
                position="top"
                withArrow
              >
                <Button
                  disabled={!allRevenuesValue}
                  type="button"
                  onClick={handleApplySetAllRevenues}
                >
                  Für alle Monate übernehmen
                </Button>
              </Tooltip>
            </Flex>
          );
        } else {
          return (
            <MantineEditNumberInput
              alignRight
              cell={cell}
              columnId={column.id}
              label="Tatsächlicher Erlös in €/MWh"
              overrideValue={cell.getValue() as string}
              placeholder="-"
              row={row}
              onChange={(value) => {
                handleValueChange(value as number, row.original.period);
              }}
              onKeyDown={(event) => submitOrCancelOnKeyPress(event, table)}
            />
          );
        }
      }
    }
  ];

  const defaultOptions =
    getDefaultMRTOptions<DirektvermarktungContractFixedPriceTableData>({
      emptyRowsFallbackText: "Keine Monate im ausgewählten Zeitraum"
    });

  const tableEditModeOptions =
    getDefaultMRTTableEditModeOptions<DirektvermarktungContractFixedPriceTableData>();

  const tableOptions = merge({}, defaultOptions, tableEditModeOptions, {
    enableSorting: false,
    enablePagination: false,
    mantineTopToolbarProps: {
      hidden: true
    },
    mantineBottomToolbarProps: {
      hidden: true
    },
    mantineTableBodyRowProps: ({ row }) => {
      if (row.original.period === ALL_MONTHS_LABEL) {
        return {
          style: {
            backgroundColor:
              "color-mix(in srgb, var(--mantine-primary-color-0), #FFF 90%)"
          }
        };
      }
      return {};
    }
  });

  const table = useMantineReactTable({
    ...tableOptions,
    columns,
    data: dataWithEditAll
  });

  return (
    <Accordion
      header={
        <FormFieldLabel label="Tatsächliche Erlöse" renderAs="div" required />
      }
      initialIsOpen
    >
      <MantineReactTable table={table} />
      <hr />
    </Accordion>
  );
}

export { FixedPriceTable, FixedPriceTableProps };
