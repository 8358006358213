import { type MRT_ColumnDef } from "mantine-react-table";
import { isMrtEditing } from "../../../../../mantine/mrt/utils/isMrtEditing";
import type { ValidationErrors } from "../../../../../types/inlineEditTable.types";
import type {
  DirektvermarktungGeneratorShareResponse,
  DirektvermarktungUnassignedGeneratorsResponse
} from "../../../Direktvermarktung.types";

type UnassignedGeneratorColumnProps = {
  validationErrors: ValidationErrors;
  unassignedGenerators?: Array<DirektvermarktungUnassignedGeneratorsResponse>;
  onChange: (generator: DirektvermarktungUnassignedGeneratorsResponse) => void;
  enableEditing?: boolean;
};

export function getGeneratorColumn({
  validationErrors,
  unassignedGenerators,
  onChange
}: UnassignedGeneratorColumnProps): MRT_ColumnDef<DirektvermarktungGeneratorShareResponse> {
  const unassignedGeneratorsChoices =
    unassignedGenerators
      ?.map((generator) => generator.generator_display_name)
      .sort() ?? [];

  return {
    accessorKey: "generator_display_name",
    header: "Erzeuger",
    editVariant: "select",
    enableSorting: true,
    mantineEditSelectProps: ({ row, table }) => {
      const isEditingExistingEntry = isMrtEditing(table);

      return {
        "aria-label": "Erzeuger",
        data: isEditingExistingEntry
          ? [row.original.generator_display_name]
          : unassignedGeneratorsChoices,
        error: validationErrors.generatorId,
        required: true,
        disabled: isEditingExistingEntry,
        onChange: (generatorName) => {
          const selectedGenerator = unassignedGenerators?.find(
            (generator) => generator.generator_display_name === generatorName
          );

          if (selectedGenerator) {
            onChange(selectedGenerator);
          }
        }
      };
    }
  };
}
