import { BarChart } from "@mantine/charts";
import { Title } from "@mantine/core";
import * as React from "react";
import { seriesColors } from "../../../../mantine/charts/mantine.constants";
import { Portlet } from "../../../BuildingBlocks/Layout/Portlet";
import { type LoadProfileSeries } from "../../LoadProfileManager.types";

type LoadProfileBarPlotProps = {
  plotData: Array<LoadProfileSeries>;
  xAxisLabel: string;
  yAxisLabel: string;
};

function LoadProfileBarPlot({
  plotData,
  xAxisLabel,
  yAxisLabel
}: LoadProfileBarPlotProps) {
  const mantineSeries = plotData.map((series, seriesIndex) => ({
    name: plotData.length > 1 ? series.name : yAxisLabel,
    color: seriesColors[seriesIndex % seriesColors.length]
  }));

  const mantineData = plotData[0].x.map((xValue) => ({
    x: xValue,
    ...mantineSeries.reduce(
      (result, series, seriesIndex) => ({
        ...result,
        [series.name]:
          plotData[seriesIndex].y[
            plotData[seriesIndex].x.findIndex((value) => value === xValue)
          ]
      }),
      {}
    )
  }));

  return (
    <Portlet>
      <Title mb="lg" order={5}>
        {yAxisLabel}
      </Title>
      <BarChart
        barChartProps={{
          style: { backgroundColor: "#fff", padding: 12 }
        }}
        data={mantineData}
        dataKey="x"
        h={260}
        series={mantineSeries}
        valueFormatter={(yValue) => yValue.toFixed(3)}
        withLegend={plotData.length > 1}
        xAxisLabel={xAxisLabel}
      />
    </Portlet>
  );
}

export { LoadProfileBarPlot };
