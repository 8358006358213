import {
  LocationType,
  type SiteSetupProcessForForm,
  type SiteSetupProcessRaw
} from "../SiteSetupAssistant.types";

export function formatBackendDataToFrontend(
  rawData?: SiteSetupProcessRaw
): SiteSetupProcessForForm | null {
  if (!rawData) {
    return null;
  }

  return {
    step: rawData.step,

    /* site */
    name: rawData.site?.name ?? "",
    pvPlants: rawData.site?.pv_plants,
    siteAddress: rawData.site?.address?.address,
    siteZip: rawData.site?.address?.zip,
    siteCity: rawData.site?.address?.city,
    siteSupplyType: rawData.site?.supply_type,
    connectionVoltageLevel: rawData.site?.connection_voltage_level,
    gridOperator: rawData.site?.grid_operator,

    /* connection meter (in site) */
    connectionName: rawData.site?.connection_meter?.name,
    connectionMeasurementType: rawData.site?.connection_meter?.measurement_type,
    connectionMeteringDirection:
      rawData.site?.connection_meter?.metering_direction,
    connectionMeteringServiceProvider:
      rawData.site?.connection_meter?.metering_service_provider,
    // frontend-only parameter
    connectionLocationType: rawData.site?.connection_meter?.metering_location
      ? LocationType.MeteringLocation
      : rawData.site?.connection_meter?.market_location_feedin ||
          rawData.site?.connection_meter?.market_location_feedout
        ? LocationType.MarketLocation
        : LocationType.None,
    connectionMeteringLocation:
      rawData.site?.connection_meter?.metering_location,
    connectionMarketLocationFeedin:
      rawData.site?.connection_meter?.market_location_feedin,
    connectionMarketLocationFeedout:
      rawData.site?.connection_meter?.market_location_feedout,

    defaultPvPlantsMeasurementType: rawData.default_pv_plants_measurement_type,
    connectionMeterEnergyDataOrigin:
      rawData.connection_meter_energy_data_origin,
    voltageLevelDeterminedAutomatically:
      rawData.voltage_level_determined_automatically,
    consumerAmount: rawData.consumer_amount,
    defaultConsumerType: rawData.default_consumer_type,
    defaultConsumerMeasurementType: rawData.default_consumer_measurement_type,
    allTenantAddressesAreSameAsSiteAddress:
      rawData.all_tenant_addresses_are_same_as_site_address,
    allTenantsParticipatingInMieterstrom:
      rawData.all_tenants_participating_in_mieterstrom,
    siteHasBatteryStorage: rawData.site_has_battery_storage,
    isBatteryStorageMeasuredSeparately:
      rawData.is_battery_storage_measured_separately
  };
}
