import type { QueryClient } from "@tanstack/react-query";
import api from "../../../../../api";
import urls from "../../../../../urls";
import { MeasurementType, ObjectName } from "../../../../../utils/enums";
import { CounterDataView } from "../../../../CounterDataView/CounterDataView";
import { mapLabelToMeasurand } from "../../../../MeterWizard/mapLabelToMeasurand";
import type { GeneralDataField } from "./common";
import {
  buildHistoryData,
  loadData,
  mapDependenciesToSelectedFields,
  saveDataBasic
} from "./common";
import type { Form, FormWithMeteringData } from "./data-types";
import { buildFieldNameToLabelMapOldForms } from "./utils/buildFieldNameToLabelMapOldForms";

const METERING_MSB_HELP_TEXT =
  "Eine Mess- oder Marktlokation ist notwendig, um die Lastgänge beim zuständigen Messstellenbetreiber anfragen zu können. Stromzähler, welche für offizielle Abrechnungsprozesse genutzt werden, werden zumeist von einem Messstellenbetreiber (MSB) betrieben. Diese Stromzähler erhalten dann eine Mess- und/oder Marktlokation, damit sie in der Marktkommunikation (Versand von Messwerten bspw. an den Stromlieferanten) eindeutig identifizierbar sind. ";
const METERING_COMPANY_HELP_TEXT =
  "Insofern Sie den Zähler selbst betreiben, ist Ihren Zähler im Regelfall keine Mess- oder Marktlokation zugewiesen. Diese Zähler können über die Zählernummer (häufig die Seriennummer des Herstellers) eindeutig identifiziert werden.";

const METERING_MSB_FIELD_NAMES = [
  "meteringLocation",
  "marketLocationFeedin",
  "marketLocationFeedout"
];
const METERING_COMPANY_FIELD_NAMES = [
  "subMeteringSystem",
  "isCalibrated",
  "number"
];

const PREMIUM_SPECIFIC_FIELDS = [
  "measurementDescription",
  "installationLocation",
  "voltage",
  "yearlyConsumption",
  "tariffType",
  "meterBillingRelevantOrSubmeter",
  "aufhaengung",
  "measuringMethod",
  "absicherungInAmpere",
  "installationDate"
];

const HIDDEN_FIELDS_IF_NULL: Array<GeneralDataField> = [];

const VISIBLE_FIELDS: Array<GeneralDataField> = [
  { name: "globalId" },
  { name: "name" },
  { name: "medium" },
  { name: "measurementType" },
  { name: "frequency" },
  { name: "meteringDirection" },
  { name: "meteringLocation" },
  { name: "marketLocationFeedin" },
  { name: "marketLocationFeedout" },
  { name: "number" },
  { name: "subMeteringSystem" },
  { name: "isCalibrated" },
  { name: "calibrationValidUntil" },
  {
    name: "conversionFactor",
    dependencies: [
      {
        field: "measurementType",
        values: [MeasurementType.Arbitrary, MeasurementType.Cumulative]
      }
    ]
  },
  { name: "measurementDescription" },
  { name: "installationLocation" },
  { name: "voltage" },
  { name: "yearlyConsumption" },
  { name: "tariffType" },
  { name: "meterBillingRelevantOrSubmeter" },
  { name: "aufhaengung" },
  { name: "measuringMethod" },
  { name: "absicherungInAmpere" },
  { name: "installationDate" }
];

export const METER_EDIT_TABS = {
  TAB_GENERAL: "general",
  TAB_METERING_DATA: "messwerte",
  TAB_METERING_CONCEPT_DATA: "messkonzept",
  TAB_HISTORY: "historie"
};

export function loadMeterData(
  queryClient: QueryClient,
  meterId: number,
  siteId: number,
  variantId: number,
  isPremiumSite: boolean,
  isStaff: boolean
) {
  const visibleFieldNames = VISIBLE_FIELDS.map((field) => field.name);
  const hiddenIfNullFieldNames = HIDDEN_FIELDS_IF_NULL.map(
    (field) => field.name
  );
  return loadData({
    queryClient,
    objectName: ObjectName.Meter,
    objectId: meterId,
    siteId,
    variantId,
    visibleFields: visibleFieldNames,
    hiddenFieldsIfNull: hiddenIfNullFieldNames,
    isPremium: isPremiumSite
  }).then((forms: Array<Form>) => {
    const generalDataForm = { ...forms[0], formTitle: "Stammdaten" };

    const sectionWithAllFields = {
      ...generalDataForm.sections[0],
      fields: mapDependenciesToSelectedFields(
        generalDataForm.sections[0].fields,
        VISIBLE_FIELDS.concat(HIDDEN_FIELDS_IF_NULL)
      )
    };

    const meteringMSBFields = sectionWithAllFields.fields.filter((field) => {
      return METERING_MSB_FIELD_NAMES.includes(field.name);
    });

    const meteringCompanyFields = sectionWithAllFields.fields.filter(
      (field) => {
        return METERING_COMPANY_FIELD_NAMES.includes(field.name);
      }
    );
    const premiumSpecificFields = sectionWithAllFields.fields.filter(
      (field) => {
        return PREMIUM_SPECIFIC_FIELDS.includes(field.name);
      }
    );

    const generalFields = sectionWithAllFields.fields.filter((field) => {
      return !METERING_MSB_FIELD_NAMES.concat(
        METERING_COMPANY_FIELD_NAMES,
        PREMIUM_SPECIFIC_FIELDS
      ).includes(field.name);
    });

    const meteringMSBSection = {
      helpText: METERING_MSB_HELP_TEXT,
      header: "Identifikation des Zähler im Messstellenbetrieb",
      fields: meteringMSBFields,
      values: {
        meteringLocation: sectionWithAllFields.values.meteringLocation,
        marketLocationFeedin: sectionWithAllFields.values.marketLocationFeedin,
        marketLocationFeedout: sectionWithAllFields.values.marketLocationFeedout
      },
      errors: {}
    };
    const meteringCompanySection = {
      helpText: METERING_COMPANY_HELP_TEXT,
      header: "Identifikation des Zähler im Eigenbetrieb",
      fields: meteringCompanyFields,
      values: {
        subMeteringSystem: sectionWithAllFields.values.subMeteringSystem,
        isCalibrated: sectionWithAllFields.values.isCalibrated,
        number: sectionWithAllFields.values.number
      },
      errors: {}
    };
    const generalSection = {
      header: "Allgemeine Daten",
      fields: generalFields,
      values: {
        ...(isStaff ? { globalId: sectionWithAllFields.values.globalId } : {}),
        name: sectionWithAllFields.values.name,
        medium: sectionWithAllFields.values.medium,
        measurementType: sectionWithAllFields.values.measurementType,
        frequency: sectionWithAllFields.values.frequency,
        meteringDirection: sectionWithAllFields.values.meteringDirection,
        calibrationValidUntil:
          sectionWithAllFields.values.calibrationValidUntil,
        conversionFactor: sectionWithAllFields.values.conversionFactor
      },
      errors: {}
    };

    forms[0].sections = [
      generalSection,
      meteringMSBSection,
      meteringCompanySection
    ];
    if (isPremiumSite) {
      forms.push({
        name: METER_EDIT_TABS.TAB_METERING_CONCEPT_DATA,
        formTitle: "Messkonzept",
        extraProps: {
          meterId: meterId
        },
        sections: [
          {
            header: "(technische) Zähler Daten (Premium)",
            fields: premiumSpecificFields,
            values: {
              measurementDescription:
                sectionWithAllFields.values.measurementDescription,
              measuringMethod: sectionWithAllFields.values.measuringMethod,
              installationLocation:
                sectionWithAllFields.values.installationLocation,
              voltage: sectionWithAllFields.values.voltage,
              installationDate: sectionWithAllFields.values.installationDate,
              yearlyConsumption: sectionWithAllFields.values.yearlyConsumption,
              tariffType: sectionWithAllFields.values.tariffType,
              meterBillingRelevantOrSubmeter:
                sectionWithAllFields.values.meterBillingRelevantOrSubmeter,
              aufhaengung: sectionWithAllFields.values.aufhaengung,
              absicherungInAmpere:
                sectionWithAllFields.values.absicherungInAmpere
            },
            errors: {}
          }
        ],
        data: {}
      } as FormWithMeteringData);
    }
    forms.push({
      name: METER_EDIT_TABS.TAB_METERING_DATA,
      formTitle: "Messwerte",
      Component: CounterDataView,
      extraProps: {
        variantId: variantId,
        meterId: meterId,
        measurementType: forms[0].sections[0].values.measurementType
      },
      sections: [],
      data: {}
    } as FormWithMeteringData);

    const meterHistory = buildHistoryData(
      METER_EDIT_TABS.TAB_HISTORY,
      ObjectName.Meter,
      meterId,
      buildFieldNameToLabelMapOldForms(forms)
    );

    forms.push(meterHistory);
    return Promise.resolve(forms);
  });
}
export function savePremiumMeterData(meterId: number, forms: Array<Form>) {
  return saveMeterData(meterId, forms, undefined, true);
}

export function saveMeterData(
  meterId: number,
  forms: Array<Form>,
  _,
  isPremiumSite?: boolean
) {
  const filteredForms = [forms[0]];
  if (isPremiumSite) {
    filteredForms.push(forms[1]);
  }

  return saveDataBasic(
    ObjectName.Meter,
    meterId,
    filteredForms,
    isPremiumSite
  ).then((result) => {
    const finalResult = {
      ...result,
      forms: [...result.forms, ...forms.slice(isPremiumSite ? 2 : 1)]
    };

    const counterUpdateRequestData = (
      forms[isPremiumSite ? 2 : 1] as FormWithMeteringData
    ).data;

    const promises = Object.keys(counterUpdateRequestData).map(
      (counterLabel: string) => {
        return api.patch(urls.api.meterEnergyData(), {
          meter_id: meterId,
          measurand: mapLabelToMeasurand(counterLabel),
          energyData: counterUpdateRequestData[counterLabel]
        });
      }
    );

    return Promise.all(promises)
      .catch((error) => {
        finalResult.serverError = error;
        finalResult.didError = true;
      })
      .then(() => {
        return Promise.resolve(finalResult);
      });
  });
}
