import React, {
  useCallback,
  useEffect,
  useLayoutEffect,
  useState
} from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { Userpilot } from "userpilot";
import { useCurrentUserQuery } from "../../hooks/useCurrentUserQuery";
import { useSiteCategoriesSummary } from "../../hooks/useSiteCategoriesSummary";
import { PUBLIC_ROUTES } from "../../routes";
import { UserLicense } from "../../utils/enums";
import { ApiSettingsView } from "../ApiSettings/ApiSettingsView";
import { DirektvermarktungView } from "../Direktvermarktung/DirektvermarktungView";
import { withAnimatedLoadingIcon } from "../Icons/AnimatedLoadingIcon/AnimatedLoadingIcon";
import { LoadOrError } from "../LoadOrError/LoadOrError";
import { LoadProfileManager } from "../LoadProfileManager/LoadProfileManager";
import { Login } from "../Login/Login";
import { OtpConfirm } from "../Login/OtpConfirm";
import { ManagerDashboard } from "../ManagerDashboard/ManagerDashboard";
import { PasswordReset } from "../Password/PasswordReset";
import { PasswordResetConfirm } from "../Password/PasswordResetConfirm";
import { SetPasswordConfirm } from "../Password/SetPasswordConfirm";
import { ProductSelectPage } from "../ProductSelectPage/ProductSelectPage";
import { ProjectManager } from "../ProjectManager/ProjectManager";
import { RevenueMonitoringView } from "../RevenueMonitoring/RevenueMonitoringView";
import { Settings } from "../Settings/Settings";
import SimulationPage from "../SimulationPage/SimulationPage";
import { SiteSetupAssistant } from "../SiteSetupAssistant/SiteSetupAssistant";
import { StartPage } from "../StartPage/StartPage";
import { SupportPage } from "../Support/SupportPage";
import { InternalPage } from "./InternalPage";
import { ManagerStructureViewRoute } from "./ManagerStructureViewRoute";
import { ManagerVariantRoutes } from "./ManagerVariantRoutes";
import { OldManagerStructureViewRoute } from "./OldManagerStructureViewRoute";
import { ShowStaffViewContext } from "./ShowStaffViewContext";
import { loadHubSpotTracker } from "./utils/loadHubSpotTracker";
import { loadMouseflow } from "./utils/loadMouseflow";
import { loadUserpilot } from "./utils/loadUserpilot";
import { VariantRoutes } from "./VariantRoutes";

declare global {
  interface Window {
    _hsq?: {
      push: (command: [string, { [key: string]: string | number }]) => void;
    };
  }
}

const ProductSelectPageWithAnimatedLoadingIcon =
  withAnimatedLoadingIcon(ProductSelectPage);

function Page() {
  const location = useLocation();

  const [showStaffView, setShowStaffView] = useState<boolean>();
  const { currentUser } = useCurrentUserQuery();
  const { siteCategoriesSummary } = useSiteCategoriesSummary();

  const userLicenses: UserLicense[] | null = currentUser ? [] : null;

  if (currentUser?.has_analyzer_license) {
    userLicenses?.push(UserLicense.Analyzer);
  }

  if (currentUser?.has_manager_license) {
    userLicenses?.push(UserLicense.Manager);
  }

  const toggleShowStaffView = useCallback(
    () => setShowStaffView((oldShowStaffView) => !oldShowStaffView),
    []
  );

  useEffect(() => {
    if (
      typeof showStaffView === "undefined" &&
      typeof currentUser !== "undefined"
    ) {
      setShowStaffView(currentUser?.is_staff || false);
    }
  }, [currentUser, showStaffView]);

  useEffect(() => {
    sessionStorage.removeItem("meter_params_polling_data"); // clean all meter params polling loading data in case when user decides to reload the page
  }, []);

  useLayoutEffect(() => {
    if (currentUser?.tracking_enabled) {
      loadMouseflow();
    }

    if (siteCategoriesSummary) {
      if (currentUser?.hubspot_enabled) {
        loadHubSpotTracker(currentUser);
      }

      if (currentUser?.userpilot_enabled) {
        loadUserpilot(currentUser, siteCategoriesSummary);
      }
    }
  }, [currentUser, siteCategoriesSummary]);

  useEffect(() => {
    if (siteCategoriesSummary && currentUser?.userpilot_enabled) {
      Userpilot.reload();
    }
  }, [currentUser?.userpilot_enabled, location, siteCategoriesSummary]);

  return (
    <ShowStaffViewContext.Provider value={showStaffView}>
      <Routes>
        <Route element={<Login />} path={PUBLIC_ROUTES.login} />
        <Route element={<OtpConfirm />} path={PUBLIC_ROUTES.otpConfirm} />
        <Route element={<PasswordReset />} path={PUBLIC_ROUTES.passwordReset} />
        <Route
          element={<PasswordResetConfirm />}
          path={PUBLIC_ROUTES.passwordResetConfirm}
        />
        <Route
          element={<SetPasswordConfirm />}
          path={PUBLIC_ROUTES.setPasswordConfirm}
        />
        <Route element={<SupportPage />} path={PUBLIC_ROUTES.support} />
        <Route
          element={
            <InternalPage
              currentUser={currentUser}
              toggleShowStaffView={toggleShowStaffView}
            />
          }
          path="*"
        >
          <Route
            element={
              <ProductSelectPageWithAnimatedLoadingIcon
                requiredProps={["userLicenses"]}
                userLicenses={userLicenses}
              />
            }
            index
          />
          <Route path="analyzer">
            <Route path="projekte">
              <Route
                element={
                  <LoadOrError loading={userLicenses === null}>
                    {userLicenses && (
                      <ProjectManager
                        hasPermission={userLicenses.includes(
                          UserLicense.Analyzer
                        )}
                      />
                    )}
                  </LoadOrError>
                }
                index
              />
            </Route>
          </Route>
          <Route path="projekte/:projectId">
            <Route element={<VariantRoutes />} path="varianten/:variantId/*" />
            <Route
              element={<SimulationPage />}
              path="simulation/:simulationId"
            />
            <Route
              element={<LoadProfileManager />}
              path="lastprofil-manager/*"
            />
          </Route>
          <Route path="manager">
            <Route element={<Navigate to="../projekte" />} path="dashboard" />
            <Route element={<StartPage />} path="startseite/*" />
            <Route
              element={<DirektvermarktungView />}
              path="direktvermarktung/*"
            />
            <Route
              element={<RevenueMonitoringView />}
              path="erloesmonitoring/*"
            />
            <Route path="projekte">
              <Route element={<ManagerDashboard />} index />
              <Route
                element={<SiteSetupAssistant />}
                path=":projectId/liegenschaften/:siteId/liegenschaftskonfigurator/:siteSetupProcessId"
              />
              <Route
                element={<ManagerStructureViewRoute />}
                path=":projectId/liegenschaften/:siteId/*"
              />
              <Route element={<ManagerVariantRoutes />} path=":projectId/*" />
            </Route>
            <Route
              element={<OldManagerStructureViewRoute />}
              path="liegenschaften/:siteId/*"
            />
          </Route>
          <Route element={<ApiSettingsView />} path="api-einstellungen/*" />
          <Route element={<Settings />} path="einstellungen" />
        </Route>
      </Routes>
    </ShowStaffViewContext.Provider>
  );
}

export { Page };
