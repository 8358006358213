import { Group, Stack, Text } from "@mantine/core";
import { t } from "i18next";
import React, { useEffect, useState } from "react";
import { apiWithoutCamelization } from "../../../../api";
import urls from "../../../../urls";
import { FormField } from "../../../BuildingBlocks/Forms/FormField/FormField";
import { Icon } from "../../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../../BuildingBlocks/Icon/types";
import { FormItemInfoText } from "../../../DynamicForm/FormItem/FormItemInfoText";
import type { PVPlant } from "../../SiteSetupAssistant.types";
import "./PvPlantsFields.scss";

interface SeeNumberError {
  index: number;
  error: string;
}

interface PvPlantsFieldsProps {
  onChangeNavigationBlock: (blocked: boolean) => void;
  onChange: (data: Array<PVPlant | null>) => void;
  pvPlants?: Array<PVPlant | null> | null;
}

function PvPlantsFields({
  onChange,
  onChangeNavigationBlock,
  pvPlants = []
}: PvPlantsFieldsProps) {
  const [seeNumberErrors, setSeeNumberErrors] = useState<Array<SeeNumberError>>(
    []
  );

  function changeOrAddTextToError(errorText: string, index: number) {
    const errorIndex = seeNumberErrors.findIndex(
      (error) => error.index === index
    );
    if (errorIndex === -1) {
      setSeeNumberErrors([...seeNumberErrors, { index, error: errorText }]);
    } else {
      const newErrors = [...seeNumberErrors];
      newErrors[errorIndex] = { index, error: errorText };
      setSeeNumberErrors(newErrors);
    }
  }

  async function validateSeeNumberDeep(value: string) {
    let errorText: string | null = null;
    try {
      await apiWithoutCamelization.post(
        urls.apiWithoutCamelization.validateSEENumber(),
        { see_number: value }
      );
    } catch (error) {
      errorText = t(`errors.SiteSetupAssistant.${error.response.data[0]}`);
    }

    return errorText;
  }

  async function validateSeeNumberLight(
    value: string | undefined,
    index: number
  ) {
    if (value === "" || typeof value === "undefined" || value === null) {
      const errorIndex = seeNumberErrors.findIndex(
        (error) => error.index === index
      );
      if (errorIndex !== -1) {
        const newErrors = [...seeNumberErrors];
        newErrors.splice(errorIndex, 1);
        setSeeNumberErrors(newErrors);
      }
    } else if (
      pvPlants?.find(
        (pv, pvIndex) => pvIndex !== index && pv?.see_number === value
      )
    ) {
      changeOrAddTextToError("Diese Nummer wurde bereits hinzugefügt.", index);
    } else {
      const seeNumberRegex = /^(SEE|EEG)\d{12}$/;
      if (!seeNumberRegex.test(value)) {
        changeOrAddTextToError(
          'Leider konnten wir Ihre Registernummer nicht identifizieren. Bitte stellen Sie sicher, \
          dass Ihre Eingabe mit "SEE" oder "EEG" beginnt und anschließend 12 Ziffern enthält.',
          index
        );
      } else {
        const errorText = await validateSeeNumberDeep(value);
        if (errorText) {
          changeOrAddTextToError(errorText, index);
        } else {
          const errorIndex = seeNumberErrors.findIndex(
            (error) => error.index === index
          );
          if (errorIndex !== -1) {
            const newErrors = [...seeNumberErrors];
            newErrors.splice(errorIndex, 1);
            setSeeNumberErrors(newErrors);
          }
        }
      }
    }
  }

  function handleChangeSeeNumber(index: number, value: string) {
    if (pvPlants === null || pvPlants === undefined || pvPlants.length === 0) {
      onChange([{ see_number: value }]);
    } else {
      const updatedPvPlants = pvPlants.map((pvPlant, idx) => {
        if (idx === index) {
          return { see_number: value };
        }
        return pvPlant;
      });
      onChange(updatedPvPlants);
    }
  }

  useEffect(() => {
    if (seeNumberErrors && seeNumberErrors.length > 0) {
      onChangeNavigationBlock(true);
    } else {
      onChangeNavigationBlock(false);
    }
  }, [onChangeNavigationBlock, seeNumberErrors]);

  function handleDeletePvPlant(index: number) {
    if (pvPlants) {
      const updatedPvPlants = pvPlants.filter((pvPlant, idx) => idx !== index);
      onChange(updatedPvPlants);
      setSeeNumberErrors(
        seeNumberErrors.filter((error) => error.index !== index)
      );
    }
  }

  return (
    <Stack className="PvPlantsFields" gap="sm" mt="lg">
      <Text fw={500}>
        Markstammdatenregisternummer der PV-Anlage(n) (SEE-Nummer)
      </Text>
      <FormItemInfoText
        infoText="Jede PV-Anlage ist im Marktstammdatenregister angemeldet. 
      Opti.node kann sämtliche Stammdaten von dort beziehen. Hierfür benötigen wir die Registernummer, 
      um die Anlage zu finden.\
      [https://www.marktstammdatenregister.de/MaStR](https://www.marktstammdatenregister.de/MaStR) "
      />

      {pvPlants?.map((pvPlant, index) => (
        <Group align="flex-end" className="single-plant" gap="sm" key={index}>
          <FormField
            error={
              seeNumberErrors.find((error) => error.index === index)?.error
            }
            label="SEE-/EEG-Nummer"
            placeholder="z.Bsp. SEE111111118666"
            type="text"
            value={pvPlant?.see_number ?? undefined}
            onBlur={() =>
              validateSeeNumberLight(pvPlant?.see_number ?? "", index)
            }
            onChange={(event) =>
              handleChangeSeeNumber(index, event.target.value)
            }
          />
          <Icon
            className={
              seeNumberErrors.find((error) => error.index === index)
                ? "error"
                : ""
            }
            name={IconName.Trash}
            tooltipText="Anlage löschen"
            onClick={() => handleDeletePvPlant(index)}
          />
        </Group>
      ))}

      <div
        className="icon-link-container"
        onClick={() => {
          if (Array.isArray(pvPlants)) {
            const newPvPlants = [...pvPlants];
            newPvPlants.push(null);
            onChange(newPvPlants);
          }
        }}
      >
        <Icon className="icon-link-icon" name={IconName.PlusCircle} />
        <p className="icon-link-text">Anlage hinzufügen</p>
      </div>
    </Stack>
  );
}

export { PvPlantsFields };
