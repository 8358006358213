import type { DateTime } from "luxon";
import React, { useEffect, useState } from "react";
import {
  Controller,
  type FieldError,
  type SubmitHandler,
  useForm
} from "react-hook-form";
import { useTranslation } from "react-i18next";
import { TabContent, TabPane } from "reactstrap";
import type { Site } from "../../../../../../utils/backend-types";
import { AlertColor } from "../../../../../Alert/Alert";
import {
  DatePresetType,
  UncontrolledDateRangePicker
} from "../../../../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";
import { FormFieldController } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import { FormFieldInfoText } from "../../../../../BuildingBlocks/Forms/FormField/FormFieldInfoText/FormFieldInfoText";
import { HelperText } from "../../../../../BuildingBlocks/Forms/FormField/HelperText/HelperText";
import { IconAlert } from "../../../../../BuildingBlocks/IconAlert/IconAlert";
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader
} from "../../../../../BuildingBlocks/Layout/Modal/Modal";
import { ModalSubtitle } from "../../../../../BuildingBlocks/Layout/Modal/ModalSubtitle/ModalSubtitle";
import type { TabData } from "../../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { TabBar } from "../../../../../BuildingBlocks/Layout/TabBar/TabBar";
import { Button } from "../../../../../Buttons/Button/Button";
import { SpinButton } from "../../../../../Buttons/SpinButton/SpinButton";
import { CustomReactTable } from "../../../../../CustomReactTable/CustomReactTable";
import type { Choice } from "../../../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { LoadOrError } from "../../../../../LoadOrError/LoadOrError";
import type {
  ParkabrechnungContractFormOptionsComponent,
  ParkabrechnungContractFormOptionsSite
} from "../../../../Parkabrechnung.types";
import { ShareType } from "../../../../Parkabrechnung.types";
import {
  EINSPEISUNG_CONTRACT_FIELDS,
  GENERIC_CONTRACT_FIELDS,
  NETZBEZUG_CONTRACT_FIELDS
} from "../../ParkabrechnungContracts.constants";
import type { ParkabrechnungContractFormState } from "../ParkabrechnungContractModal.types";
import { setErrorsFromResponseData } from "../utils/setErrorsFromResponseData";
import "./ParkabrechnungContractModalUI.scss";

const FORM_ID = "AddContractModalForm";
const TABS: Array<TabData> = [
  {
    id: "netzbezug",
    label: "Netzbezug"
  },
  {
    id: "einspeisung",
    label: "Einspeisung"
  }
];

interface ParkabrechnungContractModalUIProps {
  defaultValues?: Partial<ParkabrechnungContractFormState>;
  isOpen: boolean;
  optionsSites?: Array<ParkabrechnungContractFormOptionsSite>;
  showStaffView: boolean;
  sites: Array<Site>;
  onClose: () => void;
  onSubmit: (
    data: ParkabrechnungContractFormState,
    selectedSite?: ParkabrechnungContractFormOptionsSite
  ) => Promise<void>;
}

function ParkabrechnungContractModalUI({
  defaultValues,
  showStaffView,
  isOpen,
  optionsSites,
  onClose,
  onSubmit
}: ParkabrechnungContractModalUIProps) {
  const {
    control,
    formState: { errors },
    handleSubmit,
    setError,
    setValue,
    watch
  } = useForm<ParkabrechnungContractFormState>({
    defaultValues: defaultValues ?? {
      feedinDistributionType: ShareType.InstalledCapacity
    }
  });
  const { t } = useTranslation();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [activeTab, setActiveTab] = useState(TABS[0].id);
  const siteId = watch("site");
  const componentIds = watch("components");
  const selectedSite = optionsSites?.find((site) => site.id === siteId);
  const componentsBySelectedSite = selectedSite?.components;
  const connectingParty = selectedSite?.connectingParty;
  const isLoading = typeof optionsSites === "undefined";

  useEffect(() => {
    if (connectingParty) {
      setValue("gridConnectionSupplyHolder", connectingParty);
    }
  }, [connectingParty, setValue]);

  function handleChangeDateRange(change: {
    startDate: DateTime | null;
    endDate: DateTime | null;
  }) {
    setValue("startDate", change.startDate);
    setValue("endDate", change.endDate);
  }

  const onValidSubmit: SubmitHandler<ParkabrechnungContractFormState> = async (
    data
  ) => {
    setIsSubmitting(true);

    try {
      const response = await onSubmit(
        data,
        optionsSites?.find((site) => site.id === data.site)
      );
      return response;
    } catch (error) {
      setErrorsFromResponseData<ParkabrechnungContractFormState>(
        error,
        watch(),
        setError,
        t("errors.UnknownError"),
        t("errors.Parkabrechnung.UnknownContractError")
      );
    } finally {
      setIsSubmitting(false);
    }
  };

  const submitCallback = handleSubmit(onValidSubmit);

  const componentChoices =
    componentsBySelectedSite?.map<Choice<number>>((component) => ({
      value: component.id,
      displayName: `${component.name} (${component.person})`
    })) ?? [];
  const meteringLocationChoices =
    selectedSite?.meteringLocations.map<Choice<number>>((meteringLocation) => ({
      value: meteringLocation.id,
      displayName: meteringLocation.name
    })) ?? [];
  const siteChoices =
    optionsSites?.map<Choice<number>>((site) => ({
      value: site.id,
      displayName: site.name
    })) ?? [];

  const selectedComponents =
    componentIds
      ?.map((c) => {
        const selectedComponent = componentsBySelectedSite?.find(
          (component) => component.id === c
        );

        if (
          typeof selectedComponent?.id === "undefined" ||
          typeof selectedComponent?.person === "undefined" ||
          typeof selectedComponent?.name === "undefined"
        ) {
          return undefined;
        } else {
          return {
            id: selectedComponent.id,
            person: selectedComponent.person,
            name: selectedComponent.name
          };
        }
      })
      .filter(
        (c): c is ParkabrechnungContractFormOptionsComponent =>
          typeof c !== "undefined"
      ) ?? [];

  const selectedComponentPersons = [
    ...new Set(selectedComponents.map((component) => component.person))
  ];

  const BothFormsInfo = (
    <FormFieldInfoText infoText="Bitte füllen Sie die Angaben für eine der Optionen *Netzeinspeisung* oder *Einspeisung* aus, um den Vertrag erfolgreich anzulegen." />
  );

  return (
    <Modal className="ParkabrechnungContractModalUI" isOpen={isOpen} size="xl">
      <ModalHeader toggle={onClose}>
        Vertrag {defaultValues ? "bearbeiten" : "hinzufügen"}
      </ModalHeader>
      <ModalBody scrollable>
        <LoadOrError loading={isLoading}>
          <form id={FORM_ID}>
            <FormFieldController
              control={control}
              data={GENERIC_CONTRACT_FIELDS.name}
              error={errors.name}
            />
            <FormFieldController
              control={control}
              data={{ ...GENERIC_CONTRACT_FIELDS.site, choices: siteChoices }}
              error={errors.site}
            />
            <FormFieldController
              control={control}
              data={{
                ...GENERIC_CONTRACT_FIELDS.gridConnectionSupplyHolder,
                disabled: true
              }}
              error={errors.gridConnectionSupplyHolder}
            />
            <div className="anlagen">
              <ModalSubtitle>für die folgenden Anlagen</ModalSubtitle>
              <FormFieldController
                control={control}
                data={{
                  ...GENERIC_CONTRACT_FIELDS.components,
                  disabled: componentChoices?.length === 0,
                  choices: componentChoices
                }}
                error={errors.components as FieldError | undefined}
              />

              {selectedComponentPersons.length > 0 && (
                <IconAlert color={AlertColor.Info}>
                  <p>Vertraglich erfasste Betreiberunternehmen</p>
                  <ul>
                    {selectedComponentPersons.map((person) => (
                      <li key={person}>{person}</li>
                    ))}
                  </ul>
                </IconAlert>
              )}
            </div>
            <div className="ParkabrechnungContractModal-validity">
              <Controller
                control={control}
                name="startDate"
                render={() => (
                  <>
                    <p className="ParkabrechnungContractModal-label">
                      Gültigkeitszeitraum*
                    </p>
                    <UncontrolledDateRangePicker
                      initialEndDate={
                        defaultValues?.endDate
                          ? defaultValues?.endDate
                          : undefined
                      }
                      initialStartDate={
                        defaultValues?.startDate
                          ? defaultValues?.startDate
                          : undefined
                      }
                      presets={DatePresetType.MonthYear}
                      onChange={handleChangeDateRange}
                    />
                    <HelperText
                      error={
                        (errors.startDate || errors.endDate) as
                          | FieldError
                          | undefined
                      }
                    />
                  </>
                )}
                rules={{ required: true }}
              />
              <Controller
                control={control}
                name="endDate"
                render={() => {
                  return <span />;
                }}
                rules={{ required: true }}
              />
            </div>
            <TabBar
              activeTab={activeTab}
              routeless
              tabs={TABS}
              onActivateTab={setActiveTab}
            />
            <TabContent activeTab={activeTab}>
              <TabPane tabId={TABS[0].id}>
                <ModalSubtitle>Strombezugskosten berechnen</ModalSubtitle>
                {BothFormsInfo}
                <FormFieldController
                  control={control}
                  data={{
                    ...NETZBEZUG_CONTRACT_FIELDS.period,
                    disabled: siteId === undefined
                  }}
                  error={errors.period}
                />
                <FormFieldController
                  control={control}
                  data={{
                    ...NETZBEZUG_CONTRACT_FIELDS.meloMaloId,
                    disabled: siteId === undefined,
                    choices: meteringLocationChoices
                  }}
                  error={errors.meloMaloId}
                />
                <div>
                  <ModalSubtitle>Angaben zu Anlagen</ModalSubtitle>
                  <CustomReactTable
                    columns={[
                      { Header: "Anlage", accessor: "anlage" },
                      {
                        Header: "Aufteilungsfaktor",
                        Cell: (cellInfo) => {
                          return (
                            <FormFieldController
                              control={control}
                              data={{
                                type: "number",
                                name: `share-${cellInfo.original.id}`,
                                placeholder: "z.B. 0,5 (Wert zwischen 0 und 1)"
                              }}
                              error={errors?.[`share-${cellInfo.original.id}`]}
                              rules={{
                                validate: (value) => {
                                  if (
                                    value === undefined ||
                                    value === null ||
                                    value < 0 ||
                                    value > 1
                                  ) {
                                    return t(
                                      "errors.Parkabrechnung.ShareRangeError"
                                    );
                                  }

                                  return true;
                                }
                              }}
                            />
                          );
                        }
                      }
                    ]}
                    data={selectedComponents?.map((selectedGenerator) => ({
                      anlage: `${selectedGenerator.name} (${selectedGenerator.person})`,
                      id: selectedGenerator.id
                    }))}
                  />
                </div>
                <div>
                  <ModalSubtitle>
                    Informationen Strombezugsvertrag
                  </ModalSubtitle>
                  <FormFieldController
                    control={control}
                    data={{
                      ...NETZBEZUG_CONTRACT_FIELDS.powerFeedoutPrice,
                      disabled: siteId === undefined,
                      type: "number",
                      step: 0.01
                    }}
                    error={errors.powerFeedoutPrice}
                    rules={{
                      validate: (value) => {
                        if (value === null) {
                          return "Ungültiger Wert";
                        }

                        return true;
                      }
                    }}
                  />
                </div>
              </TabPane>
              <TabPane tabId={TABS[1].id}>
                <ModalSubtitle>Vermarktungserlöse berechnen</ModalSubtitle>
                {BothFormsInfo}
                <FormFieldController
                  control={control}
                  data={{
                    ...EINSPEISUNG_CONTRACT_FIELDS.feedinPeriod,
                    disabled: siteId === undefined
                  }}
                  error={errors.feedinPeriod}
                />
                <FormFieldController
                  control={control}
                  data={{
                    ...EINSPEISUNG_CONTRACT_FIELDS.feedinMeloMaloId,
                    disabled: siteId === undefined,
                    choices: meteringLocationChoices
                  }}
                  error={errors.feedinMeloMaloId}
                />
                <FormFieldController
                  control={control}
                  data={{
                    ...EINSPEISUNG_CONTRACT_FIELDS.feedinDistributionType,
                    disabled: !showStaffView
                  }}
                  error={errors.feedinDistributionType}
                />
              </TabPane>
            </TabContent>
          </form>
        </LoadOrError>
      </ModalBody>

      <ModalFooter>
        <Button onClick={onClose}>Abbrechen</Button>
        <SpinButton
          color="brand"
          form={FORM_ID}
          spin={isSubmitting}
          type="submit"
          onClick={(e) => {
            e.preventDefault();
            submitCallback(e);
          }}
        >
          Speichern
        </SpinButton>
      </ModalFooter>
    </Modal>
  );
}

export { ParkabrechnungContractModalUI, ParkabrechnungContractModalUIProps };
