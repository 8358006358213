import {
  Card,
  Group,
  NavLink,
  NumberFormatter,
  Popover,
  RingProgress,
  Text,
  Title
} from "@mantine/core";
import React from "react";
import "./EnergyDataQuality.scss";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../../routes";
import type { components } from "../../../types/api.types";
import { AlertColor } from "../../Alert/Alert";
import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { IconAlert } from "../../BuildingBlocks/IconAlert/IconAlert";

export type DataQualityMeloMaloListItem =
  components["schemas"]["DataQualityMeloMaloListItem"];

interface EnergyDataQualityProps {
  qualityPercent: number;
  totalVolume: number;
  missingDataSources?: Array<DataQualityMeloMaloListItem>;
}

function renderMissingDataSourceLink(src: DataQualityMeloMaloListItem) {
  const path =
    generatePath(ROUTES.energyData, { projectId: src.project_id }) +
    "/zaehlerdaten";

  const label = src.site_name + " — " + (src.meter_name ?? src.melo_malo_name);

  return (
    <NavLink
      component={Link}
      key={src.melo_malo_id}
      label={<Text size="xs">{label}</Text>}
      py="5"
      to={path}
    />
  );
}

function EnergyDataQuality({
  qualityPercent,
  totalVolume,
  missingDataSources
}: EnergyDataQualityProps) {
  const numberOfLinksBeforeDropdown = 3;
  const numberOfLinksInsideDropdown =
    missingDataSources &&
    missingDataSources.length > numberOfLinksBeforeDropdown
      ? missingDataSources.length - numberOfLinksBeforeDropdown
      : 0;
  const dataSourcesBeforeDropdown =
    missingDataSources?.slice(0, numberOfLinksBeforeDropdown) || [];
  const dataSourcesInsideDropdown =
    missingDataSources?.slice(numberOfLinksBeforeDropdown) || [];

  if (isNaN(qualityPercent)) {
    return (
      <IconAlert color={AlertColor.Warning}>
        Es sind keine Daten verfügbar.
      </IconAlert>
    );
  }

  return (
    <Card className="EnergyDataQuality" withBorder>
      <Group justify="space-between">
        <Title mb="xs" order={4} size="h5">
          Datenqualität
        </Title>
        <Icon name={IconName.WindGenerator} />
      </Group>
      <Group justify="space-between">
        <Group gap="sm">
          <RingProgress
            label={
              <>
                <Text size="xs" ta="center">
                  <span className="data-quality-percent">
                    {Math.floor(qualityPercent)}&nbsp;%
                  </span>
                  <br />
                  verfügbar
                </Text>
              </>
            }
            rootColor="#d7d7d7"
            sections={[
              {
                value: qualityPercent,
                color: "#9050f7"
              }
            ]}
            size={100}
            thickness={6}
          />
          <div>
            <Title fw="normal" order={6} size="xs">
              Menge kWh
            </Title>
            <Text size="lg">
              <NumberFormatter
                decimalScale={2}
                fixedDecimalScale
                value={totalVolume}
              />
            </Text>
          </div>
        </Group>
        <div className="data-quality-details">
          {dataSourcesBeforeDropdown.length > 0 ? (
            <>
              <Title fw="normal" mb="xs" order={6} size="xs">
                Fehlende Daten in
              </Title>

              {dataSourcesBeforeDropdown.map((src) =>
                renderMissingDataSourceLink(src)
              )}

              {dataSourcesInsideDropdown.length > 0 && (
                <Popover
                  arrowSize={10}
                  offset={0}
                  position="bottom"
                  width="target"
                  withArrow
                >
                  <Popover.Target>
                    <NavLink
                      component="button"
                      key="more-items"
                      label={
                        <Text size="xs">{`und ${numberOfLinksInsideDropdown} weitere`}</Text>
                      }
                      leftSection={<Icon name={IconName.AngleDown} />}
                    />
                  </Popover.Target>
                  <Popover.Dropdown className="data-quality-dropdown">
                    {dataSourcesInsideDropdown.map((src) =>
                      renderMissingDataSourceLink(src)
                    )}
                  </Popover.Dropdown>
                </Popover>
              )}
            </>
          ) : (
            <>
              <Icon
                className="data-quality-ok"
                name={IconName.RoundedCheckGreen}
              />
              Alle Daten vollständig
            </>
          )}
        </div>
      </Group>
    </Card>
  );
}

export { EnergyDataQuality, EnergyDataQualityProps };
