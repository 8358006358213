import { DateTime } from "luxon";
import React, { useState } from "react";
import ReactDOM from "react-dom";

import {
  DatePresetType,
  UncontrolledDateRangePicker
} from "../../BuildingBlocks/Dates/DateRangePicker/DateRangePicker";
import { Button } from "../../Buttons/Button/Button";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";

import "./HistoricalDataImport.scss";

export const IMPORT_RUNNING_TEXT =
  "Die historischen Messdaten werden nun automatisch von Ihrem Untermesssystem importiert. Dieser Prozess läuft im Hintergrund und kann einige Minuten dauern.";

function HistoricalDataImport({ onDatesChanged }) {
  function handleDatePickerChange({ startDate, endDate }) {
    // don't allow empty range
    if (startDate && endDate) {
      onDatesChanged(startDate, endDate);
    }
  }

  return (
    <div className="HistoricalDataImport">
      <p className="description">
        Für welchen Zeitraum sollen Werte abgefragt werden?
      </p>
      <UncontrolledDateRangePicker
        id="historical-data-import-range-date-picker"
        maxDate={DateTime.now().minus({ days: 1 })}
        presets={DatePresetType.MonthYear}
        onChange={handleDatePickerChange}
      />
    </div>
  );
}

function useHistoricalDataImportState() {
  const [startDate, setStartDate] = useState<string | null>(null);
  const [endDate, setEndDate] = useState<string | null>(null);

  function setDates(start: DateTime, end: DateTime) {
    setStartDate(start.toISO());
    setEndDate(end.toISO());
  }

  return [startDate, endDate, setDates];
}

function HistoricalDataImportWithStateAndControls({
  buttonContainer,
  onSubmit,
  onSkip
}) {
  const [loading, setLoading] = useState(false);
  const [startDate, endDate, setDates] = useHistoricalDataImportState();

  function handleSubmit() {
    setLoading(true);
    onSubmit(startDate, endDate).catch(() => {
      setLoading(false);
    });
  }

  function handleClickSkip() {
    onSkip();
  }

  const submitIsDisabled = !startDate || !endDate;

  return (
    <div>
      <HistoricalDataImport onDatesChanged={setDates} />
      {buttonContainer && (
        <>
          {ReactDOM.createPortal(
            <Controls
              loading={loading}
              submitIsDisabled={submitIsDisabled}
              onClickImport={handleSubmit}
              onClickSkip={handleClickSkip}
            />,
            buttonContainer
          )}
        </>
      )}
    </div>
  );
}

function Controls({ loading, submitIsDisabled, onClickSkip, onClickImport }) {
  return (
    <div className="historical-data-import-controls">
      <Button
        className="skip"
        color="brand"
        disabled={loading}
        onClick={onClickSkip}
      >
        Überspringen
      </Button>
      <SpinButton
        color="primary"
        disabled={submitIsDisabled}
        spin={loading}
        onClick={onClickImport}
      >
        Importieren
      </SpinButton>
    </div>
  );
}

export {
  HistoricalDataImport,
  HistoricalDataImportWithStateAndControls,
  useHistoricalDataImportState
};
