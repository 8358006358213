import { DateTime } from "luxon";
import type { MeasurandDataGaps } from "../../../../../../../../utils/backend-types";
import type { DataGapsTableObject } from "../DataGaps.types";

export function getTableObjectsFromDataGapEntry(
  dataGap: MeasurandDataGaps
): DataGapsTableObject[] | null {
  const gaps = dataGap.gaps;
  if (gaps.length === 0 || gaps[0].length === 0) {
    return null;
  }

  const groupedGaps: { [key: string]: Array<Array<string>> } = {};

  // Split gaps that span multiple days and group gaps by day
  for (const gap of gaps) {
    const startDateTime = DateTime.fromISO(gap[0], { zone: "Europe/Berlin" });
    const endDateTime = DateTime.fromISO(gap[1], { zone: "Europe/Berlin" });

    const gapStart = startDateTime.toISO();
    const gapEnd = endDateTime.toISO();

    const startDayString = startDateTime.toISODate();
    const endDayString = endDateTime.toISODate();
    if (startDayString && gapStart && gapEnd) {
      const accessKey = startDayString + "-" + endDayString;
      if (!groupedGaps[accessKey]) {
        groupedGaps[accessKey] = [];
      }
      groupedGaps[accessKey].push([gapStart, gapEnd]);
    }
  }

  const result: DataGapsTableObject[] = [];

  // Create DataGapsTableObject for each day
  for (const day in groupedGaps) {
    const dayGaps = groupedGaps[day];
    let earliestDate = dayGaps[0][0];
    let latestDate = dayGaps[0][1];

    for (const gap of dayGaps) {
      if (gap[0] < earliestDate) {
        earliestDate = gap[0];
      }
      if (gap[1] > latestDate) {
        latestDate = gap[1];
      }
    }

    const startDateTime = DateTime.fromISO(earliestDate, {
      zone: "Europe/Berlin"
    });
    const lastDateTime = DateTime.fromISO(latestDate, {
      zone: "Europe/Berlin"
    }).plus({ minutes: 15 });

    result.push({
      date: {
        startDate: startDateTime,
        lastDate: lastDateTime
      },
      measurand: dataGap.measurand,
      dataGaps: dayGaps
    });
  }

  return result;
}
