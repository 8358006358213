import { type MRT_ColumnDef } from "mantine-react-table";
import React from "react";
import { type Todo } from "../../../utils/backend-types";
import { TodosListColumnKeys } from "../TodoTable";

export function getFulfillForColumn(todos: Array<Todo>): MRT_ColumnDef<Todo> {
  const allFulfillFors = todos.map((todo) => todo.fulfillFor);
  const sortedNonNullFulfillFors = allFulfillFors
    .filter((fulfillFor) => fulfillFor !== null)
    .sort((fulfillForA, fulfillForB) =>
      (fulfillForA ?? "").localeCompare(fulfillForB ?? "")
    );
  const uniqueUsedFulfillFors = Array.from<string>(
    new Set(sortedNonNullFulfillFors)
  );
  const usedFulfillFors = Array.from(uniqueUsedFulfillFors);

  return {
    accessorKey: TodosListColumnKeys.FulfillFor,
    header: "Zu erfüllen für",
    Header: () => (
      <>
        Zu erfüllen für{" "}
        {/* add when Bora and Paulina have written the text: <IconHelpText helpText="" /> */}
      </>
    ),
    enableGrouping: false,
    Cell: ({ row }) => row.original.fulfillFor,
    filterVariant: "multi-select",
    filterFn: (row, _, filterValue) => {
      if (filterValue.length === 0) {
        return true;
      }
      return filterValue.includes(row.original.fulfillFor?.toString());
    },
    mantineFilterMultiSelectProps: {
      data: usedFulfillFors.map((fulfillFor) => ({
        value: fulfillFor,
        label: fulfillFor
      }))
    }
  };
}
