import * as Sentry from "@sentry/browser";
import { merge } from "lodash";
import {
  MantineReactTable,
  type MRT_ColumnDef,
  type MRT_Row,
  type MRT_SortingState,
  type MRT_TableOptions,
  useMantineReactTable
} from "mantine-react-table";
import React, { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import type { ApiResponse } from "../../../../api";
import { getDefaultMRTOptions } from "../../../../mantine/getDefaultMRTOptions";
import { getDefaultMRTRowEditModeOptions } from "../../../../mantine/getDefaultMRTRowEditModeOptions";
import { getMRTTopToolbarCustomActionsRenderer } from "../../../../mantine/getMRTTopToolbarCustomActionsRenderer";
import { convertApiErrorsToMrtErrors } from "../../../../mantine/mrt/utils/convertApiErrorsToMrtErrors";
import { THEME_VARS } from "../../../../utils/constants";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { getErrorText } from "../../../../utils/get-error-text";
import { showToast } from "../../../../utils/toast";
import { MultiConfirmationModal } from "../../../BuildingBlocks/Layout/Modals/MultiConfirmationModal/MultiConfirmationModal";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import { useParagraph6Companies } from "../../hooks/useParagraph6Companies";
import type { Paragraph6EEGCreditNumberRangeResponse } from "../../Paragraph6.types";
import type { Paragraph6CreditNumberRangeFormValues } from "../Paragraph6CreditNumberRangesPage.types";
import { getCounterStartValueColumn } from "./Columns/getCounterStartValueColumn";
import { getFormatColumn } from "./Columns/getFormatColumn";
import { getSupplierColumn } from "./Columns/getSupplierColumn";
import { getValidFromColumn } from "./Columns/getValidFromColumn";
import { validateParagraph6CreditNumberRangeFormValues } from "./utils/validateParagraph6CreditNumberRangeFormValues";

const DEFAULT_SORTED: MRT_SortingState = [
  {
    id: "created",
    desc: true
  }
];

interface CreditNumberRangesTableProps {
  creditNumberRanges: Array<Paragraph6EEGCreditNumberRangeResponse>;
  variantId: number;
  onDelete: (rangeId: string) => Promise<void>;
  onSubmit: (
    updatedRange: Paragraph6CreditNumberRangeFormValues,
    creditRangeId?: string
  ) => Promise<ApiResponse>;
}

function CreditNumberRangesTable({
  creditNumberRanges,
  variantId,
  onDelete,
  onSubmit
}: CreditNumberRangesTableProps) {
  const { t } = useTranslation();
  const { paragraph6Companies, isLoading, error } =
    useParagraph6Companies(variantId);
  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [validationErrors, setValidationErrors] = useState<
    Record<string, string | undefined>
  >({});
  const [rangesToBeDeleted, setRangesToBeDeleted] = useState<
    Array<Paragraph6EEGCreditNumberRangeResponse>
  >([]);

  const columns = useMemo<
    Array<MRT_ColumnDef<Paragraph6EEGCreditNumberRangeResponse>>
  >(
    () => [
      {
        accessorKey: "created",
        header: "Id",
        sortingFn: (rowA, rowB) =>
          sortBackendDates(rowA.original.created, rowB.original.created),
        visibleInShowHideMenu: false
      },
      getSupplierColumn(
        { validationErrors, setValidationErrors, t },
        paragraph6Companies
      ),
      getFormatColumn({
        validationErrors,
        setValidationErrors,
        t
      }),
      getCounterStartValueColumn({
        validationErrors,
        setValidationErrors,
        t
      }),
      getValidFromColumn({
        validationErrors,
        setValidationErrors,
        t
      })
    ],
    [paragraph6Companies, validationErrors, t]
  );

  const handleCreateRange: MRT_TableOptions<Paragraph6EEGCreditNumberRangeResponse>["onCreatingRowSave"] =
    async function ({ values, exitCreatingMode }) {
      await createOrEditRange(values, exitCreatingMode);
    };

  const handleSaveRange: MRT_TableOptions<Paragraph6EEGCreditNumberRangeResponse>["onEditingRowSave"] =
    async function ({ row, values, table }) {
      await createOrEditRange(
        values,
        () => table.setEditingRow(null) /* exit editing mode */,
        row.original.id
      );
    };

  async function createOrEditRange(
    values: MRT_Row<Paragraph6EEGCreditNumberRangeResponse>["_valuesCache"],
    exitCreatingOrEditingMode: () => void,
    rangeId?: string
  ) {
    const newValidationErrors = validateParagraph6CreditNumberRangeFormValues(
      values,
      t
    );

    if (Object.values(newValidationErrors).some((error) => error)) {
      setValidationErrors(newValidationErrors);
      return;
    }

    setIsSaving(true);
    setValidationErrors({});

    const supplier = paragraph6Companies?.find(
      (company) => company.name === values.supplier
    )?.id;

    if (!supplier) {
      const error = t(
        "errors.Paragraph6.CreditNumberRanges.missingSupplierError"
      );

      setValidationErrors({
        supplier: error
      });
      showToast("error", error);
      setIsSaving(false);

      Sentry.captureMessage(
        `${error} in CreditNumberRangesTable for supplier ${values.supplier}`
      );

      return;
    }

    try {
      await onSubmit({ ...values, supplier }, rangeId);
    } catch (error) {
      if (error.response.status === 400) {
        const validationErrors = convertApiErrorsToMrtErrors(
          error.response.data
        );

        if (Object.keys(validationErrors).length > 0) {
          setValidationErrors(validationErrors);
        } else {
          showToast("error", error);
        }

        setIsSaving(false);
        return;
      }
    }

    exitCreatingOrEditingMode();
    setIsSaving(false);
  }

  const defaultOptions =
    getDefaultMRTOptions<Paragraph6EEGCreditNumberRangeResponse>({
      emptyRowsFallbackText:
        "Es gibt noch keine personalisierten Gutschriftennummernkreise.",
      searchTextInput: {
        placeholder: "Suche nach Gutschriftennummernkreisen"
      }
    });

  const rowEditModeOptions =
    getDefaultMRTRowEditModeOptions<Paragraph6EEGCreditNumberRangeResponse>({
      rowActions: {
        edit: {
          text: "Gutschriftennummernkreis bearbeiten"
        },
        delete: {
          text: "Gutschriftennummernkreis löschen",
          enableDelete: (cell) => !cell.row.original.is_in_use,
          onClick: (ranges) => setRangesToBeDeleted(ranges)
        }
      }
    });

  const tableOptions = merge({}, defaultOptions, rowEditModeOptions, {
    ...getMRTTopToolbarCustomActionsRenderer<Paragraph6EEGCreditNumberRangeResponse>(
      {
        create: {
          text: "Gutschriftennummernkreis hinzufügen"
        },
        delete: {
          onClick: (rowSelection) =>
            setRangesToBeDeleted(
              creditNumberRanges.filter((range) => rowSelection[range.id])
            )
        }
      }
    ),
    displayColumnDefOptions: {
      "mrt-row-select": {
        visibleInShowHideMenu: false
      },
      "mrt-row-actions": {
        visibleInShowHideMenu: false
      }
    },
    enableRowSelection: (row) => !row.original.is_in_use,
    initialState: {
      columnVisibility: {
        created: false
      },
      showGlobalFilter: true,
      sorting: DEFAULT_SORTED
    },
    mantineToolbarAlertBannerProps: error
      ? {
          color: THEME_VARS.dangerColor,
          children: getErrorText(error)
        }
      : undefined,
    state: {
      isLoading: isLoading,
      isSaving: isSaving || isDeleting,
      showAlertBanner: !!error
    },
    onCreatingRowCancel: () => setValidationErrors({}),
    onCreatingRowSave: handleCreateRange,
    onEditingRowCancel: () => setValidationErrors({}),
    onEditingRowSave: handleSaveRange
  });

  const table = useMantineReactTable({
    ...tableOptions,
    columns,
    data: creditNumberRanges
  });

  const namesOfRangesToBeDeleted = rangesToBeDeleted.map((range) => {
    const supplierName = paragraph6Companies?.find(
      (company) => company.id === range.supplier
    )?.name;

    return range.format
      ? `${supplierName ? supplierName + ": " : ""}${range.format}`
      : "Unbekannt";
  });
  const denOrDie = rangesToBeDeleted.length === 1 ? "den" : "die";
  const singleOrPluralText =
    rangesToBeDeleted.length === 1
      ? "Gutschriftennummernkreis"
      : "Gutschriftennummernkreise";

  return (
    <LoadOrError
      error={error}
      loading={isLoading}
      loadingMessage="Unternehmensdaten werden geladen ..."
    >
      <div className="CreditNumberRangesTable">
        <MantineReactTable table={table} />
        <MultiConfirmationModal
          actionName="löschen"
          actionObjects={namesOfRangesToBeDeleted}
          confirmationText={`Möchten Sie ${denOrDie} folgenden ${singleOrPluralText} löschen? Dieser Schritt kann nicht rückgängig gemacht werden.`}
          isModalOpen={rangesToBeDeleted.length > 0}
          objectName={singleOrPluralText}
          toggleModal={() => setRangesToBeDeleted([])}
          onAction={async () => {
            setIsDeleting(true);

            await Promise.all(
              rangesToBeDeleted.map((range) => onDelete(range.id))
            );

            setRangesToBeDeleted([]);
            table.setRowSelection({}); // MRT doesn't auto-clear the selected rows after deletion
            setIsDeleting(false);
          }}
        />
      </div>
    </LoadOrError>
  );
}

export { CreditNumberRangesTable, CreditNumberRangesTableProps };
