import _ from "lodash";
import { type Data } from "plotly.js";
import React from "react";
import Plot from "react-plotly.js";
import { useFeatureFlags } from "../../../../hooks/useFeatureFlags";
import { UNITS } from "../../../../utils/constants";
import type { Unit } from "../../../../utils/enums";
import type { CounterData } from "../../../DataSheet/DataSheet";
import { AcquisitionBarPlot } from "./AcquisitionBarPlot";
import { AcquisitionLinePlot } from "./AcquisitionLinePlot";

interface AcquisitionPlotProps {
  acquisitionSerieses: CounterData;
  selectedUnitValue: Unit;
}

function AcquisitionPlot({
  acquisitionSerieses,
  selectedUnitValue
}: AcquisitionPlotProps) {
  const { featureFlags } = useFeatureFlags();

  const selectedUnit = UNITS[selectedUnitValue];
  const yAxisDescription = selectedUnit.physicalSize;
  const yAxisTitle = `${yAxisDescription} [${selectedUnitValue}]`;
  const valueCount = acquisitionSerieses.index
    ? acquisitionSerieses.index.length
    : 0;

  // Remove the following code down to the render method when finally kicking out Plotly
  const PLOTLY_dataIds = Object.keys(acquisitionSerieses.header);

  const PLOTLY_layout = {
    showLegend: true,
    margin: { t: 18 },
    yaxis: { title: { text: yAxisTitle } }
  };

  let PLOTLY_traces: Array<Data> = [];

  if (valueCount === 1) {
    // build bar plot
    const xs = PLOTLY_dataIds.map((id) =>
      acquisitionSerieses.labels ? acquisitionSerieses.labels[id] : "Unbekannt"
    );
    const ys = PLOTLY_dataIds.map((id) => acquisitionSerieses.values[id][0]);
    PLOTLY_traces = [
      {
        type: "bar",
        x: xs,
        y: ys
      }
    ];
  } else if (valueCount > 1) {
    // build line plot
    PLOTLY_layout["xAxis"] = { title: { text: "Zeitraum" } };
    const xs = _.unzip(acquisitionSerieses.index)[0];
    PLOTLY_traces = PLOTLY_dataIds.map((id) => {
      return {
        name: acquisitionSerieses.labels
          ? acquisitionSerieses.labels[id]
          : "Unbekannt",
        x: xs,
        y: acquisitionSerieses.values[id],
        type: "scatter",
        mode: "lines",
        connectgaps: false,
        line: {
          shape: "hv"
        }
      };
    });
  }

  if (featureFlags.replacePlotly) {
    return valueCount === 1 ? (
      <AcquisitionBarPlot
        acquisitionSeries={acquisitionSerieses}
        yAxisTitle={yAxisTitle}
      />
    ) : (
      <AcquisitionLinePlot
        acquisitionSeries={acquisitionSerieses}
        yAxisTitle={yAxisTitle}
      />
    );
  } else {
    return (
      <Plot
        data={PLOTLY_traces}
        layout={PLOTLY_layout}
        style={{ width: "100%" }}
        useResizeHandler={true}
      />
    );
  }
}

export { AcquisitionPlot };
