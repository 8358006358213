import classNames from "classnames";
import React, { useId } from "react";
import type { BooleanFieldProps } from "../../../DynamicForm/FormItems/FormField/BooleanField/BooleanField";
import { BooleanField } from "../../../DynamicForm/FormItems/FormField/BooleanField/BooleanField";
import type {
  ChoiceValue,
  TsDropdownProps
} from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { TsDropdown } from "../../../DynamicForm/FormItems/FormField/Dropdown/TsDropdown";
import { Secret } from "../../../DynamicForm/FormItems/FormField/Secret/Secret";
import type { AsyncSelectProps } from "../FormFields/AsyncSelect/AsyncSelect";
import { AsyncSelect } from "../FormFields/AsyncSelect/AsyncSelect";
import type { DateInputProps } from "../FormFields/DateInput/DateInput";
import { DateInput } from "../FormFields/DateInput/DateInput";
import type { ExpressionEditorProps } from "../FormFields/ExpressionEditor/ExpressionEditor";
import { ExpressionEditor } from "../FormFields/ExpressionEditor/ExpressionEditor";
import type { FileUploadProps } from "../FormFields/FileUpload/FileUpload";
import { FileUpload } from "../FormFields/FileUpload/FileUpload";
import type { MonthDayDateInputProps } from "../FormFields/MonthDayDateInput/MonthDayDateInput";
import { MonthDayDateInput } from "../FormFields/MonthDayDateInput/MonthDayDateInput";
import type { NumberInputProps } from "../FormFields/NumberInput/NumberInput";
import { NumberInput } from "../FormFields/NumberInput/NumberInput";
import {
  RadioField,
  type RadioFieldProps
} from "../FormFields/RadioField/RadioField";
import type { TextareaFieldProps } from "../FormFields/TextareaField/TextareaField";
import { TextareaField } from "../FormFields/TextareaField/TextareaField";
import type { TextInputProps } from "../FormFields/TextInput/TextInput";
import { TextInput } from "../FormFields/TextInput/TextInput";
import "./FormField.scss";
import type { FormFieldInfoTextProps } from "./FormFieldInfoText/FormFieldInfoText";
import { FormFieldInfoText } from "./FormFieldInfoText/FormFieldInfoText";
import type { FormFieldLabelProps } from "./FormFieldLabel/FormFieldLabel";
import { FormFieldLabel } from "./FormFieldLabel/FormFieldLabel";
import type { HelperTextProps } from "./HelperText/HelperText";
import { HelperText } from "./HelperText/HelperText";

type FieldSpecificProps =
  | (TextInputProps & { type: "text" | "email" })
  | (NumberInputProps & { type: "number" })
  | (TextareaFieldProps & { type: "textarea" })
  | (React.InputHTMLAttributes<HTMLInputElement> & { type: "password" })
  | (DateInputProps & { type: "date" })
  | (MonthDayDateInputProps & { type: "month-day-date" })
  | (TsDropdownProps & {
      type: "dropdown";
      value: TsDropdownProps["defaultValue"];
      onChange: (value: ChoiceValue) => void;
    })
  | (AsyncSelectProps & {
      type: "async-select";
    })
  | (Omit<BooleanFieldProps, "checked" | "onInput"> & {
      type: "boolean";
      value: BooleanFieldProps["checked"];
      onChange: (value: boolean | null) => void;
    })
  | (RadioFieldProps & { type: "radio" })
  | (FileUploadProps & { type: "file-upload" })
  | (ExpressionEditorProps & { type: "expression" });

type FormFieldProps = FormFieldRendererProps &
  Omit<FormFieldLabelProps, "formFieldId"> &
  Partial<FormFieldInfoTextProps> & {
    className?: string;
    invalid?: boolean;
    warned?: boolean;
  };

function FormField(
  {
    className,
    id,
    label,
    helpText,
    highlight,
    error,
    helperText,
    warning,
    infoText,
    ...fieldSpecificProps
  }: FormFieldProps,
  ref: React.Ref<HTMLInputElement>
) {
  const fallbackId = useId();
  const formFieldId = id || fallbackId;

  return (
    <div className={classNames("FormField2", className)}>
      <FormFieldLabel
        formFieldId={formFieldId}
        helpText={helpText}
        highlight={highlight}
        label={label}
        required={fieldSpecificProps.required}
      />
      <ForwardedRefFormFieldRenderer
        {...fieldSpecificProps}
        error={error}
        id={formFieldId}
        ref={ref}
        warning={warning}
      />
      <HelperText error={error} helperText={helperText} warning={warning} />
      {infoText && <FormFieldInfoText infoText={infoText} />}
    </div>
  );
}

type FormFieldRendererProps = FieldSpecificProps &
  Pick<HelperTextProps, "error" | "helperText" | "warning">;

function FormFieldRenderer(
  { error, warning, ...fieldSpecificProps }: FormFieldRendererProps,
  ref: React.Ref<HTMLInputElement>
) {
  const invalid = typeof error !== "undefined" && error !== "";
  const warned = typeof warning !== "undefined" && warning !== "";

  function omitType<T extends { type: typeof fieldSpecificProps.type }>(
    obj: T
  ): Omit<T, "type"> {
    const { type, ...rest } = obj;
    return rest;
  }

  switch (fieldSpecificProps.type) {
    case "number":
      return (
        <NumberInput
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          ref={ref}
          warned={warned}
          onChange={(value) =>
            fieldSpecificProps.onChange(value === undefined ? null : value)
          }
        />
      );
    case "text":
    case "email":
      return (
        <TextInput
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          ref={ref}
          type={fieldSpecificProps.type}
          warned={warned}
        />
      );
    case "textarea":
      return (
        <TextareaField
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          // hack: I'm not sure how best to handle these ref types here
          ref={ref as React.Ref<HTMLTextAreaElement>}
          warned={warned}
        />
      );
    case "password":
      return (
        <Secret
          {...omitType(fieldSpecificProps)}
          value={fieldSpecificProps.value || ""}
          onChange={fieldSpecificProps.onChange || null}
        />
      );
    case "date":
      return (
        <DateInput
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          ref={ref}
          warned={warned}
        />
      );
    case "month-day-date":
      return (
        <MonthDayDateInput
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          warned={warned}
        />
      );
    case "dropdown":
      return (
        <TsDropdown
          {...omitType(fieldSpecificProps)}
          id={fieldSpecificProps.id}
          invalid={invalid}
          onChange={(_, value) => fieldSpecificProps.onChange(value)}
        />
      );
    case "async-select":
      return (
        <AsyncSelect {...omitType(fieldSpecificProps)} invalid={invalid} />
      );
    case "boolean":
      return (
        <BooleanField
          {...omitType(fieldSpecificProps)}
          checked={fieldSpecificProps.value}
          onInput={(_, value) => fieldSpecificProps.onChange(value)}
        />
      );
    case "radio":
      return (
        <RadioField
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          ref={ref}
        />
      );
    case "file-upload":
      return <FileUpload {...omitType(fieldSpecificProps)} />;
    case "expression":
      return (
        <ExpressionEditor
          {...omitType(fieldSpecificProps)}
          invalid={invalid}
          warned={warned}
        />
      );
    default:
      return <div>component not implemented</div>;
  }
}

const ForwardedRefFormField = React.forwardRef(FormField);
const ForwardedRefFormFieldRenderer = React.forwardRef(FormFieldRenderer);

export { ForwardedRefFormField as FormField, FormFieldProps };
