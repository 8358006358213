import React from "react";
import { ROUTES } from "../../routes";
import type {
  CurrentUser,
  ExtendedUser,
  Todo
} from "../../utils/backend-types";
import {
  Priority,
  RegulatoryCluster,
  TodoCategory
} from "../../utils/backend-types";
import { Modal, ModalBody } from "../BuildingBlocks/Layout/Modal/Modal";
import { Button } from "../Buttons/Button/Button";

type CurrentUserFields = Pick<
  CurrentUser,
  | "admin_url"
  | "has_analyzer_license"
  | "has_api_settings_access"
  | "has_manager_license"
  | "is_staff"
  | "chat_enabled"
  | "tracking_enabled"
  | "hubspot_enabled"
  | "userpilot_enabled"
>;

export interface TodoUser extends ExtendedUser, Partial<CurrentUserFields> {
  isSystemUser?: boolean;
}

export enum Mode {
  Normal = "normal",
  Onboarding = "onboarding",
  Universal = "universal"
}

export const CATEGORIES = {
  ONBOARDING: {
    displayName: "Onboarding",
    value: TodoCategory.Onboarding
  },
  REGULATORY_DUTY: {
    displayName: "Regulatorische Pflicht",
    value: TodoCategory.RegulatoryDuty
  },
  OTHER: {
    displayName: "Andere",
    value: TodoCategory.Other
  }
};

export const CLUSTERS = {
  ONBOARDING: {
    displayName: "Onboarding",
    value: RegulatoryCluster.Onboarding
  },
  REGULATORY_DUTY: {
    displayName: "Regulatorische Pflicht",
    value: RegulatoryCluster.RegulatoryDuty
  },
  OTHER: {
    displayName: "Andere",
    value: RegulatoryCluster.Other
  },
  ASSESSMENT_PROFITABILITY_AND_FEASIBILITY: {
    displayName: "Bewertung Wirtschaftlichkeit und Machbarkeit",
    value: RegulatoryCluster.AssessmentProfitabilityAndFeasibility
  },
  PLANNING_AND_REALISATION: {
    displayName: "Projektierung und Realisierung PV - Anlage",
    value: RegulatoryCluster.PlanningAndRealisation
  },
  METERING_CONCEPT_AND_OPERATIONS: {
    displayName: "Messkonzept und Messstellenbetrieb",
    value: RegulatoryCluster.MeteringConceptAndOperations
  },
  DIRECT_MARKETING: {
    displayName: "Direktvermarktung",
    value: RegulatoryCluster.DirectMarketing
  },
  POWER_SUPPLY_TO_TENANT_AND_CUSTOMER: {
    displayName: "Stromlieferung an Mieter / Kunden",
    value: RegulatoryCluster.PowerSupplyToTenantAndCustomer
  },
  PROJECT_FINALISATION: {
    displayName: "Projekt - Finalisierung",
    value: RegulatoryCluster.ProjectFinalisation
  }
};

export const PRIORITIES = {
  HIGH: {
    displayName: "High",
    value: Priority.High
  },
  MEDIUM: {
    displayName: "Medium",
    value: Priority.Medium
  },
  LOW: {
    displayName: "Low",
    value: Priority.Low
  },
  UNKNOWN: {
    displayName: "Ohne",
    value: Priority.Unknown
  }
};

export const TODO_DOCUMENT_STATUS = {
  OPEN: {
    displayName: "Offen",
    value: false
  },
  DOCUMENTS_PROVIDED: {
    displayName: "Dokumente bereitgestellt",
    value: true
  }
};

export enum TodoUserDecision {
  Open = "open",
  Rejected = "rejected",
  Confirmed = "confirmed",
  Unclear = "unclear"
}

export enum TodoSystemDecision {
  Applicable = "applicable",
  NotApplicable = "not_applicable",
  CantTell = "cant_tell",
  NeedMoreInformation = "need_more_information"
}

export const TODO_SYSTEM_DECISION_BY_USER_DECISION = {
  [TodoUserDecision.Confirmed]: TodoSystemDecision.Applicable,
  [TodoUserDecision.Rejected]: TodoSystemDecision.NotApplicable,
  [TodoUserDecision.Unclear]: TodoSystemDecision.CantTell
};

export const SYSTEM_USER_NAME = "System User";
export const UNKNOWN_USER = "Unbekannte Person";

interface WhichUserModalProps {
  isOpen: boolean;
  questionText: string;
  toggle: () => void;
  onClickSystemUser: () => void;
  onClickSelf: () => void;
}

export function WhichUserModal({
  isOpen,
  questionText,
  toggle,
  onClickSystemUser,
  onClickSelf
}: WhichUserModalProps) {
  return (
    <Modal className="WhichUserModal" isOpen={isOpen} toggle={toggle}>
      <ModalBody scrollable>
        {questionText}
        <div className="which-user-modal-buttons">
          <Button color="brand" onClick={onClickSystemUser}>
            Als Systemnutzer
          </Button>
          <Button color="brand" onClick={onClickSelf}>
            Unter meinem Namen
          </Button>
        </div>
      </ModalBody>
    </Modal>
  );
}

export function getBasePathByMode(mode: Mode) {
  switch (mode) {
    case Mode.Onboarding:
      return ROUTES.onboarding;
    case Mode.Universal:
      return ROUTES.universalTodos;
    default:
      return ROUTES.todos;
  }
}

const NO_PERSON_NAME = "Kein verpflichtetes Unternehmen";

interface TodosByPersonGroup {
  personName: string;
  todos: Array<Todo>;
}

export function groupTodosByPerson(todos: Array<Todo>, mode: Mode) {
  if (mode === Mode.Universal || todos.length === 0) {
    return [];
  }

  const todosByPersonGroups = todos.reduce<Array<TodosByPersonGroup>>(
    (groups, todo) => {
      const sameGroup = groups.find(
        (group) =>
          group.personName === todo.person ||
          (todo.person === null && group.personName === NO_PERSON_NAME)
      );

      if (sameGroup) {
        sameGroup.todos.push(todo);
      } else {
        groups.push({
          personName: todo.person ?? NO_PERSON_NAME,
          todos: [todo]
        });
      }

      return groups;
    },
    []
  );

  todosByPersonGroups.sort((listA, listB) => {
    const nameA: string = listA.personName;
    const nameB: string = listB.personName;

    if (nameA !== NO_PERSON_NAME && nameB === NO_PERSON_NAME) {
      return -1;
    } else if (nameA === NO_PERSON_NAME && nameB !== NO_PERSON_NAME) {
      return 1;
    } else if (nameA === NO_PERSON_NAME && nameB === NO_PERSON_NAME) {
      return 0;
    }

    return nameA < nameB ? -1 : 1;
  });

  return todosByPersonGroups;
}
