import classNames from "classnames";
import React from "react";

import { Icon } from "../../BuildingBlocks/Icon/Icon";
import { IconName } from "../../BuildingBlocks/Icon/types";
import type { ButtonProps } from "../Button/Button";
import { Button } from "../Button/Button";

import "./SpinButton.scss";

interface SpinButtonProps extends ButtonProps {
  spin?: boolean;
}

function SpinButton({
  spin = false,
  children,
  className,
  ...otherProps
}: SpinButtonProps) {
  const disabled = otherProps.disabled || spin;

  return (
    <Button
      {...otherProps}
      className={classNames("SpinButton mantine-focus-auto", className)}
      disabled={disabled}
    >
      <span className="button-content">
        {spin && (
          <span className="spinner">
            <Icon className="spinner-icon" name={IconName.SpinnerSpinning} />
          </span>
        )}
        <span>{children}</span>
      </span>
    </Button>
  );
}

export { SpinButton, SpinButtonProps };
