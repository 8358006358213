import React, { useCallback, useState, useEffect } from "react";
import { FormGroup } from "reactstrap";

import api from "../../api";
import { useNotificationSettings } from "../../hooks/useNotificationSettings";
import urls from "../../urls";
import type {
  NotificationSetting,
  NotificationSettings as NotificationSettingsState
} from "../../utils/backend-types";
import { NotificationSettingDbName } from "../../utils/backend-types";
import { showToast } from "../../utils/toast";
import { Checkbox } from "../BuildingBlocks/Forms/Checkbox/Checkbox";
import { Portlet } from "../BuildingBlocks/Layout/Portlet";
import { SpinButton } from "../Buttons/SpinButton/SpinButton";
import { ErrorAlertPopup } from "../ErrorAlertPopup/ErrorAlertPopup";
import { LoadOrError } from "../LoadOrError/LoadOrError";

import "./Settings.scss";

const NOTIFICATION_SETTINGS: NotificationSetting[] = [
  {
    dbName: NotificationSettingDbName.TodoCreation,
    label: "Wenn in einem meiner Projekte eine neue Aufgabe angelegt wurde."
  },
  {
    dbName: NotificationSettingDbName.TodoUpcomingDueDate,
    label: "Erinnerungen über Aufgaben, die bald fällig sind"
  }
];

function NotificationSettings() {
  const { settings, isLoading, error } = useNotificationSettings();

  const [currentSettings, setCurrentSettings] =
    useState<NotificationSettingsState>({} as NotificationSettingsState);
  const [inProgress, setInProgress] = useState(false);

  const onSettingChange = useCallback(
    (dbName: NotificationSettingDbName, checked: boolean) => {
      setCurrentSettings({ ...currentSettings, [dbName]: checked });
    },
    [currentSettings]
  );

  const handleSave = useCallback(async () => {
    setInProgress(true);

    try {
      await api.patch(urls.api.settings(), currentSettings);

      showToast("success", "Die Einstellungen wurden erfolgreich gespeichert.");
    } catch (error) {
      ErrorAlertPopup(error);
    }

    setInProgress(false);
  }, [currentSettings]);

  useEffect(() => {
    if (!isLoading) {
      setCurrentSettings(settings || ({} as NotificationSettingsState));
    }
  }, [settings, isLoading]);

  return (
    <LoadOrError error={error} loading={isLoading}>
      <Portlet
        footer={
          <SpinButton
            className="submit-button"
            color="primary"
            spin={inProgress}
            type="submit"
            onClick={handleSave}
          >
            Speichern
          </SpinButton>
        }
        title="E-Mail-Benachrichtigungen"
      >
        <FormGroup>
          {NOTIFICATION_SETTINGS.map(({ dbName, label }) => (
            <Checkbox
              checked={!!currentSettings?.[dbName]}
              disabled={inProgress}
              key={dbName}
              onChange={(checked) => onSettingChange(dbName, checked)}
            >
              {label}
            </Checkbox>
          ))}
        </FormGroup>
      </Portlet>
    </LoadOrError>
  );
}

export { NotificationSettings };
