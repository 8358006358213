import PropTypes from "prop-types";
import React, { PureComponent } from "react";
import { Col } from "reactstrap";
import api from "../../../api";
import urls from "../../../urls";
import { postDataAndPollResponse } from "../../../utils/api-utils";
import { downloadCsvOrExcelFileForResponseWithContentDisposition } from "../../../utils/files/downloadCsvOrExcelFileForResponseWithContentDisposition";
import { ErrorAlert } from "../../BuildingBlocks/ErrorAlert/ErrorAlert";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { Portlet } from "../../BuildingBlocks/Layout/Portlet";
import { Section } from "../../BuildingBlocks/Layout/Section";
import { SpinButton } from "../../Buttons/SpinButton/SpinButton";
import "./EnergyFlowDownload.scss";

export default class EnergyFlowDownload extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      error: undefined
    };
  }

  handleDownload = async () => {
    this.setState({
      error: undefined,
      loading: true
    });
    try {
      await this.requestEnergyFlows();
    } catch (error) {
      this.setState({
        loading: false,
        error: error
      });
    }
  };

  requestEnergyFlows = async () => {
    const postUrl = urls.api.allocatedMeteringValueRequest(
      this.props.selectedVariantId
    );

    await postDataAndPollResponse(postUrl)
      .then(({ evaluationId, taskIds }) => {
        if (evaluationId) {
          this.setGenericError();
        } else {
          this.downloadEnergyFlowsData(taskIds[0]);
        }
      })
      .catch(this.setGenericError);
  };

  downloadEnergyFlowsData = async (taskId) => {
    try {
      const url = urls.api.allocatedMeteringValueDownload(
        this.props.selectedVariantId,
        taskId
      );
      const response = await api.get(url);
      downloadCsvOrExcelFileForResponseWithContentDisposition(response, false);
    } catch (error) {
      this.setState({
        error: error
      });
    } finally {
      this.setState({
        loading: false
      });
    }
  };

  setGenericError = () => {
    this.setState({
      loading: false,
      error: { response: { status: 500 } }
    });
  };

  render() {
    return (
      <Col className="EnergyFlowDownload" md="6">
        <Portlet
          className="m-portlet--head-sm"
          icon={IconName.Download2}
          id="download-portlet"
          title="Download"
          toggle
          toggleIconClosed={IconName.AngleRight}
          toggleIconOpen={IconName.AngleDown}
          toggleInitial={true}
        >
          <Section>
            <p>
              Hier können Sie die für die Berechnung verwendeten Lastprofile,
              sowie die Aufteilung der Messwerte auf bilanzielle Lieferungen
              herunterladen. Die Daten werden in 15-minütiger Auflösung im
              csv-Format bereitgestellt.
            </p>
            <p>
              Bitte beachten Sie, dass die Messwerte zum Zweck der
              wirtschaftlichen Bewertung auf das Jahr 2019 projiziert wurden,
              auch wenn Sie Lastprofile für einen anderen Zeitraum zur Verfügung
              gestellt haben.
            </p>
            <p>
              <SpinButton
                color="primary"
                disabled={this.state.loading}
                id="download-allocated-metering-values"
                spin={this.state.loading}
                onClick={this.handleDownload}
              >
                Download
              </SpinButton>
            </p>
            {this.state.error && (
              <p>
                <ErrorAlert error={this.state.error} />
              </p>
            )}
          </Section>
        </Portlet>
      </Col>
    );
  }
}

EnergyFlowDownload.propTypes = {
  selectedVariantId: PropTypes.number.isRequired
};
