import { IconName } from "../types";

const ICONS_MAP = {
  [IconName.AngleDown]: "la-angle-down",
  [IconName.AngleLeft]: "la-angle-left",
  [IconName.AngleRight]: "la-angle-right",
  [IconName.AngleUp]: "la-angle-up",
  [IconName.AreaChart]: "la-area-chart",
  [IconName.ArrowCircleLeft]: "la-arrow-circle-left",
  [IconName.ArrowCircleRight]: "la-arrow-circle-right",
  [IconName.ArrowDown]: "la-arrow-down",
  [IconName.Calendar]: "la-calendar",
  [IconName.CaretDown]: "la-caret-down",
  [IconName.CaretLeft]: "la-caret-left",
  [IconName.CaretRight]: "la-caret-right",
  [IconName.CaretUp]: "la-caret-up",
  [IconName.Chain]: "la-chain",
  [IconName.Check]: "la-check",
  [IconName.CheckCircle]: "la-check-circle",
  [IconName.Circle]: "la-circle",
  [IconName.Clipboard]: "la-clipboard",
  [IconName.Close]: "la-close",
  [IconName.CloudDownload]: "la-cloud-download",
  [IconName.Cogs]: "la-cogs",
  [IconName.Copy]: "la-copy",
  [IconName.Crosshairs]: "la-crosshairs",
  [IconName.Download]: "la-download",
  [IconName.Edit]: "la-edit",
  [IconName.Eraser]: "la-eraser",
  [IconName.Eye]: "la-eye",
  [IconName.Envelope]: "la-envelope",
  [IconName.ExclamationCircle]: "la-exclamation-circle",
  [IconName.ExternalLink]: "la-external-link",
  [IconName.Fax]: "la-fax",
  [IconName.File]: "la-file",
  [IconName.FileCode]: "la-file-code-o",
  [IconName.FileExcel]: "la-file-excel-o",
  [IconName.FileImage]: "la-file-image-o",
  [IconName.FilePdf]: "la-file-pdf-o",
  [IconName.FileText]: "la-file-text",
  [IconName.FileWord]: "la-file-word-o",
  [IconName.Filter]: "la-filter",
  [IconName.Home]: "la-home",
  [IconName.InfoCircle]: "la-info-circle",
  [IconName.Lightbulb]: "la-lightbulb-o",
  [IconName.Magic]: "la-magic",
  [IconName.Minus]: "la-minus",
  [IconName.Paste]: "la-paste",
  [IconName.Pause]: "la-pause",
  [IconName.Pencil]: "la-pencil",
  [IconName.Phone]: "la-phone",
  [IconName.Plus]: "la-plus",
  [IconName.PlusCircle]: "la-plus-circle",
  [IconName.Question]: "la-question",
  [IconName.QuestionCircle]: "la-question-circle",
  [IconName.Refresh]: "la-refresh",
  [IconName.RotateRight]: "la-rotate-right",
  [IconName.Save]: "la-save",
  [IconName.Search]: "la-search",
  [IconName.Share]: "la-share",
  [IconName.Sliders]: "la-sliders",
  [IconName.SortAsc]: "la-sort-asc",
  [IconName.SortDesc]: "la-sort-desc",
  [IconName.Spinner]: "la-spinner",
  [IconName.SpinnerSpinning]: "la-spinner la-spin",
  [IconName.Star]: "la-star",
  [IconName.Table]: "la-table",
  [IconName.ThumbTack]: "la-thumb-tack",
  [IconName.Times]: "la-times",
  [IconName.Trash]: "la-trash",
  [IconName.Unsorted]: "la-unsorted",
  [IconName.Upload]: "la-upload",
  [IconName.Warning]: "la-warning"
} as const;

const LA_ICONS = Object.keys(ICONS_MAP) as Array<IconName>;

function getLaIconClassNameByIconName(iconName: IconName) {
  return ICONS_MAP[iconName] || "";
}

export { getLaIconClassNameByIconName, LA_ICONS };
