import { DonutChart } from "@mantine/charts";
import { Card, Text, Title } from "@mantine/core";
import * as React from "react";
import { THEME_VARS } from "../../../utils/constants";
import "./TodosProgress.scss";

interface TodosProgressProps {
  done: number;
  total: number;
}

function TodosProgress({ done, total }: TodosProgressProps) {
  return (
    <Card className="TodosProgress" p="md">
      <Title order={5}>Fortschritt</Title>
      <DonutChart
        data={[
          { name: "Erledigt", value: done, color: "#CCB0FA" },
          {
            name: "Offen",
            value: total - done,
            color: THEME_VARS.customLightGrey
          }
        ]}
        endAngle={0}
        size={200}
        startAngle={180}
        thickness={30}
        withTooltip={false}
      />
      <Text className="label" size="md" ta="center">
        <b>{done}</b>
        <br />
        erledigt
      </Text>
      <Text className="donut-start">0</Text>
      <Text className="donut-end">{total}</Text>
    </Card>
  );
}

export { TodosProgress };
