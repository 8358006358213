import { PAYMENT_METHOD_CHOICES } from "../../../../../../utils/constants";
import type { FormFieldData } from "../../../../../BuildingBlocks/Forms/Controllers/FormFieldController";
import type { TabData } from "../../../../../BuildingBlocks/Layout/TabBar/TabBar";
import type { Paragraph6ContractFormFields } from "../../../../Paragraph6.types";

enum PARAGRAPH_6_CONTRACT_FORM_TABS {
  GENERAL_INFORMATION = "allgemeine-informationen",
  DEADLINES = "fristen",
  SHARES = "beteiligungen",
  ADDITIONAL_INFORMATION = "zusaetzliche-angaben"
}

const TABS: Array<TabData<PARAGRAPH_6_CONTRACT_FORM_TABS>> = [
  {
    id: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION,
    label: "Allgemeine Informationen"
  },
  {
    id: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES,
    label: "Fristen"
  },
  {
    id: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES,
    label: "Beteiligungen"
  },
  {
    id: PARAGRAPH_6_CONTRACT_FORM_TABS.ADDITIONAL_INFORMATION,
    label: "Zusätzliche Angaben"
  }
];

const PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA = {
  name: {
    name: "name",
    required: true,
    label: "Vertragsname",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  project_description: {
    name: "project_description",
    label: "Projektbezeichnung",
    type: "text",
    disabled: true,
    defaultValue:
      "Finanzielle Beteiligung von Gemeinden und Kommunen nach § 6 EEG",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  supplier: {
    name: "supplier",
    required: true,
    label: "Gesellschaft",
    type: "dropdown",
    choices: [], // will be filled dynamically during runtime
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_name: {
    name: "community_name",
    required: true,
    label: "Name",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_address: {
    name: "community_address",
    required: true,
    label: "Adresse",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_address_extra: {
    name: "community_address_extra",
    required: false,
    label: "Adresszusatz",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_city: {
    name: "community_city",
    required: true,
    label: "Stadt",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_zip: {
    name: "community_zip",
    required: true,
    label: "Postleitzahl",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_email: {
    name: "community_email",
    required: false,
    label: "E-Mail",
    type: "email",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  community_iban: {
    name: "community_iban",
    required: true,
    label: "IBAN",
    type: "text",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.GENERAL_INFORMATION
  },
  signing_date: {
    name: "signing_date",
    required: true,
    label: "Vertragsunterzeichnung (TT.MM.JJJJ)",
    type: "date",
    placeholder: "Bitte wählen Sie ein Datum aus.",
    helpText:
      "Das Datum wird im Sinne des Vertragsabschlusses in der Gutschrift ausgewiesen",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES
  },
  begin: {
    name: "begin",
    required: true,
    label: "Leistungsbeginn (TT.MM.JJJJ)",
    type: "date",
    placeholder: "Bitte wählen Sie ein Datum aus.",
    helpText: "Beginn des erstmaligen Abrechnungszeitraums",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES
  },
  minimum_term: {
    name: "minimum_term",
    required: true,
    label: "Ende der Mindestvertragslaufzeit (TT.MM.JJJJ)",
    type: "date",
    placeholder: "Bitte wählen Sie ein Datum aus.",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES
  },
  end: {
    name: "end",
    required: false,
    label: "Vertragsende (TT.MM.JJJJ – ggf. wegen außerordentlicher Kündigung)",
    type: "date",
    placeholder: "Bitte wählen Sie ein Datum aus.",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES
  },
  billing_method_and_period: {
    name: "billing_method_and_period",
    required: true,
    label: "Abrechnungsart und -periode",
    type: "dropdown",
    choices: [
      {
        value: "annual_based_on_measurements",
        displayName: "Jährliche Gutschrift"
      }
    ],
    disabled: true,
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES
  },
  billing_period_start: {
    name: "billing_period_start",
    required: true,
    label: "Beginn des wiederkehrenden Abrechnungszeitraumes",
    type: "month-day-date",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.DEADLINES
  },
  beteiligung: {
    name: "beteiligung",
    required: true,
    label: "Beteiligung in ct/kWh",
    type: "number",
    inputGroupText: "ct/kWh",
    infoText:
      "Die vertragliche Beteiligung der Gemeinde ist auf maximal 0,2 begrenzt.",
    placeholder: "z. Bsp. 0,2",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  formula_beteiligung: {
    name: "formula_beteiligung",
    label: "Formel",
    type: "textarea",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  formula_beteiligung_text: {
    name: "formula_beteiligung_text",
    label: "Beteiligungsregelung in Worten",
    type: "textarea",
    infoText:
      "Bitte hinterlegen Sie einen verständlichen Text zur Anzeige auf Ihren Gutschriften / Zahlungsbelegen. Dieser kann sich an der Formulierung innerhalb Ihrer Verträge orientieren.",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  beteiligung_nicht_erstattungsfaehig: {
    name: "beteiligung_nicht_erstattungsfaehig",
    required: true,
    label: "Beteiligung für nicht-rückerstattungsfähige Mengen in ct/kWh",
    type: "number",
    inputGroupText: "ct/kWh",
    infoText:
      "Die vertragliche Beteiligung der Gemeinde ist auf maximal 0,2 begrenzt.",
    placeholder: "z. Bsp. 0,2",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  formula_beteiligung_nicht_erstattungsfaehig: {
    name: "formula_beteiligung_nicht_erstattungsfaehig",
    label: "Formel für nicht-rückerstattungsfähige Mengen",
    type: "textarea",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  formula_beteiligung_nicht_erstattungsfaehig_text: {
    name: "formula_beteiligung_nicht_erstattungsfaehig_text",
    label:
      "Beteiligungsregelung für nicht-rückerstattungsfähige Mengen in Worten",
    type: "textarea",
    infoText:
      "Bitte hinterlegen Sie einen verständlichen Text zur Anzeige auf Ihren Gutschriften / Zahlungsbelegen. Dieser kann sich an der Formulierung innerhalb Ihrer Verträge orientieren.",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  beteiligung_fiktive_strommengen: {
    name: "beteiligung_fiktive_strommengen",
    required: true,
    label: "Beteiligung für fiktive Mengen in ct/kWh",
    type: "number",
    inputGroupText: "ct/kWh",
    infoText:
      "Die Möglichkeit einer Rückerstattung ist momentan nicht eindeutig geklärt. Sollten Sie die Option „Ja“ auswählen, geben Sie später bitte entsprechende fiktive Strommengen an.",
    placeholder: "z. Bsp. 0,2",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  payment_method: {
    name: "payment_method",
    required: true,
    label: "Zahlungsverfahren",
    type: "dropdown",
    choices: PAYMENT_METHOD_CHOICES,
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  due_date_value: {
    name: "due_date_value",
    required: true,
    type: "number",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  due_date_unit: {
    name: "due_date_unit",
    required: true,
    type: "dropdown",
    choices: [
      { value: "day", displayName: "Tag(en)" },
      { value: "month", displayName: "Monat(en)" },
      { value: "year", displayName: "Jahr(en)" }
    ],
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.SHARES
  },
  notes: {
    name: "notes",
    label: "Ergänzungen für Gemeinden / Kommunen",
    type: "textarea",
    infoText:
      "Bitte ergänzen Sie hier Inhalte, die auf Ihren Gutschriften ergänzt werden sollen. Diese Inhalte werden am Ende Ihrer jeweiligen Gutschriften angezeigt.",
    group: PARAGRAPH_6_CONTRACT_FORM_TABS.ADDITIONAL_INFORMATION
  }
} as const satisfies FormFieldData<Paragraph6ContractFormFields>;

export {
  PARAGRAPH_6_CONTRACT_FORM_INPUT_DATA,
  PARAGRAPH_6_CONTRACT_FORM_TABS,
  TABS
};
