import type { MRT_RowData, MRT_TableOptions } from "mantine-react-table";

/** Define re-useable default Mantine-React-Table options for table edit mode */
export function getDefaultMRTTableEditModeOptions<
  TData extends MRT_RowData
>(): Partial<MRT_TableOptions<TData>> {
  return {
    editDisplayMode: "table",
    enableEditing: true
  };
}
