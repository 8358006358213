import classNames from "classnames";
import React from "react";

import type { ButtonProps } from "../Button/Button";
import { Button } from "../Button/Button";

type SquareButtonProps = ButtonProps;

function SquareButton({ className, ...other }: SquareButtonProps) {
  return (
    <Button
      className={classNames("m-btn--square mantine-focus-auto", className)}
      {...other}
    />
  );
}

export { SquareButton, SquareButtonProps };
