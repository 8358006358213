import { Button, Group, Stack } from "@mantine/core";
import React, { useState } from "react";
import { useVariantDetail } from "../../../hooks/useVariantDetail";
import { OptiSupportEndpoints } from "../../../urls";
import { IconName } from "../../BuildingBlocks/Icon/types";
import { buttonColors } from "../../Buttons/Button/Button";
import { IconButton } from "../../Buttons/IconButton/IconButton";
import { LoadOrError } from "../../LoadOrError/LoadOrError";
import { OptiSupportHelpLink } from "../../OptiSupportHelpLink/OptiSupportHelpLink";
import { useShouldShowStaffView } from "../../StaffViewToggle/useShouldShowStaffView";
import { useParagraph6Contracts } from "../hooks/useParagraph6Contracts";
import { useParagraph6CreatableCredits } from "../hooks/useParagraph6CreatableCredits";
import { useParagraph6CreditErrors } from "../hooks/useParagraph6CreditErrors";
import { FinancialDocumentType } from "../Paragraph6.types";
import { Paragraph6AvailableCreditsModal } from "./Paragraph6AvailableCreditsModal/Paragraph6AvailableCreditsModal";
import { Paragraph6CreditErrorMessage } from "./Paragraph6CreditErrorMessage/Paragraph6CreditErrorMessage";
import "./Paragraph6CreditsPage.scss";
import { Paragraph6CreditsTable } from "./Paragraph6CreditsTable/Paragraph6CreditsTable";
import { UsePaymentInformationToggleSwitch } from "./UsePaymentInformationToggleSwitch/UsePaymentInformationToggleSwitch";

interface Paragraph6CreditsPageProps {
  variantId: number;
}

function Paragraph6CreditsPage({ variantId }: Paragraph6CreditsPageProps) {
  const {
    creatableCredits,
    error: paragraph6CreatableCreditsError,
    createParagraph6Credits,
    fetchParagraph6DraftCreditStatus
  } = useParagraph6CreatableCredits(variantId);

  const {
    isLoading: paymentInformationIsLoading,
    error: paymentInformationError,
    variantDetail
  } = useVariantDetail(variantId);

  const usePaymentInformation =
    variantDetail?.paragraph_6_payment_information_instead_of_credit;

  const { creditErrors } = useParagraph6CreditErrors(variantId);
  const shouldShowStaffView = useShouldShowStaffView();

  const {
    paragraph6Contracts,
    isLoading: paragraph6ContractsLoading,
    error: paragraph6ContractsError
  } = useParagraph6Contracts(variantId);

  const contractNames = paragraph6Contracts?.map(({ name, id }) => ({
    name,
    id
  }));

  const [availableCreditsModalOpen, setAvailableCreditsModalOpen] =
    useState(false);
  const [isPreviewModal, setIsPreviewModal] = useState(false);

  function openGenerateCreditsModal() {
    setIsPreviewModal(false);
    setAvailableCreditsModalOpen(true);
  }

  function openPreviewCreditsModal() {
    setIsPreviewModal(true);
    setAvailableCreditsModalOpen(true);
  }

  return (
    <LoadOrError
      error={
        paragraph6ContractsError ||
        paragraph6CreatableCreditsError ||
        paymentInformationError
      }
      loading={paragraph6ContractsLoading || paymentInformationIsLoading}
      loadingMessage="Vertragsinformationen werden geladen ..."
    >
      <div className="Paragraph6CreditsPage">
        <Paragraph6AvailableCreditsModal
          contentType={
            usePaymentInformation
              ? FinancialDocumentType.PaymentInformation
              : FinancialDocumentType.Credit
          }
          contractNames={contractNames}
          creatableCredits={creatableCredits}
          createParagraph6Credits={createParagraph6Credits}
          fetchDraftCreditStatus={fetchParagraph6DraftCreditStatus}
          isOpen={availableCreditsModalOpen}
          previewMode={isPreviewModal}
          variantId={variantId}
          onClose={() => setAvailableCreditsModalOpen(false)}
        />
        <Stack gap="lg" mb="lg">
          {usePaymentInformation ? (
            <OptiSupportHelpLink
              optiSupportEndpoint={
                OptiSupportEndpoints.ZahlungsinformationenZurKommunalenBeteiligungNach6Eeg
              }
              text="Wie lassen sich mit opti.node Zahlungsinformationen erstellen?"
            />
          ) : (
            <OptiSupportHelpLink
              optiSupportEndpoint={
                OptiSupportEndpoints.KommunaleBeteiligungNachParagraph6Eeg
              }
              text="Wie lassen sich mit opti.node Gutschriften und Stornos erstellen?"
            />
          )}
          {shouldShowStaffView && (
            <Group justify="flex-end">
              <UsePaymentInformationToggleSwitch
                usePaymentInformation={!!usePaymentInformation}
                variantId={variantId}
              />
            </Group>
          )}
        </Stack>
        <div className="credits-controls">
          <div className="credits-buttons">
            {usePaymentInformation ? (
              <Button
                color="brand"
                disabled={!creatableCredits || creatableCredits.length === 0}
                onClick={openGenerateCreditsModal}
              >
                Verfügbare Zahlungsinformationen
              </Button>
            ) : (
              <IconButton
                color="brand"
                disabled={!creatableCredits || creatableCredits.length === 0}
                iconName={IconName.Plus}
                onClick={openGenerateCreditsModal}
              >
                Gutschriften erstellen
              </IconButton>
            )}
            <IconButton
              color={buttonColors.brand}
              iconName={IconName.Eye}
              outline
              onClick={openPreviewCreditsModal}
            >
              Vorschau
            </IconButton>
          </div>
          {creditErrors && creditErrors.length > 0 && (
            <Paragraph6CreditErrorMessage
              contractNames={contractNames}
              paragraph6CreditErrors={creditErrors}
            />
          )}
        </div>
        <Paragraph6CreditsTable
          isPaymentInformationView={usePaymentInformation}
          variantId={variantId}
        />
      </div>
    </LoadOrError>
  );
}

export { Paragraph6CreditsPage, Paragraph6CreditsPageProps };
