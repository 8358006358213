import type { ComboboxItem } from "@mantine/core";
import { Group, MultiSelect, Stack, Title } from "@mantine/core";
import { MonthPickerInput } from "@mantine/dates";
import { DateTime } from "luxon";
import React, { useEffect, useMemo, useState } from "react";
import { usePersonGenerators } from "../../../../hooks/usePersonGenerators";
import { backendDateOrDateTimeToLuxonDateTime } from "../../../../utils/dates/backendDateOrDateTimeToLuxonDateTime";
import { sortBackendDates } from "../../../../utils/dates/sortBackendDates";
import { LoadOrError } from "../../../LoadOrError/LoadOrError";
import {
  ChartTableSwitch,
  ChartTableSwitchState
} from "../../ChartTableSwitch/ChartTableSwitch";
import { useGeneratorEnergyRevenuesData } from "../../hooks/useGeneratorEnergyRevenuesData";
import { EnergyRevenuesView } from "../EnergyRevenuesView";
import { getMinOrMaxDateFromEnergyRevenues } from "../utils/getMinOrMaxDateFromEnergyRevenues";

interface GeneratorSectionProps {
  companyId: number;
}

function GeneratorSection({ companyId }: GeneratorSectionProps) {
  const [view, setView] = useState<ChartTableSwitchState>(
    ChartTableSwitchState.Chart
  );
  const {
    data: generators,
    error: generatorsError,
    isLoading: generatorsAreLoading
  } = usePersonGenerators(companyId);

  const generatorOptions = useMemo(
    () =>
      generators?.map<ComboboxItem>((generator) => ({
        label: `${generator.name} (${generator.siteName})`,
        value: generator.id.toString()
      })),
    [generators]
  );

  useEffect(() => {
    if (generatorOptions && generatorOptions.length > 0) {
      setSelectedGenerators([generatorOptions[0].value]);
    } else {
      setSelectedGenerators(undefined);
    }
  }, [generatorOptions]);

  const [selectedGenerators, setSelectedGenerators] = useState<
    Array<string> | undefined
  >();
  const [selectedMonthAndYearRange, setSelectedMonthAndYearRange] = useState<
    [Date | null, Date | null]
  >([
    DateTime.now().startOf("year").toJSDate(),
    DateTime.now().startOf("month").toJSDate()
  ]);
  const {
    energyRevenuesGeneratorData,
    isLoading: isEnergyRevenuesGeneratorDataLoading,
    error: energyRevenuesGeneratorDataError
  } = useGeneratorEnergyRevenuesData(
    selectedGenerators?.map((generatorId) => Number(generatorId)) ?? []
  );

  const formattedGeneratorData = useMemo(() => {
    return [...(energyRevenuesGeneratorData ?? [])]
      .filter((energyRevenues) => {
        const revenuesDate = backendDateOrDateTimeToLuxonDateTime(
          energyRevenues.period_start
        ).toJSDate();

        return (
          (selectedMonthAndYearRange[0] === null ||
            revenuesDate >= selectedMonthAndYearRange[0]) &&
          (selectedMonthAndYearRange[1] === null ||
            revenuesDate <= selectedMonthAndYearRange[1])
        );
      })
      .sort((revenuesA, revenuesB) =>
        sortBackendDates(revenuesA.period_start, revenuesB.period_start)
      );
  }, [energyRevenuesGeneratorData, selectedMonthAndYearRange]);

  return (
    <Stack gap="sm">
      <Title order={5}>Anlagenübersicht</Title>

      <Group justify="space-between">
        <Group gap="sm">
          <MultiSelect
            className="generator-select"
            clearable
            data={generatorOptions}
            disabled={generatorsAreLoading || generators?.length === 0}
            label="Anlagen"
            placeholder="Anlagen auswählen"
            searchable
            size="xs"
            value={selectedGenerators}
            w={624}
            onChange={setSelectedGenerators}
          />
          <MonthPickerInput
            allowSingleDateInRange
            clearable
            disabled={
              generatorsAreLoading || energyRevenuesGeneratorData?.length === 0
            }
            label="Berechneter Zeitraum"
            maxDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesGeneratorData ?? [],
              "max"
            )?.toJSDate()}
            minDate={getMinOrMaxDateFromEnergyRevenues(
              energyRevenuesGeneratorData ?? [],
              "min"
            )?.toJSDate()}
            placeholder="Monate auswählen"
            size="xs"
            type="range"
            value={selectedMonthAndYearRange}
            onChange={setSelectedMonthAndYearRange}
          />
        </Group>
        <ChartTableSwitch setValue={setView} value={view} />
      </Group>

      <LoadOrError
        error={generatorsError || energyRevenuesGeneratorDataError}
        loading={generatorsAreLoading || isEnergyRevenuesGeneratorDataLoading}
      >
        <EnergyRevenuesView
          energyRevenuesData={formattedGeneratorData}
          view={view}
        />
      </LoadOrError>
    </Stack>
  );
}

export { GeneratorSection };
