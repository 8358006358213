import airConditioner from "../../../icons/structure-view-diagram/air-conditioner.svg";
import battery from "../../../icons/structure-view-diagram/battery.svg";
import biomassPowerPlant from "../../../icons/structure-view-diagram/biomass-power-plant.svg";
import cantenn from "../../../icons/structure-view-diagram/canteen.svg";
import capacitor from "../../../icons/structure-view-diagram/capacitor.svg";
import chp from "../../../icons/structure-view-diagram/chp.svg";
import circle from "../../../icons/structure-view-diagram/circle.svg";
import electricCar from "../../../icons/structure-view-diagram/electric-car.svg";
import electricMeter from "../../../icons/structure-view-diagram/electric-meter.svg";
import electricTower from "../../../icons/structure-view-diagram/electric-tower.svg";
import elevator from "../../../icons/structure-view-diagram/elevator.svg";
import factory from "../../../icons/structure-view-diagram/factory.svg";
import family from "../../../icons/structure-view-diagram/family.svg";
import flash from "../../../icons/structure-view-diagram/flash.svg";
import fuellCell from "../../../icons/structure-view-diagram/fuell-cell.svg";
import gasCogenerationPlant from "../../../icons/structure-view-diagram/gas-cogeneration-plant.svg";
import gasTurbine from "../../../icons/structure-view-diagram/gas-turbine.svg";
import generator from "../../../icons/structure-view-diagram/generator.svg";
import heatPump from "../../../icons/structure-view-diagram/heat-pump.svg";
import heatStorage from "../../../icons/structure-view-diagram/heat-storage.svg";
import officeBlock from "../../../icons/structure-view-diagram/office-block.svg";
import orcSystem from "../../../icons/structure-view-diagram/orc-system.svg";
import questionmark from "../../../icons/structure-view-diagram/questionmark.svg";
import radiatior from "../../../icons/structure-view-diagram/radiator.svg";
import realEstate from "../../../icons/structure-view-diagram/real-estate.svg";
import rectangle from "../../../icons/structure-view-diagram/rectangle.svg";
import residental from "../../../icons/structure-view-diagram/residential.svg";
import solarPanel from "../../../icons/structure-view-diagram/solar-panel.svg";
import user from "../../../icons/structure-view-diagram/user.svg";
import valve from "../../../icons/structure-view-diagram/valve.svg";
import windTurbine from "../../../icons/structure-view-diagram/wind-turbine.svg";

const SVG_TEXTS = {
  "/structure-icons/air-conditioner": airConditioner,
  "/structure-icons/battery": battery,
  "/structure-icons/biomass-power-plant": biomassPowerPlant,
  "/structure-icons/canteen": cantenn,
  "/structure-icons/capacitor": capacitor,
  "/structure-icons/chp": chp,
  "/structure-icons/circle": circle,
  "/structure-icons/electric-car": electricCar,
  "/structure-icons/electric-meter": electricMeter,
  "/structure-icons/electric-tower": electricTower,
  "/structure-icons/elevator": elevator,
  "/structure-icons/factory": factory,
  "/structure-icons/family": family,
  "/structure-icons/flash": flash,
  "/structure-icons/fuell-cell": fuellCell,
  "/structure-icons/gas-cogeneration-plant": gasCogenerationPlant,
  "/structure-icons/gas-turbine": gasTurbine,
  "/structure-icons/generator": generator,
  "/structure-icons/heat-pump": heatPump,
  "/structure-icons/heat-storage": heatStorage,
  "/structure-icons/office-block": officeBlock,
  "/structure-icons/orc-system": orcSystem,
  "/structure-icons/questionmark": questionmark,
  "/structure-icons/radiator": radiatior,
  "/structure-icons/real-estate": realEstate,
  "/structure-icons/rectangle": rectangle,
  "/structure-icons/residential": residental,
  "/structure-icons/solar-panel": solarPanel,
  "/structure-icons/user": user,
  "/structure-icons/valve": valve,
  "/structure-icons/wind-turbine": windTurbine
} as const;

/** Returns a URL to an SVG image for the StructureViewDiagram. Images are mapped according to imageUrl from node data. */
export function getStructureViewImageUrl(
  image: string,
  color?: string,
  opacity?: number
) {
  const svg = getSvgText(SVG_TEXTS[image]);
  if (!svg) {
    console.error(
      "Missing StructureViewDiagram image in getStructureViewImageUrl!",
      image
    );
    return undefined;
  }

  const text = applyStyles(svg, color, opacity);
  const blob = new Blob([text], { type: "image/svg+xml" });
  const url = URL.createObjectURL(blob);

  return url;
}

function getSvgText(svgUrl: string) {
  const request = new XMLHttpRequest();
  request.open("GET", svgUrl, false);
  request.send(null);

  if (request.status === 200) {
    return request.responseText;
  }

  console.error("Missing SVG file for ", svgUrl);
  return undefined;
}

function applyStyles(originalText: string, color?: string, opacity?: number) {
  let styledImageText = originalText;

  if (color) {
    const colorWithoutHash = color.replace("#", "");
    const isHex = /([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/.test(colorWithoutHash);

    if (isHex) {
      styledImageText = styledImageText.replace(
        "#00A79D",
        `#${colorWithoutHash}`
      );
    } else {
      console.error(
        `Invalid color format ${color} in getStructureViewImageUrl for ${originalText}`
      );
    }
  }

  if (typeof opacity !== "undefined") {
    styledImageText = styledImageText.replace(
      'fill-opacity="1"',
      `fill-opacity="${opacity}"`
    );
  }

  return styledImageText;
}
