import React from "react";
import type { EnergyRevenuesViewProps } from "../EnergyRevenuesView";
import { EnergyRevenuesView } from "../EnergyRevenuesView";

function CompanySection({ view, energyRevenuesData }: EnergyRevenuesViewProps) {
  return (
    <EnergyRevenuesView energyRevenuesData={energyRevenuesData} view={view} />
  );
}

export { CompanySection };
